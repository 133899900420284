"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useInit = function (props) {
    var initFunc = (props || {}).initFunc;
    var _a = (0, react_1.useState)(null), rfInstance = _a[0], setRfInstance = _a[1];
    var onInit = (0, react_1.useCallback)(function (_reactFlowInstance) {
        setRfInstance(_reactFlowInstance);
        initFunc && initFunc(_reactFlowInstance);
        _reactFlowInstance.fitView({ duration: 500, padding: 0.5 });
    }, []);
    return { rfInstance: rfInstance, onInit: onInit };
};
exports.default = useInit;
