"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var INSIGHT_EVENTS = {
    //----------------------------------------------------------------
    JOIN_ROOM: "JOIN_ROOM",
    LEAVE_ROOM: "LEAVE_ROOM",
    USER_DISCONNECTS: "USER_DISCONNECTS",
    USER_RECONNECT: "USER_RECONNECT",
    USER_TOGGLE_ACTIVE: "USER_TOGGLE_ACTIVE",
    //----------------------------------------------------------------
    // INSIGHT EVENTS
    //----------------------------------------------------------------
    UPDATE_ITEM_WIDTH: "UPDATE_ITEM_WIDTH",
    TRIGGER_REFETCH: "TRIGGER_REFETCH",
    UPLOAD_FILES: "UPLOAD_FILES",
    UPLOAD_EXISTING_FILES: "UPLOAD_EXISTING_FILES",
    ANALYSE_FILES: "ANALYSE_FILES",
    //----------------------------------------------------------------
    // ROW EVENTS
    //----------------------------------------------------------------
    PUT_ROW: "PUT_ROW",
    DELETE_ROW: "DELETE_ROW",
    DELETE_ROWS: "DELETE_ROWS",
    UPDATE_ROW_INDEX: "UPDATE_ROW_INDEX",
    DUPLICATE_ROW: "DUPLICATE_ROW",
    DUPLICATE_MULTIPLE_ROWS: "DUPLICATE_MULTIPLE_ROWS",
    REANALYSE_ROW: "REANALYSE_ROW",
    REANALYSE_ROW_META: "REANALYSE_ROW_META",
    //----------------------------------------------------------------
    // COLUMN EVENTS
    //----------------------------------------------------------------
    POST_COLUMN: "POST_COLUMN",
    DELETE_COLUMN: "DELETE_COLUMN",
    UPDATE_COLUMN_INDEX: "UPDATE_COLUMN_INDEX",
    UPDATE_COLUMN_WIDTH: "UPDATE_COLUMN_WIDTH",
    UPDATE_COLUMN: "UPDATE_COLUMN",
    REANALYSE_COLUMN: "REANALYSE_COLUMN",
    //----------------------------------------------------------------
    // CELL EVENTS
    //----------------------------------------------------------------
    CLEAR_CELL: "CLEAR_CELL",
    REANALYSE_CELL: "REANALYSE_CELL",
    //----------------------------------------------------------------
    // Client WebSocket Events
    //----------------------------------------------------------------
    INSIGHT_CLIENT_NOTIFY_CHANGES: "INSIGHT_CLIENT_NOTIFY_CHANGES",
    INSIGHT_AI_FILE_META_CHUNK: "INSIGHT_AI_FILE_META_CHUNK",
    INSIGHT_AI_FILE_META_COMPLETED: "INSIGHT_AI_FILE_META_COMPLETED",
    INSIGHT_AI_FILE_META_FAILED: "INSIGHT_AI_FILE_META_FAILED",
    INSIGHT_AI_FILE_META_STARTED: "INSIGHT_AI_FILE_META_STARTED",
    INSIGHT_AI_ANALYSIS_STARTED: "INSIGHT_AI_ANALYSIS_STARTED",
    INSIGHT_AI_ANALYSIS_CHUNK: "INSIGHT_AI_ANALYSIS_CHUNK",
    INSIGHT_AI_ANALYSIS_COMPLETED: "INSIGHT_AI_ANALYSIS_COMPLETED",
    INSIGHT_AI_ANALYSIS_FAILED: "INSIGHT_AI_ANALYSIS_FAILED",
    REANALYSE_ROW_OCR: "REANALYSE_ROW_OCR",
    INSIGHT_ANALYSE_OCR: "INSIGHT_ANALYSE_OCR",
    INSIGHT_DOCUMENTS_OCR_COMPLETED: "INSIGHT_DOCUMENTS_OCR_COMPLETED",
    INSIGHT_DOCUMENTS_OCR_FAILED: "INSIGHT_DOCUMENTS_OCR_FAILED",
    INSIGHT_DOCUMENTS_OCR_STARTED: "INSIGHT_DOCUMENTS_OCR_STARTED",
    INSIGHT_DOCUMENTS_OCR_PROGRESS: "INSIGHT_DOCUMENTS_OCR_PROGRESS",
    INSIGHT_DOCUMENTS_OCR_CHUNK: "INSIGHT_DOCUMENTS_OCR_CHUNK",
    INSIGHT_UPLOAD_INDEXATION_STARTED: "INSIGHT_UPLOAD_INDEXATION_STARTED",
    INSIGHT_UPLOAD_INDEXATION_PROGRESS: "INSIGHT_UPLOAD_INDEXATION_PROGRESS",
    INSIGHT_UPLOAD_INDEXATION_CHUNK: "INSIGHT_UPLOAD_INDEXATION_CHUNK",
    INSIGHT_UPLOAD_INDEXATION_COMPLETED: "INSIGHT_UPLOAD_INDEXATION_COMPLETED",
    INSIGHT_UPLOAD_INDEXATION_FAILED: "INSIGHT_UPLOAD_INDEXATION_FAILED",
    INSIGHT_PROGRESS_STARTED: "INSIGHT_PROGRESS_STARTED",
    INSIGHT_PROGRESS: "INSIGHT_PROGRESS",
    INSIGHT_PROGRESS_COMPLETED: "INSIGHT_PROGRESS_COMPLETED",
    INSIGHT_PROGRESS_FAILED: "INSIGHT_PROGRESS_FAILED",
    //----------------------------------------------------------------
};
exports.default = INSIGHT_EVENTS;
