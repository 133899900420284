"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DynamicStateAccessor = {
    actions: {
        stateKey: "output",
        callable: "title",
    },
    alerts: {
        stateKey: "alert",
        callable: "title",
    },
    attributes: {
        stateKey: "attribute",
        callable: "label",
    },
    conditions: {
        stateKey: "conditions",
        callable: "label",
    },
    nodes: {
        stateKey: "node",
        callable: "title",
    },
    relationships: {
        stateKey: "relationship",
        callable: "label",
    },
    people: {
        stateKey: "person",
        callable: "name",
    },
    documents: {
        stateKey: "document",
        callable: "title",
    },
    whiteboards: {
        stateKey: "workspace",
        callable: "title",
    },
    workspaces: {
        stateKey: "workspace",
        callable: "label",
    },
    timelines: {
        stateKey: "timeline",
        callable: "title",
    },
    deadlines: {
        stateKey: "deadline",
        callable: "title",
    },
    insight: {
        stateKey: "insight.insight",
        callable: "title",
    },
};
exports.default = DynamicStateAccessor;
