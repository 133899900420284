"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@pages/Timelines/constants");
function FormatToCollaborator(collaborator) {
    var _a = collaborator.user, name = _a.name, email = _a.email, isClient = _a.isClient, initials = _a.initials;
    var data = {
        name: name,
        email: email,
        isClient: isClient,
        color: (0, constants_1.stringToColor)(name || email),
        avatar: initials || (0, constants_1.stringAvatar)(name || email).children,
    };
    return {
        id: collaborator.id,
        active: collaborator.active,
        room: collaborator.room,
        data: data,
        position: { x: 50, y: 50 },
    };
}
exports.default = FormatToCollaborator;
