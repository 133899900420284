"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Descendants = void 0;
// eslint-disable-next-line import/prefer-default-export
var Descendants;
(function (Descendants) {
    Descendants["CONDITIONS"] = "conditions";
    Descendants["WORKSPACES"] = "workspaces";
    Descendants["OUTPUTS"] = "outputs";
    Descendants["NODES"] = "nodes";
    Descendants["TIMELINES"] = "timelines";
    Descendants["INSIGHTS"] = "insights";
    Descendants["BOARDS"] = "boards";
})(Descendants = exports.Descendants || (exports.Descendants = {}));
