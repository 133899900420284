"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var userConfigActions_1 = require("@redux/actions/userConfigActions");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
function useTimelinePresence() {
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var hasPresence = (0, redux_1.useAppSelector)(function (state) { return state.userConfig.timeline.isInTimeline; });
    (0, react_1.useEffect)(function () {
        var pathname = location.pathname;
        var isInTimeline = pathname.includes("timelines");
        if (!isInTimeline && hasPresence)
            dispatch((0, userConfigActions_1.updateTimelinePresence)(false));
    }, [location]);
}
exports.default = useTimelinePresence;
