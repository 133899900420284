"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_hotkeys_hook_1 = require("react-hotkeys-hook");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var redux_1 = require("./redux");
var notificationActions_1 = require("@redux/actions/notificationActions");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var react_1 = require("react");
var selectedNodesCallback = function (nodes) {
    return nodes
        .filter(function (node) { return node.selected; })
        .map(function (node) { return ({
        id: node.id,
        type: node.type,
    }); });
};
var selectedFormattableNodesCallback = function (nodes) {
    return nodes
        .filter(function (element) { return element.type === "custom" && !!element.selected; })
        .map(function (n) { return ({
        id: n.data.id,
        isBold: n.data.isBold,
        isItalic: n.data.isItalic,
        isUnderline: n.data.isUnderline,
    }); });
};
var selectedFormattableEdgesCallback = function (edges) {
    return edges
        .filter(function (e) { return !!e.selected; })
        .map(function (e) {
        var _a, _b, _c, _d;
        return ({
            id: (_a = e.data) === null || _a === void 0 ? void 0 : _a.id,
            isBold: (_b = e.data) === null || _b === void 0 ? void 0 : _b.isBold,
            isItalic: (_c = e.data) === null || _c === void 0 ? void 0 : _c.isItalic,
            isUnderline: (_d = e.data) === null || _d === void 0 ? void 0 : _d.isUnderline,
        });
    });
};
function useWorkspaceHotKeys(setShowContextMenu, handleVisabilityChange, setSnapToGrid, rfInstance, contextNode, handleShowNodeRelations, handleShowCapTableGenerator, getAddressData, dispatch, mirror) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var location = (0, react_router_dom_1.useLocation)();
    var relationships = (0, redux_1.useAppSelector)(function (state) { return state.workspace.relationships; });
    var defaultRelationship = (0, redux_1.useAppSelector)(function (state) { return state.workspace.defaultRelationship; });
    var handleToggleHelperLines = (0, react_1.useCallback)(function () {
        dispatch(workspaceActions_http_1.toggleHelperLines);
    }, [dispatch]);
    // open ownership lines
    (0, react_hotkeys_hook_1.useHotkeys)(["meta+shift+o", "ctrl+shift+o"], function () {
        var relationship = relationships.find(function (r) { return r.label === "Ownership"; });
        if (defaultRelationship.relationship) {
            dispatch((0, workspaceActions_http_1.setDefaultRelationship)(null));
            dispatch((0, notificationActions_1.showNotification)("success", t("workspace.ownership relationship removed as default")));
            return;
        }
        if (relationship) {
            var value = { label: relationship.label, value: relationship.id };
            dispatch((0, workspaceActions_http_1.setDefaultRelationship)(value));
            dispatch((0, notificationActions_1.showNotification)("success", t("workspace.ownership relationship set as default")));
        }
    }, [relationships, defaultRelationship]);
    // indsæt element
    (0, react_hotkeys_hook_1.useHotkeys)(["cmd+i", "ctrl+i"], function () {
        setShowContextMenu(false);
    });
    // show lines
    (0, react_hotkeys_hook_1.useHotkeys)("alt+g", handleVisabilityChange, {}, [handleVisabilityChange]);
    // snap
    (0, react_hotkeys_hook_1.useHotkeys)("alt+k", function () {
        setSnapToGrid(function (prevVal) { return !prevVal; });
    });
    // helperLines
    (0, react_hotkeys_hook_1.useHotkeys)("alt+h", handleToggleHelperLines);
    // fit to view
    (0, react_hotkeys_hook_1.useHotkeys)("alt+1", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ duration: 500, padding: 0.5 });
    }, {}, [rfInstance]);
    // zoom to 100
    (0, react_hotkeys_hook_1.useHotkeys)("alt+0", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomTo(1);
    }, {}, [rfInstance]);
    // zoom out
    (0, react_hotkeys_hook_1.useHotkeys)("alt+-", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomOut();
    }, {}, [rfInstance]);
    // zoom in
    (0, react_hotkeys_hook_1.useHotkeys)("alt-*", function (e) {
        if (e.key === "±" || e.key === "+") {
            rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomIn();
        }
    }, { combinationKey: "-" }, [rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+r", function () {
        contextNode && handleShowNodeRelations(contextNode);
    }, {}, [contextNode, handleShowNodeRelations]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+c", function () {
        contextNode && handleShowCapTableGenerator(contextNode);
    }, {}, [contextNode, handleShowCapTableGenerator]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+e", function () {
        contextNode && getAddressData(contextNode.id);
    }, {}, [contextNode]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+w", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.bringToFront)(selectedNodes));
    }, [dispatch, rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+s", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.sendToBack)(selectedNodes));
    }, [dispatch, rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+q", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.bringForward)(selectedNodes));
    }, [dispatch, rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+a", function () {
        var _a;
        var selectedNodes = selectedNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        dispatch((0, workspaceActions_websocket_1.sendBackward)(selectedNodes));
    }, [dispatch, rfInstance]);
    // Make bold
    (0, react_hotkeys_hook_1.useHotkeys)(["meta+b", "ctrl+b"], function (e) {
        var _a, _b;
        e.preventDefault();
        var selectedNodes = selectedFormattableNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        var selectedEdges = selectedFormattableEdgesCallback((_b = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getEdges()) !== null && _b !== void 0 ? _b : []);
        // only do it if there is a single node or edge selected
        if (selectedNodes.length + selectedEdges.length === 1) {
            var node = selectedNodes[0];
            var edge = selectedEdges[0];
            if (node) {
                dispatch((0, workspaceActions_websocket_1.putNode)({ id: +node.id, mirror: mirror(), isBold: !node.isBold }, false));
            }
            else if (edge) {
                dispatch((0, workspaceActions_websocket_1.putEdge)(location, { id: edge.id, mirror: mirror(), isBold: !edge.isBold }, {}));
            }
        }
    }, { enableOnContentEditable: true }, [dispatch, rfInstance, mirror, location]);
    // Make italic
    (0, react_hotkeys_hook_1.useHotkeys)(["meta+i", "ctrl+i"], function (e) {
        var _a, _b;
        e.preventDefault();
        var selectedNodes = selectedFormattableNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        var selectedEdges = selectedFormattableEdgesCallback((_b = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getEdges()) !== null && _b !== void 0 ? _b : []);
        // only do it if there is a single node or edge selected
        if (selectedNodes.length + selectedEdges.length === 1) {
            var node = selectedNodes[0];
            var edge = selectedEdges[0];
            if (node) {
                dispatch((0, workspaceActions_websocket_1.putNode)({ id: +node.id, mirror: mirror(), isItalic: !node.isItalic }, false));
            }
            else if (edge) {
                dispatch((0, workspaceActions_websocket_1.putEdge)(location, { id: edge.id, mirror: mirror(), isItalic: !edge.isItalic }, {}));
            }
        }
    }, { enableOnContentEditable: true }, [dispatch, rfInstance, mirror, location]);
    // Make underline
    (0, react_hotkeys_hook_1.useHotkeys)(["meta+u", "ctrl+u"], function (e) {
        var _a, _b;
        e.preventDefault();
        var selectedNodes = selectedFormattableNodesCallback((_a = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes()) !== null && _a !== void 0 ? _a : []);
        var selectedEdges = selectedFormattableEdgesCallback((_b = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getEdges()) !== null && _b !== void 0 ? _b : []);
        // only do it if there is a single node or edge selected
        if (selectedNodes.length + selectedEdges.length === 1) {
            var node = selectedNodes[0];
            var edge = selectedEdges[0];
            if (node) {
                dispatch((0, workspaceActions_websocket_1.putNode)({ id: +node.id, mirror: mirror(), isUnderline: !node.isUnderline }, false));
            }
            else if (edge) {
                dispatch((0, workspaceActions_websocket_1.putEdge)(location, { id: edge.id, mirror: mirror(), isUnderline: !edge.isUnderline }, {}));
            }
        }
    }, { enableOnContentEditable: true }, [dispatch, rfInstance, mirror, location]);
}
exports.default = useWorkspaceHotKeys;
