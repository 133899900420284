"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var initialPromptLoadings = __assign(__assign({}, constants_1.initialLoadings), { chat: false, publicConnections: false, connections: [], promptBank: false, timelineIndexingDocs: false });
var initialConnections = {
    users: [],
    documents: [],
    timelines: [],
    boards: [],
    workspaces: [],
    companies: [],
    publicConnections: [],
};
var initialPromptBank = {
    private: [],
    team: [],
    juristic: [],
};
var initialState = {
    prompts: [],
    loadings: initialPromptLoadings,
    connections: initialConnections,
    sources: [],
    publicConnections: [],
    promptBank: initialPromptBank,
    files: [],
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "PROMPT_SHOW_LOADING":
        case "PROMPT_INDEX_LOADING":
        case "PROMPT_INTERACTION_LOADING":
        case "PROMPT_DELETE_LOADING":
        case "PROMPT_UPDATE_LOADING":
        case "PROMPT_SHOW_FAILED":
        case "PROMPT_TOGGLE_USER_LOADING":
        case "GET_PUBLIC_CONNECTIONS_LOADING":
        case "LOAD_PROMPT_BANK_LOADING":
        case "DELETE_PROMPT_BANK_LOADING":
        case "UPDATE_PROMPT_BANK_LOADING":
        case "PROMPT_IMANAGE_UPLOAD_LOADING":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case "PROMPT_INDEX_FAILED":
        case "PROMPT_INTERACTION_FAILED":
        case "PROMPT_DELETE_FAILED":
        case "PROMPT_UPDATE_FAILED":
        case "PROMPT_TOGGLE_USER_FAILED":
        case "GET_PUBLIC_CONNECTIONS_FAILED":
        case "LOAD_PROMPT_BANK_FAILED":
        case "DELETE_PROMPT_BANK_FAILED":
        case "UPDATE_PROMPT_BANK_FAILED":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = false, _b)) });
        case "PROMPT_TOGGLE_DOCUMENT_LOADING":
        case "PROMPT_TOGGLE_DOCUMENT_FAILED":
        case "PROMPT_TOGGLE_TIMELINE_LOADING":
        case "PROMPT_TOGGLE_TIMELINE_FAILED":
        case "PROMPT_TOGGLE_BOARD_LOADING":
        case "PROMPT_TOGGLE_BOARD_FAILED":
        case "PROMPT_TOGGLE_WORKSPACE_LOADING":
        case "PROMPT_TOGGLE_WORKSPACE_FAILED":
        case "PROMPT_TOGGLE_COMPANY_LOADING":
        case "PROMPT_TOGGLE_COMPANY_FAILED":
        case "PROMPT_TOGGLE_PUBLIC_CONNECTION_LOADING":
        case "PROMPT_TOGGLE_PUBLIC_CONNECTION_FAILED":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { connections: state.loadings.connections.concat(action.id) }) });
        case "PROMPT_SHOW_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = false, _c)), connections: {
                    users: action.promptUsers,
                    documents: action.promptDocuments,
                    timelines: action.promptTimelines,
                    boards: action.promptBoards,
                    workspaces: action.promptWorkspaces,
                    companies: action.promptCompanies,
                    publicConnections: action.promptPublicConnections,
                } });
        case "PROMPT_INTERACTION_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = false, _d)), sources: action.sources });
        case "LOAD_PROMPT_BANK_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_e = {}, _e[action.loadingType] = false, _e)), promptBank: action.promptBank });
        case "DELETE_PROMPT_BANK_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_f = {}, _f[action.loadingType] = false, _f)) });
        case "UPDATE_PROMPT_BANK_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_g = {}, _g[action.loadingType] = false, _g)), prompts: state.prompts.map(function (prompt) {
                    return prompt.id === action.promptId
                        ? __assign(__assign({}, prompt), action.updatedProperties) : prompt;
                }) });
        case "NEW_CHAT":
            return __assign(__assign({}, state), { connections: initialConnections, sources: [] });
        case "GET_PUBLIC_CONNECTIONS_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_h = {}, _h[action.loadingType] = false, _h)), publicConnections: action.publicConnections });
        case "PROMPT_INDEX_SUCCESS":
            return __assign(__assign({}, state), { prompts: action.prompts, loadings: __assign(__assign({}, state.loadings), (_j = {}, _j[action.loadingType] = false, _j)) });
        case "PROMPT_DELETE_SUCCESS":
            return __assign(__assign({}, state), { prompts: state.prompts.filter(function (prompt) { return prompt.id !== action.id; }), loadings: __assign(__assign({}, state.loadings), (_k = {}, _k[action.loadingType] = false, _k)), connections: initialConnections, sources: [] });
        case "PROMPT_UPDATE_SUCCESS":
            return __assign(__assign({}, state), { prompts: state.prompts.map(function (prompt) {
                    return prompt.id === action.prompt.id ? action.prompt : prompt;
                }), loadings: __assign(__assign({}, state.loadings), (_l = {}, _l[action.loadingType] = false, _l)) });
        case "NEW_PROMPT":
            return __assign(__assign({}, state), { prompts: __spreadArray([__assign(__assign({}, action.prompt), { isLoading: true })], state.prompts, true).sort(function (p) {
                    return p.favorite ? -1 : 1;
                }) });
        case "PROMPT_TITLE_GENERATED":
            return __assign(__assign({}, state), { prompts: state.prompts.map(function (prompt) {
                    return prompt.id === action.payload.promptId
                        ? __assign(__assign({}, prompt), { title: action.payload.title, isLoading: false }) : prompt;
                }) });
        case "PROMPT_TOGGLE_USER_SUCCESS":
            return __assign(__assign({}, state), { connections: state.connections.users.includes(action.userId)
                    ? __assign(__assign({}, state.connections), { users: state.connections.users.filter(function (id) { return id !== action.userId; }) }) : __assign(__assign({}, state.connections), { users: __spreadArray(__spreadArray([], state.connections.users, true), [action.userId], false) }), loadings: __assign(__assign({}, state.loadings), (_m = {}, _m[action.loadingType] = false, _m)) });
        case "PROMPT_TOGGLE_DOCUMENT_SUCCESS": {
            var documentMap_1 = new Map(state.connections.documents.map(function (doc) { return [doc.id, doc]; }));
            action.documents.forEach(function (currentDoc) {
                if (documentMap_1.has(currentDoc.id)) {
                    documentMap_1.delete(currentDoc.id);
                }
                else {
                    documentMap_1.set(currentDoc.id, currentDoc);
                }
            });
            var idsToRemove_1 = Array.isArray(action.id) ? action.id : [action.id];
            return __assign(__assign({}, state), { connections: __assign(__assign({}, state.connections), { documents: Array.from(documentMap_1.values()) }), loadings: __assign(__assign({}, state.loadings), { connections: state.loadings.connections.filter(function (id) { return !idsToRemove_1.includes(id); }) }) });
        }
        case "PROMPT_TOGGLE_TIMELINE_SUCCESS":
            return __assign(__assign({}, state), { connections: state.connections.timelines.find(function (x) { return x.id === action.timeline.id; })
                    ? __assign(__assign({}, state.connections), { timelines: state.connections.timelines.filter(function (t) { return t.id !== action.timeline.id; }) }) : __assign(__assign({}, state.connections), { timelines: __spreadArray(__spreadArray([], state.connections.timelines, true), [action.timeline], false) }), loadings: __assign(__assign({}, state.loadings), { connections: state.loadings.connections.filter(function (id) { return id !== action.id; }) }) });
        case "ADD_DOCUMENT_TO_PROMPT":
            return __assign(__assign({}, state), { connections: __assign(__assign({}, state.connections), { documents: __spreadArray(__spreadArray([], state.connections.documents, true), action.documents, true) }) });
        case "PROMPT_TOGGLE_BOARD_SUCCESS":
            return __assign(__assign({}, state), { connections: state.connections.boards.find(function (x) { return x.id === action.board.id; })
                    ? __assign(__assign({}, state.connections), { boards: state.connections.boards.filter(function (b) { return b.id !== action.board.id; }) }) : __assign(__assign({}, state.connections), { boards: __spreadArray(__spreadArray([], state.connections.boards, true), [action.board], false) }), loadings: __assign(__assign({}, state.loadings), { connections: state.loadings.connections.filter(function (id) { return id !== action.id; }) }) });
        case "PROMPT_TOGGLE_WORKSPACE_SUCCESS":
            return __assign(__assign({}, state), { connections: state.connections.workspaces.find(function (x) { return x.id === action.workspace.id; })
                    ? __assign(__assign({}, state.connections), { workspaces: state.connections.workspaces.filter(function (w) { return w.id !== action.workspace.id; }) }) : __assign(__assign({}, state.connections), { workspaces: __spreadArray(__spreadArray([], state.connections.workspaces, true), [action.workspace], false) }), loadings: __assign(__assign({}, state.loadings), { connections: state.loadings.connections.filter(function (id) { return id !== action.id; }) }) });
        case "PROMPT_TOGGLE_COMPANY_SUCCESS":
            return __assign(__assign({}, state), { connections: state.connections.companies.find(function (x) { return x.id === action.company.id; })
                    ? __assign(__assign({}, state.connections), { companies: state.connections.companies.filter(function (c) { return c.id !== action.company.id; }) }) : __assign(__assign({}, state.connections), { companies: __spreadArray(__spreadArray([], state.connections.companies, true), [action.company], false) }), loadings: __assign(__assign({}, state.loadings), { connections: state.loadings.connections.filter(function (id) { return id !== action.id; }) }) });
        case "PROMPT_TOGGLE_PUBLIC_CONNECTION_SUCCESS":
            return __assign(__assign({}, state), { connections: state.connections.publicConnections.find(function (x) { return x.id === action.publicConnection.id; })
                    ? __assign(__assign({}, state.connections), { publicConnections: state.connections.publicConnections.filter(function (pc) { return pc.id !== action.publicConnection.id; }) }) : __assign(__assign({}, state.connections), { publicConnections: __spreadArray(__spreadArray([], state.connections.publicConnections, true), [
                        action.publicConnection,
                    ], false) }), loadings: __assign(__assign({}, state.loadings), { connections: state.loadings.connections.filter(function (id) { return id !== action.id; }) }) });
        case "PROMPT_UPDATE_FILE_PROGRESS_UPLOADS": {
            var newFiles = state.files.map(function (file) {
                if (("id" in file.file && file.file.id === action.name) ||
                    file.file.name === action.name) {
                    return __assign(__assign({}, file), { progress: action.progress });
                }
                return file;
            });
            return __assign(__assign({}, state), { files: newFiles });
        }
        case "PROMPT_SET_FILE_UPLOADS":
            return __assign(__assign({}, state), { files: action.files });
        case "PROMPT_CONCAT_FILE_UPLOADS":
            return __assign(__assign({}, state), { files: state.files.concat(action.files) });
        case "PROMPT_IMANAGE_UPLOAD_FAILED":
        case "PROMPT_IMANAGE_UPLOAD_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_o = {}, _o[action.loadingType] = false, _o)) });
        default:
            return state;
    }
}
exports.default = reducer;
