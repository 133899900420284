"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var initalState = {
    connection: {
        status: "connected",
    },
    room: "",
    collaborators: new Map(),
};
function reducer(state, action) {
    if (state === void 0) { state = initalState; }
    switch (action.type) {
        case "SOCKET_CLEAR":
            return initalState;
        case "SOCKET_CONNECTED":
            return __assign(__assign({}, state), { connection: __assign(__assign({}, state.connection), { status: "connected" }) });
        case "SOCKET_DISCONNECTED":
            return __assign(__assign({}, state), { connection: __assign(__assign({}, state.connection), { status: "disconnected" }) });
        case "SOCKET_RECONNECTING":
            return __assign(__assign({}, state), { connection: __assign(__assign({}, state.connection), { status: "reconnecting" }) });
        case "CLEAR_ROOM":
            return __assign(__assign({}, state), { room: "" });
        case "UPDATE_ROOM":
            return __assign(__assign({}, state), { room: action.room });
        case "UPDATE_COLLABORATOR_ROOM": {
            var collaborators = new Map(state.collaborators);
            var collaborator = collaborators.get(action.id);
            if (collaborator) {
                collaborator.room = action.room;
                collaborators.set(action.id, collaborator);
            }
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case "COLLABORATORS_RESET":
            return __assign(__assign({}, state), { collaborators: new Map() });
        case "COLLABORATORS_REFRESH":
            return __assign(__assign({}, state), { collaborators: action.collaborators });
        case "COLLABORATOR_JOIN_ROOM": {
            var collaborators = new Map(state.collaborators);
            collaborators.set(action.id, action.collaborator);
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case "COLLABORATOR_LEAVE_ROOM": {
            var collaborators = new Map(state.collaborators);
            collaborators.delete(action.id);
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case "COLLABORATOR_TOGGLE_ACTIVE": {
            var collaborators = new Map(state.collaborators);
            var collaborator = collaborators.get(action.id);
            if (collaborator) {
                collaborator.active = action.active;
                collaborators.set(action.id, collaborator);
            }
            return __assign(__assign({}, state), { collaborators: collaborators });
        }
        case "COLLABORATOR_UPDATE_POSITION": {
            var cursor = state.collaborators.get(action.socketId);
            if (cursor) {
                cursor.position = { x: action.x, y: action.y };
                var newMap = new Map(state.collaborators);
                return __assign(__assign({}, state), { collaborators: newMap.set(action.socketId, cursor) });
            }
            return state;
        }
        case "COLLABORATOR_UPDATE_POSITION_RELATIVE": {
            var _ = action.type, socketId = action.socketId, position = __rest(action, ["type", "socketId"]);
            var cursor = state.collaborators.get(socketId);
            if (cursor) {
                cursor.position = position;
                var newMap = new Map(state.collaborators);
                return __assign(__assign({}, state), { collaborators: newMap.set(action.socketId, cursor) });
            }
            return state;
        }
        default:
            return state;
    }
}
exports.default = reducer;
