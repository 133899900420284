"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultHighlightColor = exports.defaultTextColor = exports.fontSizes = exports.fontFamilies = void 0;
exports.fontFamilies = [
    "Arial",
    "Times New Roman",
    "Courier New",
    "Georgia",
    "Verdana",
    "Helvetica",
    "Tahoma",
    "Trebuchet MS",
    "Garamond",
    "Calibri",
    "Segoe UI",
    "Roboto",
    "Open Sans",
    "Lato",
];
exports.fontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
exports.defaultTextColor = { r: 0, g: 0, b: 0, a: 1 };
exports.defaultHighlightColor = { r: 255, g: 255, b: 0, a: 0.5 };
