"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POST_DOCUMENT_FAILED = exports.POST_DOCUMENT_SUCCESS = exports.POST_DOCUMENT_LOADING = exports.DOCUMENTS_CHANGE = exports.TOGGLE_DOCUMENT_MODAL = exports.RELATIONSHIP_DUPLICATE_FAILED = exports.RELATIONSHIP_DUPLICATE_SUCCESS = exports.RELATIONSHIP_DUPLICATE_LOADING = exports.TOGGLE_HIDE_LABEL = exports.TOGGLE_HIDE_VALUE = exports.TOGGLE_LINE_THROUGH = exports.TOGGLE_ANIMATED = exports.CHANGE_TYPE = exports.CHANGE_LABEL_BACKGROUND_COLOR = exports.CHANGE_COLOR = exports.CHANGE_SIZE = exports.LABEL_MOVE_RELATIONSHIP_FAILED = exports.CLEAR_RELATIONSHIP_DESCENDANTS = exports.CHANGE_USE_SUGGESTION = exports.NOTES_CHANGE = exports.FONT_COLOR_CHANGE = exports.FONT_FAMILY_CHANGE = exports.FONT_SIZE_CHANGE = exports.DESCRIPTION_CHANGE = exports.VALUES_CHANGE = exports.LABEL_CHANGE = exports.GET_RELATIONSHIP_DESCENDANTS_FAILED = exports.GET_RELATIONSHIP_DESCENDANTS_SUCCESS = exports.FORCE_DELETE_RELATIONSHIP_FAILED = exports.FORCE_DELETE_RELATIONSHIP_SUCCESS = exports.DELETE_RELATIONSHIP_FAILED = exports.DELETE_RELATIONSHIP_SUCCESS = exports.PUT_RELATIONSHIP_FAILED = exports.PUT_RELATIONSHIP_SUCCESS = exports.SHOW_RELATIONSHIP_FAILED = exports.SHOW_RELATIONSHIP_SUCCESS = exports.POST_RELATIONSHIP_FAILED = exports.POST_RELATIONSHIP_SUCCESS = exports.GET_RELATIONSHIPS_FAILED = exports.GET_RELATIONSHIPS_SUCCESS = void 0;
// API
exports.GET_RELATIONSHIPS_SUCCESS = "GET_RELATIONSHIPS_SUCCESS";
exports.GET_RELATIONSHIPS_FAILED = "GET_RELATIONSHIPS_FAILED";
exports.POST_RELATIONSHIP_SUCCESS = "POST_RELATIONSHIP_SUCCESS";
exports.POST_RELATIONSHIP_FAILED = "POST_RELATIONSHIP_FAILED";
exports.SHOW_RELATIONSHIP_SUCCESS = "SHOW_RELATIONSHIP_SUCCESS";
exports.SHOW_RELATIONSHIP_FAILED = "SHOW_RELATIONSHIP_FAILED";
exports.PUT_RELATIONSHIP_SUCCESS = "PUT_RELATIONSHIP_SUCCESS";
exports.PUT_RELATIONSHIP_FAILED = "PUT_RELATIONSHIP_FAILED";
exports.DELETE_RELATIONSHIP_SUCCESS = "DELETE_RELATIONSHIP_SUCCESS";
exports.DELETE_RELATIONSHIP_FAILED = "DELETE_RELATIONSHIP_FAILED";
exports.FORCE_DELETE_RELATIONSHIP_SUCCESS = "FORCE_DELETE_RELATIONSHIP_SUCCESS";
exports.FORCE_DELETE_RELATIONSHIP_FAILED = "FORCE_DELETE_RELATIONSHIP_FAILED";
exports.GET_RELATIONSHIP_DESCENDANTS_SUCCESS = "GET_RELATIONSHIP_DESCENDANTS_SUCCESS";
exports.GET_RELATIONSHIP_DESCENDANTS_FAILED = "GET_RELATIONSHIP_DESCENDANTS_FAILED";
exports.LABEL_CHANGE = "RELATIONSHIP_LABEL_CHANGE";
exports.VALUES_CHANGE = "RELATIONSHIP_VALUES_CHANGE";
exports.DESCRIPTION_CHANGE = "RELATIONSHIP_DESCRIPTION_CHANGE";
exports.FONT_SIZE_CHANGE = "RELATIONSHIP_FONT_SIZE_CHANGE";
exports.FONT_FAMILY_CHANGE = "RELATIONSHIP_FONT_FAMILY_CHANGE";
exports.FONT_COLOR_CHANGE = "RELATIONSHIP_FONT_COLOR_CHANGE";
exports.NOTES_CHANGE = "RELATIONSHIP_NOTES_CHANGE";
exports.CHANGE_USE_SUGGESTION = "RELATIONSHIP_CHANGE_USE_SUGGESTION";
exports.CLEAR_RELATIONSHIP_DESCENDANTS = "RELATIONSHIP_CLEAR_DESCENDANTS";
exports.LABEL_MOVE_RELATIONSHIP_FAILED = "LABEL_MOVE_RELATIONSHIP_FAILED";
// STYLE
exports.CHANGE_SIZE = "RELATIONSHIP_CHANGE_SIZE";
exports.CHANGE_COLOR = "RELATIONSHIP_CHANGE_COLOR";
exports.CHANGE_LABEL_BACKGROUND_COLOR = "RELATIONSHIP_CHANGE_LABEL_BACKGROUND_COLOR";
exports.CHANGE_TYPE = "RELATIONSHIP_CHANGE_TYPE";
exports.TOGGLE_ANIMATED = "RELATIONSHIP_TOGGLE_ANIMATED";
exports.TOGGLE_LINE_THROUGH = "RELATIONSHIP_TOGGLE_LINE_THROUGH";
exports.TOGGLE_HIDE_VALUE = "RELATIONSHIP_TOGGLE_HIDE_VALUE";
exports.TOGGLE_HIDE_LABEL = "RELATIONSHIP_TOGGLE_HIDE_LABEL";
exports.RELATIONSHIP_DUPLICATE_LOADING = "RELATIONSHIP_DUPLICATE_LOADING";
exports.RELATIONSHIP_DUPLICATE_SUCCESS = "RELATIONSHIP_DUPLICATE_SUCCESS";
exports.RELATIONSHIP_DUPLICATE_FAILED = "RELATIONSHIP_DUPLICATE_FAILED";
exports.TOGGLE_DOCUMENT_MODAL = "RELATIONSHIP_TOGGLE_DOCUMENT_MODAL";
exports.DOCUMENTS_CHANGE = "RELATIONSHIP_DOCUMENTS_CHANGE";
exports.POST_DOCUMENT_LOADING = "POST_DOCUMENT_LOADING";
exports.POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
exports.POST_DOCUMENT_FAILED = "POST_DOCUMENT_FAILED";
