"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@customTypes/styles");
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) {
    var _a;
    return ({
        table: {
            "& > div": {
                overflow: "auto",
            },
            "& table": (_a = {
                    "& td": {
                        wordBreak: "keep-all",
                    }
                },
                _a[theme.breakpoints.down("lg")] = {
                    "& td": {
                        maxHeight: 45,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                _a),
            marginBottom: 50,
        },
        lottie: {
            height: 150,
        },
        racoonAndText: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: theme.spacing(2),
        },
        body: __assign({ overflowY: "auto", height: "100%", padding: theme.spacing(1) }, (0, styles_1.JuristicScroll)(theme)),
        paper: {
            width: "100%",
            height: "100%",
            padding: theme.spacing(2),
        },
        button: {
            opacity: 0.7,
        },
    });
});
exports.default = useStyles;
