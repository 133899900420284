"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JuristicScroll = exports.CursorType = void 0;
var CursorType;
(function (CursorType) {
    CursorType["Auto"] = "auto";
    CursorType["Default"] = "default";
    CursorType["None"] = "none";
    CursorType["ContextMenu"] = "context-menu";
    CursorType["Help"] = "help";
    CursorType["Pointer"] = "pointer";
    CursorType["Progress"] = "progress";
    CursorType["Wait"] = "wait";
    CursorType["Cell"] = "cell";
    CursorType["Crosshair"] = "crosshair";
    CursorType["Text"] = "text";
    CursorType["VerticalText"] = "vertical-text";
    CursorType["Alias"] = "alias";
    CursorType["Copy"] = "copy";
    CursorType["Move"] = "move";
    CursorType["NoDrop"] = "no-drop";
    CursorType["NotAllowed"] = "not-allowed";
    CursorType["Grab"] = "grab";
    CursorType["Grabbing"] = "grabbing";
    CursorType["AllScroll"] = "all-scroll";
    CursorType["ColResize"] = "col-resize";
    CursorType["RowResize"] = "row-resize";
    CursorType["NResize"] = "n-resize";
    CursorType["EResize"] = "e-resize";
    CursorType["SResize"] = "s-resize";
    CursorType["WResize"] = "w-resize";
    CursorType["NEResize"] = "ne-resize";
    CursorType["NWResize"] = "nw-resize";
    CursorType["SEResize"] = "se-resize";
    CursorType["SWResize"] = "sw-resize";
    CursorType["EwResize"] = "ew-resize";
    CursorType["NsResize"] = "ns-resize";
    CursorType["NeswResize"] = "nesw-resize";
    CursorType["NwseResize"] = "nwse-resize";
    CursorType["ZoomIn"] = "zoom-in";
    CursorType["ZoomOut"] = "zoom-out";
})(CursorType = exports.CursorType || (exports.CursorType = {}));
var JuristicScroll = function (theme, options) {
    var size = "0.4em";
    if (options === null || options === void 0 ? void 0 : options.size) {
        size = options.size + "em";
    }
    return {
        "&::-webkit-scrollbar": __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.isHorizontal) ? { height: size } : { width: size })), ((options === null || options === void 0 ? void 0 : options.isBoth) ? { height: size, width: size } : {})),
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 10,
        },
    };
};
exports.JuristicScroll = JuristicScroll;
