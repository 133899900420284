"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Colors_1 = require("@enums/Colors");
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
var panelStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    timeline: {
        height: "calc(100% - 136px)",
        // width: "50%",
    },
    panel: {
        position: "absolute",
        zIndex: 1000,
        backgroundColor: Colors_1.otherGenericColors.panel,
        right: 10,
        bottom: 68,
        minHeight: "30%",
        maxHeight: "calc(100% - 136px)",
        // "-webkit-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // "-moz-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // "-o-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // transition:
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // width: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        pointerEvents: "auto",
        padding: theme.spacing(2),
        opacity: "0",
        visibility: "hidden",
        gap: theme.spacing(1),
    },
    panelOffice: {
        position: "absolute",
        zIndex: 1000,
        backgroundColor: Colors_1.otherGenericColors.panel,
        right: 10,
        bottom: 68,
        minHeight: "30%",
        maxHeight: "calc(100% - 220px)",
        // "-webkit-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // "-moz-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // "-o-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // transition:
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // width: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        pointerEvents: "auto",
        padding: theme.spacing(2),
        opacity: "0",
        visibility: "hidden",
        gap: theme.spacing(1),
    },
    panelOpen: {
        // "-webkit-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // "-moz-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // "-o-transition":
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // transition:
        //   "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        // width: 400,
        opacity: "1",
        visibility: "visible",
    },
    aiCredits: {
        width: "100%",
        height: 8,
        border: "1px solid #000",
        borderRadius: 3,
        display: "flex",
        justifyContent: "flex-end",
        overflow: "hidden",
    },
    aiCreditsOverlay: {
        height: "100%",
        backgroundColor: "white",
        transform: "skewX(-20deg)",
    },
    aiCreditsText: {
        "text-wrap": "nowrap",
        overflow: "none",
    },
    panelDivider: {
        height: 1,
        width: "100%",
    },
    panelHeaderContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: theme.spacing(1),
    },
    panelHeader: {
        fontWeight: "bold",
    },
    panelBody: __assign({ height: "100%", width: "100%", overflowY: "scroll", display: "flex", alignItems: "safe flex-end" }, (0, styles_1.JuristicScroll)(theme, { size: 0.2 })),
    panelBodyLoader: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    panelFooter: {
        display: "flex",
        width: "100%",
        gap: 1,
        alignItems: "flex-end",
    },
    topButtonContainer: {
        width: "100%",
        marginTop: 15,
    },
    messageAdornment: {
        height: "100%",
        alignSelf: "flex-end",
        button: { width: 26, height: 26 },
    },
    iconButton: {
        height: "fit-content",
        svg: {
            color: theme.palette.primary.main,
        },
    },
    mention: {
        color: theme.palette.primary.main,
    },
    betaHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
    },
    betaTextWrapper: {
        backgroundColor: theme.palette.primary.main,
        padding: 2,
        borderRadius: 3,
        marginBottom: 10,
    },
    betaText: {
        color: theme.palette.primary.contrastText,
        fontSize: 8,
        margin: 0,
    },
}); });
exports.default = panelStyles;
