"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MENU_FAILED = exports.GET_MENU_SUCCESS = exports.GET_MENU_LOADING = exports.TOGGLE_PIN_MENU_ITEM = exports.SET_LANGUAGE = exports.SET_THEME = exports.LOAD_PAGE = exports.OPEN_SUBMENU = exports.CLOSE_MENU = exports.OPEN_MENU = exports.TOGGLE_SIDEBAR = void 0;
// Global UI Action
exports.TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
exports.OPEN_MENU = "OPEN_MENU";
exports.CLOSE_MENU = "CLOSE_MENU";
exports.OPEN_SUBMENU = "OPEN_SUBMENU";
exports.LOAD_PAGE = "LOAD_PAGE";
exports.SET_THEME = "SET_THEME";
exports.SET_LANGUAGE = "SET_LANGUAGE";
exports.TOGGLE_PIN_MENU_ITEM = "TOGGLE_PIN_MENU_ITEM";
exports.GET_MENU_LOADING = "GET_MENU_LOADING";
exports.GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
exports.GET_MENU_FAILED = "GET_MENU_FAILED";
