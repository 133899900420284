"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var i18next_1 = require("i18next");
function useLanguage(user) {
    var meta = user && user["https://juristic.io/meta"];
    var dbUser = meta === null || meta === void 0 ? void 0 : meta.dbUser;
    var language = dbUser === null || dbUser === void 0 ? void 0 : dbUser.language;
    (0, react_1.useEffect)(function () {
        if (language) {
            (0, i18next_1.changeLanguage)(language);
        }
    }, [language]);
}
exports.default = useLanguage;
