"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var reactflow_1 = require("reactflow");
var d3_timer_1 = require("d3-timer");
var defaultOptions = { duration: 500 };
var sameDateNodeHeight = 195;
var screenWidthsToLoad = 2;
var screenHeightsToLoad = 2;
var zoomInFactor = 5.75 / 5;
var zoomOutFactor = 5.25 / 6;
var filterNodesBasedOnViewport = function (nodes, viewport) {
    var screenWidth = window.innerWidth, screenHeight = window.innerHeight;
    var viewportX = viewport.x, viewportY = viewport.y, zoom = viewport.zoom;
    var loadDistanceX = screenWidth * screenWidthsToLoad * (1 / zoom);
    var loadDistanceY = screenHeight * screenHeightsToLoad * (1 / zoom);
    var lowerX = -viewportX * (1 / zoom) - loadDistanceX;
    var upperX = -viewportX * (1 / zoom) + loadDistanceX;
    var lowerY = -viewportY * (1 / zoom) - loadDistanceY;
    var upperY = -viewportY * (1 / zoom) + loadDistanceY;
    return nodes.filter(function (node) {
        var _a, _b, _c, _d;
        var _e = node.position, x = _e.x, y = _e.y;
        var collapsedNodeYDistance = ((_c = (_b = (_a = node.data) === null || _a === void 0 ? void 0 : _a.sameDateNodes) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) *
            sameDateNodeHeight *
            (1 / zoom);
        var hasChildren = !!((_d = node.data) === null || _d === void 0 ? void 0 : _d.hasChildren);
        return (x >= lowerX &&
            x <= upperX &&
            (hasChildren || (y >= lowerY && y <= upperY + collapsedNodeYDistance)));
    });
};
function useAnimatedNodes(initialNodes, _a) {
    var _b = _a.options, options = _b === void 0 ? defaultOptions : _b, _c = _a.isDragging, isDragging = _c === void 0 ? false : _c;
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var _d = react_1.default.useState(rfInstance ? filterNodesBasedOnViewport(initialNodes, rfInstance.getViewport()) : []), nodes = _d[0], _setNodes = _d[1];
    var setNodes = react_1.default.useCallback(function (newNodes) {
        var viewport = rfInstance.getViewport();
        _setNodes(filterNodesBasedOnViewport(newNodes, viewport));
    }, [rfInstance]);
    var _e = react_1.default.useState(null), oldViewport = _e[0], setOldViewport = _e[1];
    (0, reactflow_1.useOnViewportChange)({
        onChange: (0, react_1.useCallback)(function (viewport) {
            var _a, _b;
            var screenWidth = window.innerWidth;
            var x = viewport.x;
            var isMoved = Math.abs(x - ((_a = oldViewport === null || oldViewport === void 0 ? void 0 : oldViewport.x) !== null && _a !== void 0 ? _a : 0)) > screenWidth;
            var zoomFactor = ((_b = oldViewport === null || oldViewport === void 0 ? void 0 : oldViewport.zoom) !== null && _b !== void 0 ? _b : 1) / viewport.zoom;
            var isZoomed = zoomFactor <= zoomOutFactor || zoomFactor >= zoomInFactor;
            if (!isMoved && !isZoomed)
                return;
            setOldViewport(viewport);
            setNodes(filterNodesBasedOnViewport(initialNodes, viewport));
        }, [initialNodes, oldViewport]),
        onEnd: (0, react_1.useCallback)(function (viewport) {
            setOldViewport(viewport);
            _setNodes(filterNodesBasedOnViewport(initialNodes, viewport));
        }, [initialNodes]),
    });
    var _f = react_1.default.useState(false), shouldDisableMouseEnter = _f[0], setDisableMouseEnter = _f[1];
    react_1.default.useEffect(function () {
        if (isDragging) {
            setNodes(initialNodes);
            return function () { };
        }
        setNodes(initialNodes);
        // setDisableMouseEnter(true);
        var transitions = initialNodes.map(function (node) {
            var _a, _b;
            return ({
                id: node.id,
                from: (_b = (_a = rfInstance.getNode(node.id)) === null || _a === void 0 ? void 0 : _a.position) !== null && _b !== void 0 ? _b : node.position,
                to: node.position,
                node: node,
            });
        });
        var t = (0, d3_timer_1.timer)(function (elapsed) {
            var s = elapsed / options.duration;
            if (elapsed >= options.duration)
                s = 1;
            var currNodes = transitions.map(function (_a) {
                var node = _a.node, from = _a.from, to = _a.to;
                return (__assign(__assign({}, node), { width: "width" in node ? node.width : 50, height: "height" in node ? node.height : 50, position: node.type === "stickyNote"
                        ? node.position
                        : {
                            x: from.x + (to.x - from.x) * s,
                            y: from.y + (to.y - from.y) * s,
                        } }));
            });
            setNodes(currNodes);
            if (elapsed >= options.duration) {
                setNodes(initialNodes);
                setDisableMouseEnter(false);
                t.stop();
            }
        });
        return function () { return t.stop(); };
    }, [initialNodes]);
    return [nodes, shouldDisableMouseEnter];
}
exports.default = useAnimatedNodes;
