"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHelperLines = void 0;
var react_1 = __importStar(require("react"));
var reactflow_1 = require("reactflow");
var styles_1 = require("@mui/material/styles");
var shallow_1 = require("zustand/shallow");
var redux_1 = require("@hooks/redux");
var canvasStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10,
    pointerEvents: "none",
};
var storeSelector = function (state) { return ({
    width: state.width,
    height: state.height,
}); };
// a simple component to display the helper lines
// it puts a canvas on top of the React Flow pane and draws the lines using the canvas API
var canvasClassName = "react-flow__canvas";
var SNAP_DISTANCE = 5;
var minSnap = function (snaps) {
    return snaps.reduce(function (best, current) {
        return Math.abs(current.snappedDistance || Infinity) <
            Math.abs(best.snappedDistance || Infinity)
            ? current
            : best;
    });
};
var useHelperLines = function () {
    var theme = (0, styles_1.useTheme)();
    var canvas = document.querySelector(".".concat(canvasClassName));
    var ctx = canvas === null || canvas === void 0 ? void 0 : canvas.getContext("2d");
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var _a = (0, reactflow_1.useStore)(storeSelector, shallow_1.shallow), width = _a.width, height = _a.height;
    var showHelperLines = (0, redux_1.useAppSelector)(function (state) { return state.workspace.showHelperLines; });
    var snapPositions = (0, react_1.useRef)({ x: [], y: [] });
    var updateHelperLines = (0, react_1.useCallback)(function (horizontal, vertical) {
        if (!showHelperLines) {
            return;
        }
        if (!canvas || !ctx) {
            return;
        }
        var _a = rfInstance.getViewport(), x = _a.x, y = _a.y, zoom = _a.zoom;
        var dpi = window.devicePixelRatio;
        canvas.width = width * dpi;
        canvas.height = height * dpi;
        ctx.scale(dpi, dpi);
        ctx.clearRect(0, 0, width, height);
        ctx.strokeStyle = theme.palette.primary.main;
        if (typeof vertical === "number") {
            ctx.moveTo(vertical * zoom + x, 0);
            ctx.lineTo(vertical * zoom + x, height);
            ctx.stroke();
        }
        if (typeof horizontal === "number") {
            ctx.moveTo(0, horizontal * zoom + y);
            ctx.lineTo(width, horizontal * zoom + y);
            ctx.stroke();
        }
    }, [rfInstance, canvas, ctx, theme.palette.primary.main, width, height, showHelperLines]);
    var updateSnapPositions = (0, react_1.useCallback)(function (selectedNodeIds) {
        snapPositions.current = { x: [], y: [] };
        var result = rfInstance.getNodes().reduce(function (acc, node) {
            var _a, _b, _c, _d, _e, _f;
            if (selectedNodeIds ? selectedNodeIds.has(node.id) : node.selected) {
                acc.selectedElements.push(node);
            }
            else {
                var parentNode = node.parentNode
                    ? rfInstance.getNode(node.parentNode)
                    : null;
                var lowerX = node.position.x + ((_a = parentNode === null || parentNode === void 0 ? void 0 : parentNode.position.x) !== null && _a !== void 0 ? _a : 0);
                var lowerY = node.position.y + ((_b = parentNode === null || parentNode === void 0 ? void 0 : parentNode.position.y) !== null && _b !== void 0 ? _b : 0);
                acc.newSnapPositions.x.push(lowerX);
                acc.newSnapPositions.x.push(lowerX + ((_c = node.width) !== null && _c !== void 0 ? _c : 0) / 2);
                acc.newSnapPositions.x.push(lowerX + ((_d = node.width) !== null && _d !== void 0 ? _d : 0));
                acc.newSnapPositions.y.push(lowerY);
                acc.newSnapPositions.y.push(lowerY + ((_e = node.height) !== null && _e !== void 0 ? _e : 0) / 2);
                acc.newSnapPositions.y.push(lowerY + ((_f = node.height) !== null && _f !== void 0 ? _f : 0));
            }
            return acc;
        }, {
            selectedElements: [],
            newSnapPositions: { x: [], y: [] },
        });
        result.newSnapPositions.x.sort(function (a, b) { return a - b; });
        result.newSnapPositions.y.sort(function (a, b) { return a - b; });
        snapPositions.current = result.newSnapPositions;
        return result;
    }, [rfInstance]);
    var calculateSnap = (0, react_1.useCallback)(function (val, dimension) {
        if (!showHelperLines) {
            return { newVal: val, snappedDistance: 0 };
        }
        if (snapPositions.current[dimension].length === 0) {
            // No snapping values at all; just return the original value.
            return { newVal: val, snappedDistance: 0 };
        }
        var left = 0;
        var right = snapPositions.current[dimension].length - 1;
        // Initialize our "best guess" at the closest value and distance
        var closestVal = snapPositions.current[dimension][0];
        var bestDist = val - closestVal;
        // Standard binary search, but update closestVal/bestDist on each iteration
        while (left <= right) {
            var mid = Math.floor((left + right) / 2);
            var candidate = snapPositions.current[dimension][mid];
            var diff = val - candidate;
            // Update our best known snap if this midpoint is closer
            if (Math.abs(diff) < Math.abs(bestDist)) {
                closestVal = candidate;
                bestDist = diff;
            }
            // If it’s an exact match, we can stop searching
            if (candidate === val) {
                return { newVal: val, snappedDistance: 0 };
            }
            // Standard binary search narrowing
            if (candidate < val) {
                left = mid + 1;
            }
            else {
                right = mid - 1;
            }
        }
        // After the loop, closestVal is the best candidate we found
        // Snap only if within the threshold
        if (Math.abs(bestDist) <= SNAP_DISTANCE) {
            return { newVal: closestVal, snappedDistance: bestDist };
        }
        // Otherwise, return the original value
        return { newVal: val, snappedDistance: 0 };
    }, [showHelperLines]);
    var getHelperLines = (0, react_1.useCallback)(function (changes) {
        var _a = changes.reduce(function (acc, change) {
            var _a, _b;
            if (change.type === "position" && change.dragging && change.position) {
                var node = rfInstance.getNode(change.id);
                if (!node || node.parentNode) {
                    return acc;
                }
                if (node.selected) {
                    acc.selectedNodes.add(change.id);
                }
                var _c = change.position, x = _c.x, y = _c.y;
                var x2 = x + ((_a = node.width) !== null && _a !== void 0 ? _a : 0);
                var y2 = y + ((_b = node.height) !== null && _b !== void 0 ? _b : 0);
                var rect = acc.boundingBox;
                if (x < rect.x) {
                    rect.x = x;
                }
                if (y < rect.y) {
                    rect.y = y;
                }
                if (x2 > rect.x2) {
                    rect.x2 = x2;
                }
                if (y2 > rect.y2) {
                    rect.y2 = y2;
                }
            }
            return acc;
        }, {
            boundingBox: {
                x: Infinity,
                y: Infinity,
                x2: -Infinity,
                y2: -Infinity,
            },
            selectedNodes: new Set(),
        }), boundingBox = _a.boundingBox, selectedNodes = _a.selectedNodes;
        var xSnap = calculateSnap(boundingBox.x, "x");
        var ySnap = calculateSnap(boundingBox.y, "y");
        var xMidSnap = calculateSnap(boundingBox.x + (boundingBox.x2 - boundingBox.x) / 2, "x");
        var yMidSnap = calculateSnap(boundingBox.y + (boundingBox.y2 - boundingBox.y) / 2, "y");
        var x2Snap = calculateSnap(boundingBox.x2, "x");
        var y2Snap = calculateSnap(boundingBox.y2, "y");
        var minXSnap = minSnap([xSnap, xMidSnap, x2Snap]);
        var minYSnap = minSnap([ySnap, yMidSnap, y2Snap]);
        var vertical = minXSnap.snappedDistance === 0 ||
            minXSnap.snappedDistance > Math.abs(SNAP_DISTANCE)
            ? undefined
            : minXSnap.newVal;
        var horizontal = minYSnap.snappedDistance === 0 ||
            minYSnap.snappedDistance > Math.abs(SNAP_DISTANCE)
            ? undefined
            : minYSnap.newVal;
        if (vertical || horizontal) {
            var numOfChanges = changes.length;
            for (var i = 0; i < numOfChanges; i++) {
                var change = changes[i];
                if (change.type === "position" && change.dragging && change.position) {
                    if (vertical) {
                        change.position.x -= minXSnap.snappedDistance;
                    }
                    if (horizontal) {
                        change.position.y -= minYSnap.snappedDistance;
                    }
                    if (selectedNodes.has(change.id)) {
                        changes.push({
                            id: change.id,
                            type: "select",
                            selected: true,
                        });
                    }
                }
            }
        }
        return {
            horizontal: horizontal,
            vertical: vertical,
            updatedChanges: changes,
        };
    }, [rfInstance, calculateSnap]);
    return {
        updateHelperLines: updateHelperLines,
        updateSnapPositions: updateSnapPositions,
        calculateSnap: calculateSnap,
        getHelperLines: getHelperLines,
    };
};
exports.useHelperLines = useHelperLines;
function HelperLinesCanvas() {
    return react_1.default.createElement("canvas", { className: canvasClassName, style: canvasStyle });
}
exports.default = (0, react_1.memo)(HelperLinesCanvas);
