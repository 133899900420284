"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONDITION_DUMMY_ID = void 0;
var reactFlow_1 = require("@customTypes/reactFlow");
var conditions_1 = require("@customTypes/reducers/conditions");
var converters_1 = require("@helpers/colors/converters");
var constants_1 = require("@pages/Conditions/constants");
var conditionActions_1 = require("@pages/Conditions/reducers/conditionActions");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var reactflow_1 = require("reactflow");
exports.CONDITION_DUMMY_ID = "-1";
var useEdge = function (dispatch, user, id, dimFlowItems) {
    var location = (0, react_router_dom_1.useLocation)();
    var _a = (0, react_1.useState)(false), isUpdatingEdge = _a[0], setIsUpdatingEdge = _a[1];
    var _b = (0, react_1.useState)(null), edgeToUpdate = _b[0], setEdgeToUpdate = _b[1];
    var _c = (0, react_1.useState)(null), edgePopperRef = _c[0], setEdgePopperRef = _c[1];
    var _d = (0, react_1.useState)(false), showEdgePopper = _d[0], setShowEdgePopper = _d[1];
    var _e = (0, react_1.useState)(null), newCustomHandle = _e[0], setNewCustomHandle = _e[1];
    var handleShowEdgePopper = function () {
        setShowEdgePopper(true);
    };
    var handleHideEdgePopper = function () {
        setEdgePopperRef(null);
        setShowEdgePopper(false);
    };
    var _f = (0, react_1.useState)({
        source: "",
        target: "",
        sourceHandle: "",
        targetHandle: "",
    }), currentConnectionData = _f[0], setCurrentConnectionData = _f[1];
    var _g = (0, react_1.useState)(true), isNewRelationship = _g[0], setIsNewRelationship = _g[1];
    var closeEdge = (0, react_1.useCallback)(function () {
        if (isNewRelationship) {
            setNewCustomHandle(null);
            dispatch(conditionActions_1.removeDummyRelationshipEdge);
        }
        setIsNewRelationship(false);
        handleHideEdgePopper();
        setIsUpdatingEdge(false);
        setEdgeToUpdate(null);
        setIsNewRelationship(false);
    }, []);
    var handleRelationshipSave = function (edge) {
        var data = edge.data;
        if (!data.label) {
            closeEdge();
        }
        else if (isUpdatingEdge && edgeToUpdate && edgeToUpdate.id !== exports.CONDITION_DUMMY_ID) {
            dispatch((0, conditionActions_1.putEdge)(user, location, edgeToUpdate.id, data.label, data.comparison_type, data.comparison_value, edge.type));
            closeEdge();
        }
        else {
            if (edge.id === exports.CONDITION_DUMMY_ID) {
                var connectionEdge = __assign({ relationshipLabel: data.label, relationshipType: edge.type, comparisonType: data.comparison_type, comparisonValue: data.comparison_value, source: currentConnectionData.source, target: currentConnectionData.target, sourceHandle: currentConnectionData.sourceHandle, targetHandle: currentConnectionData.targetHandle }, (newCustomHandle && { customHandle: newCustomHandle }));
                dispatch((0, conditionActions_1.postEdge)(user, id, connectionEdge));
            }
            closeEdge();
        }
    };
    var showPopper = function (target) {
        handleShowEdgePopper();
        setEdgePopperRef(target);
    };
    var onEdgeClick = function (event, element) {
        setIsUpdatingEdge(true);
        setEdgeToUpdate(element);
        var target = event.target;
        handleShowEdgePopper();
        var actualTarget = target;
        if (target.tagName === "path") {
            // Navigate up the DOM tree to the parent group element
            var parent = target.parentNode;
            // Navigate down the DOM tree to the rect element
            var rect = parent === null || parent === void 0 ? void 0 : parent.querySelector(".react-flow__edge-textbg");
            if (rect) {
                actualTarget = rect;
            }
            else {
                actualTarget = target;
            }
        }
        setEdgePopperRef(actualTarget);
    };
    var handleChangeLabelEdge = function (edgeId, selectedStandardEdge) {
        if (selectedStandardEdge.__isNew__) {
            dispatch((0, conditionActions_1.addRelationshipToList)({
                id: 0,
                label: selectedStandardEdge.label,
                description: "",
                values: [],
                type: reactFlow_1.EdgeVariants.CUSTOM,
                color: { r: 0, g: 0, b: 0, a: 1 },
                labelBackgroundColor: (0, converters_1.hexToRgba)("#F3F5F8"),
                animated: false,
                lineThrough: false,
                isBold: false,
                isItalic: false,
                isUnderline: false,
                hideValue: false,
                hideLabel: false,
                useSuggestions: false,
                notes: "",
                documents: [],
                curveScalar: 0.5,
                offsetBearing: null,
                fontColor: "#000000",
                fontSize: 10,
                fontFamily: "Open Sans",
                sourceMarker: "none",
                targetMarker: "none",
            }));
        }
        dispatch((0, conditionActions_1.editEdgeLabel)(edgeId, selectedStandardEdge.label));
    };
    var handleTypeChange = function (edgeId, _type) {
        dispatch((0, conditionActions_1.editEdgeType)(edgeId, _type));
    };
    var handleComparisonTypeChange = function (edgeId, v) {
        if (constants_1.nonValueArray.includes(v.value)) {
            dispatch((0, conditionActions_1.editEdgeConditionValue)(edgeId, ""));
        }
        dispatch((0, conditionActions_1.editEdgeConditionType)(edgeId, v.value));
    };
    var handleComparisonValueChange = function (edgeId, v) {
        dispatch((0, conditionActions_1.editEdgeConditionValue)(edgeId, v));
    };
    var onConnect = (0, react_1.useCallback)(function (data, customHandle, onSuccess) {
        if (data.source !== data.target) {
            if (data.source !== null && data.target !== null) {
                if (customHandle) {
                    setNewCustomHandle(customHandle);
                    data.targetHandle = exports.CONDITION_DUMMY_ID;
                }
                var dummyEdge = {
                    data: {
                        comparison_type: conditions_1.ComparisonTypes.IS_EQUAL_TO,
                        comparison_value: "",
                        label: undefined,
                        showArrow: true,
                    },
                    id: exports.CONDITION_DUMMY_ID,
                    label: "",
                    labelBgStyle: { fill: "rgba(243, 245, 248, 1)" },
                    markerEnd: { type: reactflow_1.MarkerType.ArrowClosed },
                    selected: true,
                    source: data.source,
                    sourceHandle: data.sourceHandle,
                    target: data.target,
                    targetHandle: data.targetHandle,
                    type: reactFlow_1.EdgeVariants.CUSTOM,
                };
                var dummyHandle = null;
                if (customHandle) {
                    dummyHandle = __assign(__assign({ id: parseInt(exports.CONDITION_DUMMY_ID, 10), nodeId: parseInt(data.target, 10) }, customHandle), { updatedAt: "", createdAt: "" });
                }
                dispatch((0, conditionActions_1.addDummyRelationshipEdge)(dummyEdge, dummyHandle, onSuccess));
                setIsUpdatingEdge(true);
                setEdgeToUpdate(dummyEdge);
                setIsNewRelationship(true);
                dimFlowItems(false, exports.CONDITION_DUMMY_ID);
                setCurrentConnectionData(data);
                var element = document.querySelector("[data-id=\"".concat(data.target, "\"]"));
                showPopper(element);
            }
        }
    }, [
        dispatch,
        dimFlowItems,
        setIsUpdatingEdge,
        setEdgeToUpdate,
        setIsNewRelationship,
        setCurrentConnectionData,
    ]);
    return {
        onEdgeClick: onEdgeClick,
        handleRelationshipSave: handleRelationshipSave,
        edgePopperRef: edgePopperRef,
        showEdgePopper: showEdgePopper,
        closeEdge: closeEdge,
        handleChangeLabelEdge: handleChangeLabelEdge,
        handleTypeChange: handleTypeChange,
        handleComparisonTypeChange: handleComparisonTypeChange,
        handleComparisonValueChange: handleComparisonValueChange,
        isUpdatingEdge: isUpdatingEdge,
        onConnect: onConnect,
        edgeToUpdate: edgeToUpdate,
        handleHideEdgePopper: handleHideEdgePopper,
        showPopper: showPopper,
    };
};
exports.default = useEdge;
