"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var DragContext_1 = require("./context/DragContext");
var material_1 = require("@mui/material");
var DropZone = function (_a) {
    var eventId = _a.eventId, _b = _a.fieldType, fieldType = _b === void 0 ? "columns" : _b, children = _a.children;
    var _c = (0, DragContext_1.useDrag)(), draggedItem = _c.draggedItem, handleDrop = _c.handleDrop;
    var _d = (0, react_1.useState)(false), isOver = _d[0], setIsOver = _d[1];
    var handleDragOver = function (e) {
        e.preventDefault();
        if (!draggedItem)
            return;
        e.dataTransfer.dropEffect = "move";
        setIsOver(true);
    };
    var handleDragLeave = function () {
        setIsOver(false);
    };
    var handleDropAction = function (e) {
        e.preventDefault();
        if (!draggedItem)
            return;
        handleDrop(eventId, fieldType);
        setIsOver(false);
    };
    return (react_1.default.createElement(material_1.Box, { onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleDropAction, sx: __assign(__assign({ borderRadius: 1, width: "100%" }, (isOver && {
            bgcolor: "primary.50",
            border: "1px dashed",
            borderColor: "primary.dark",
        })), (!children && {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80px",
        })) }, children ||
        (isOver ? (react_1.default.createElement(material_1.Typography, { variant: "caption", sx: {
                color: "primary.main",
                fontWeight: "medium",
                animation: "pulse 1.5s infinite ease-in-out",
                "@keyframes pulse": {
                    "0%, 100%": { opacity: 1 },
                    "50%": { opacity: 0.7 },
                },
            } }, "Drop here")) : (react_1.default.createElement(material_1.Typography, { variant: "caption", sx: { color: "text.disabled" } }, "Drag columns here")))));
};
exports.default = DropZone;
