"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(material_1.Paper)(function (_a) {
    var theme = _a.theme;
    return ({
        width: "100%",
        height: "calc(100vh - 16px)",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    });
});
