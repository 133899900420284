"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    drawerPaper: {
        width: 570,
        minWidth: 570,
    },
    dragger: {
        width: "14px",
        cursor: "ew-resize",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: "100",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparant",
        transition: "background-color 0.3s ease",
        "& .MuiSvgIcon-root": {
            opacity: 0,
            visibility: "hidden",
            transition: "opacity 0.3s ease, visibility 0.3s ease",
        },
        "&:hover": {
            backgroundColor: "#f4f7f9",
            "& .MuiSvgIcon-root": {
                opacity: 1,
                visibility: "visible",
            },
        },
    },
    cellContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        gap: 4,
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
    columnCenterPopperPaper: {
        width: 350,
    },
    search: {},
    moreColumnButton: {
        marginTop: theme.spacing(1),
        color: "black",
    },
    listItemButton: {
        borderRadius: "5px",
        "& .MuiListItemIcon-root": {
            minWidth: "30px",
        },
    },
    contextMenu: {
        "& .MuiMenu-paper": {
            boxShadow: "0px 2px 10px rgb(5 0 56 / 8%)",
        },
    },
    taskGroupCompletedIcon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    menuButton: { minWidth: 0, color: "#666", display: "flex", gap: 5 },
    clickable: { cursor: "pointer" },
    lowOpacity: { opacity: 0.2 },
    bigCreateColumnButtonBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    bigCreateColumnButton: {
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #000000",
        width: "30%",
        height: "20%",
        padding: theme.spacing(2),
    },
    biggerCreateButton: {
        width: "45%",
    },
    buttonRow: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1),
    },
    buttonInnerRow: {
        display: "flex",
        gap: theme.spacing(1),
    },
    step1Container: {
        display: "flex",
        justifyContent: "space-evenly",
        padding: theme.spacing(2),
        width: "100%",
    },
    step2Container: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        width: "100%",
        gap: theme.spacing(1),
    },
    whatTextField: {
        width: "100%",
        margin: 0,
        marginTop: theme.spacing(1),
    },
    whatHeader: {
        marginLeft: 3,
    },
    step3TextField: {
        width: "100%",
        margin: 0,
    },
    templatePopperPaper: {
        padding: theme.spacing(2),
        width: 350,
    },
    drawerFileIconWrapper: {
        marginBottom: 5,
    },
}); });
exports.default = useStyles;
