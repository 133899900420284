"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var PanelItemText = (0, material_1.styled)(material_1.Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: theme.typography.pxToRem(20),
        color: "inherit",
        userSelect: "none",
    });
});
exports.default = PanelItemText;
