"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/styles");
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var reactflow_1 = require("reactflow");
function getFlowPositions(rfInstance, rect) {
    var left = rfInstance.screenToFlowPosition({ x: 0, y: 0 }).x;
    var right = rfInstance.screenToFlowPosition({ x: rect.width, y: 0 }).x;
    return { left: left, right: right };
}
var Indicator = (0, styles_1.styled)(material_1.Box)({
    position: "absolute",
    width: 25,
    height: 25,
    top: "50%",
    zIndex: 999,
    padding: 1,
    cursor: "pointer",
    backgroundColor: "#193765",
    color: "white",
    borderRadius: "80% 0 55% 50% / 55% 0 80% 50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});
function NodeIndicators(_a) {
    var reactFlowContainer = _a.reactFlowContainer, rfInstance = _a.rfInstance, nodes = _a.nodes;
    var x = (0, reactflow_1.useViewport)().x;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = react_1.default.useState(0), left = _b[0], setLeft = _b[1];
    var _c = react_1.default.useState(0), right = _c[0], setRight = _c[1];
    var _d = react_1.default.useState([]), leftNodes = _d[0], setLeftNodes = _d[1];
    var _e = react_1.default.useState([]), rightNodes = _e[0], setRightNodes = _e[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (!rfInstance)
            return;
        var rect = (_a = reactFlowContainer.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!rect)
            return;
        var p = getFlowPositions(rfInstance, rect);
        setLeft(p.left);
        setRight(p.right);
        var _leftNodes = [];
        var _rightNodes = [];
        nodes.forEach(function (node) {
            if (node.position.x < p.left) {
                _leftNodes.push(node);
            }
            else if (node.position.x > p.right) {
                _rightNodes.push(node);
            }
        });
        setLeftNodes(_leftNodes);
        setRightNodes(_rightNodes);
    }, [rfInstance, reactFlowContainer, nodes, x]);
    var onGoToNearestLeftNode = (0, react_1.useCallback)(function () {
        var nearestNode = leftNodes.reduce(function (prev, current) {
            return Math.abs(current.position.x - left) < Math.abs(prev.position.x - left)
                ? current
                : prev;
        });
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({
            nodes: [nearestNode],
            duration: 500,
            minZoom: 1,
            maxZoom: 1,
        });
    }, [leftNodes, rfInstance, left]);
    var onGoToNearestRightNode = (0, react_1.useCallback)(function () {
        var nearestNode = rightNodes.reduce(function (prev, current) {
            return Math.abs(current.position.x - right) < Math.abs(prev.position.x - right)
                ? current
                : prev;
        });
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({
            nodes: [nearestNode],
            duration: 500,
            minZoom: 1,
            maxZoom: 1,
        });
    }, [rightNodes, rfInstance, right]);
    // make one indicator on each side with the number of nodes. Clicking on it will go to the nearest node
    return (react_1.default.createElement(react_1.default.Fragment, null,
        leftNodes.length > 0 && (react_1.default.createElement(material_1.Tooltip, { title: t("timeline.node_indicator.go") },
            react_1.default.createElement(Indicator, { sx: {
                    left: 70,
                    transform: "translateY(-50%) rotate(-135deg)",
                }, onClick: onGoToNearestLeftNode, "data-html2canvas-ignore": "true" },
                react_1.default.createElement(material_1.Box, { sx: { transform: "rotate(135deg)", fontSize: 10 } }, leftNodes.length > 999 ? "999+" : leftNodes.length)))),
        rightNodes.length > 0 && (react_1.default.createElement(material_1.Tooltip, { title: t("timeline.node_indicator.go") },
            react_1.default.createElement(Indicator, { sx: {
                    right: 70,
                    transform: "translateY(-50%) rotate(45deg)",
                }, onClick: onGoToNearestRightNode, "data-html2canvas-ignore": "true" },
                react_1.default.createElement(material_1.Box, { sx: { transform: "rotate(-45deg)", fontSize: 10 } }, rightNodes.length > 999 ? "999+" : rightNodes.length))))));
}
exports.default = (0, react_1.memo)(NodeIndicators);
