"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var PanelItemContainer = (0, material_1.styled)(material_1.Box)({
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    gap: "1px",
    position: "relative",
    flex: 1,
});
exports.default = PanelItemContainer;
