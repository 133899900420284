"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(material_1.Tab)(function (_a) {
    var theme = _a.theme;
    return ({
        whiteSpace: "nowrap",
        padding: theme.spacing(1.5, 5),
    });
});
