"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var react_1 = require("react");
var ConnectionLoading = function (promptId) {
    var _a = (0, react_1.useState)(true), isConnectionLoading = _a[0], setIsConnectionLoading = _a[1];
    var connection = (0, redux_1.useAppSelector)(function (state) { return state.prompt.loadings.connections; });
    (0, react_1.useEffect)(function () {
        if (connection.includes(promptId)) {
            setIsConnectionLoading(true);
        }
        else {
            setIsConnectionLoading(false);
        }
    }, [connection]);
    return isConnectionLoading;
};
exports.default = ConnectionLoading;
