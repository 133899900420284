"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DATE_FORMATS = exports.TimelineBundleDocumentType = void 0;
var TimelineBundleDocumentType;
(function (TimelineBundleDocumentType) {
    TimelineBundleDocumentType["DOCUMENT"] = "document";
    TimelineBundleDocumentType["SPLITTER"] = "splitter";
})(TimelineBundleDocumentType = exports.TimelineBundleDocumentType || (exports.TimelineBundleDocumentType = {}));
exports.DATE_FORMATS = {
    JURISTIC: "DD/MM-YYYY",
    ShortDate: "MM/DD/YY",
    ShortDateWithDashes: "YY-MM-DD",
    ShortDateWithDots: "DD.MM.YY",
    ShortDateWithSlashes: "YY/MM/DD",
    InvertedShortDate: "YY-DD-MM",
    ShortMonthFirst: "MM-YY-DD",
    ShortDayFirst: "DD-YY-MM",
    FullDateWithSlashes: "YYYY/MM/DD",
    FullDateWithDashes: "YYYY-MM-DD",
    FullDateWithDashesReverse: "DD-MM-YYYY",
    FullDateWithDots: "DD.MM.YYYY",
    FullDateWithSpaces: "DD MM YYYY",
    FullDateReversed: "YYYY/DD/MM",
};
