"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onUpdateColumnWidth = exports.onUpdateColumnIndex = exports.onDeleteColumn = exports.onUpdateColumn = exports.onPostColumn = void 0;
function onPostColumn() {
    return function (dispatch) { return function (column) {
        dispatch({ type: "INSIGHT_POST_COLUMN_SUCCESS", column: column });
    }; };
}
exports.onPostColumn = onPostColumn;
function onUpdateColumn() {
    return function (dispatch) { return function (column) {
        dispatch({ type: "INSIGHT_UPDATE_COLUMN_SUCCESS", column: column });
    }; };
}
exports.onUpdateColumn = onUpdateColumn;
function onDeleteColumn() {
    return function (dispatch) { return function (columnId) {
        dispatch({ type: "INSIGHT_DELETE_COLUMN_SUCCESS", columnId: columnId });
    }; };
}
exports.onDeleteColumn = onDeleteColumn;
function onUpdateColumnIndex() {
    return function (dispatch) { return function (payload) {
        dispatch(__assign({ type: "INSIGHT_CHANGE_COLUMN_INDEX" }, payload));
    }; };
}
exports.onUpdateColumnIndex = onUpdateColumnIndex;
function onUpdateColumnWidth() {
    return function (dispatch) { return function (payload) {
        dispatch(__assign({ type: "INSIGHT_CHANGE_COLUMN_WIDTH_SUCCESS" }, payload));
    }; };
}
exports.onUpdateColumnWidth = onUpdateColumnWidth;
