"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var usersActions_1 = require("@pages/Users/reducers/usersActions");
var redux_1 = require("@hooks/redux");
var auth0_react_1 = require("@auth0/auth0-react");
var ModalContent = function () { return (react_1.default.createElement(react_1.default.Fragment, null,
    react_1.default.createElement(material_1.Typography, { variant: "h6" }, "No organization assigned..."),
    react_1.default.createElement(material_1.Typography, { sx: { margin: 1, marginBottom: 2 } }, "You are logged in but not assigned an organisation. If this is an error, please reach out to the person responsible for Juristic in your organisation or click here."))); };
var SuccessModal = function () { return (react_1.default.createElement(react_1.default.Fragment, null,
    react_1.default.createElement(material_1.Typography, { variant: "h6" }, "We Got You! Don't Worry"),
    react_1.default.createElement(material_1.Typography, { sx: { margin: 1, marginBottom: 2 } }, "We are on it! The right people have been notified and are actively working to resolve your issue and get you the proper access."))); };
function NoOrganizationModal() {
    var dispatch = (0, redux_1.useAppDispatch)();
    var user = (0, auth0_react_1.useAuth0)().user;
    var userInfo = user["https://juristic.io/meta"];
    var orgId = userInfo === null || userInfo === void 0 ? void 0 : userInfo.dbUser.organization_id;
    var userEmail = userInfo === null || userInfo === void 0 ? void 0 : userInfo.dbUser.email;
    console.debug(userEmail);
    console.debug(orgId);
    var _a = react_1.default.useState(false), clicked = _a[0], setClicked = _a[1];
    var onClick = (0, react_1.useCallback)(function () {
        dispatch((0, usersActions_1.postHelpMessage)(user, "User with email \"".concat(userEmail, "\" encountered an issue regarding organization assignment. The user's current organization ID is 14, which corresponds to an inactive test organization.")));
        setClicked(true);
    }, []);
    if (orgId === 14) {
        return (react_1.default.createElement(material_1.Modal, { open: true, sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backdropFilter: "blur(3px)",
            } },
            react_1.default.createElement(material_1.Box, { sx: {
                    width: 400,
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: 3,
                    boxShadow: 24,
                } },
                clicked ? react_1.default.createElement(SuccessModal, null) : react_1.default.createElement(ModalContent, null),
                react_1.default.createElement(material_1.Button, { variant: "contained", onClick: onClick }, "Click here."))));
    }
}
exports.default = NoOrganizationModal;
