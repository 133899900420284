"use strict";
/* eslint-disable no-loop-func */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var XLSX = __importStar(require("xlsx"));
var reactflow_1 = require("reactflow");
var react_i18next_1 = require("react-i18next");
var file_saver_1 = require("file-saver");
var handleExport_1 = require("@helpers/export/handleExport");
var getSheetName = function (node) {
    var _a;
    var regex = /[^A-Za-z0-9]/g;
    return node.data.displayName
        ? node.data.displayName.substring(0, 30).replace(regex, "")
        : ((_a = node.data.label) === null || _a === void 0 ? void 0 : _a.substring(0, 30).replace(regex, "")) || "";
};
var useExcelExport = function (label) {
    var _a = (0, react_1.useState)(false), loadingExcel = _a[0], setLoadingExcel = _a[1];
    var stopLoadingExcel = function () { return setLoadingExcel(false); };
    var startLoadingExcel = function () { return setLoadingExcel(true); };
    var t = (0, react_i18next_1.useTranslation)().t;
    var reactFlowInstance = (0, reactflow_1.useReactFlow)();
    var handleExcel = (0, react_1.useCallback)(function () {
        var nodes = reactFlowInstance.getNodes();
        var edges = reactFlowInstance.getEdges();
        startLoadingExcel();
        var wb = XLSX.utils.book_new();
        var _nodes = nodes
            .filter(function (e) { return "label" in e.data; })
            .filter(function (e) {
            var checkIfNodeHasText = e.data.label &&
                e.data.label.length > 0 &&
                e.data.displayName &&
                e.data.displayName.length > 0;
            return checkIfNodeHasText;
        });
        if (_nodes.length === 0) {
            stopLoadingExcel();
            return;
        }
        var names = _nodes.map(function (n) { return getSheetName(n); });
        wb.SheetNames = names.filter(function (c, index) { return names.indexOf(c) === index; });
        var header = [
            t("workspace.meta.excel.headers.element"),
            t("workspace.meta.excel.headers.relation"),
            t("workspace.meta.excel.headers.value"),
            t("workspace.meta.excel.headers.type"),
        ];
        var _loop_1 = function (index) {
            var node = _nodes[index];
            // @ts-ignore
            var outgoers = (0, reactflow_1.getOutgoers)(node, nodes, edges);
            // @ts-ignore
            var incommers = (0, reactflow_1.getIncomers)(node, nodes, edges);
            var outData = outgoers.map(function (o) {
                var _a;
                var _b, _c;
                var relation = edges.find(function (x) { return x.source === node.id && x.target === o.id; });
                return _a = {},
                    // @ts-ignore
                    _a[header[0]] = o.data.displayName,
                    _a[header[1]] = relation ? ((_b = relation.data) === null || _b === void 0 ? void 0 : _b.label) || "" : "",
                    _a[header[2]] = relation ? ((_c = relation.data) === null || _c === void 0 ? void 0 : _c.value) || "" : "",
                    _a[header[3]] = t("workspace.meta.excel.headers.outgoer"),
                    _a;
            });
            var inData = incommers.map(function (o) {
                var _a;
                var _b, _c;
                var relation = edges.find(function (x) { return x.source === o.id && x.target === node.id; });
                return _a = {},
                    // @ts-ignore
                    _a[header[0]] = o.data.displayName,
                    _a[header[1]] = relation ? ((_b = relation.data) === null || _b === void 0 ? void 0 : _b.label) || "" : "",
                    _a[header[2]] = relation ? ((_c = relation.data) === null || _c === void 0 ? void 0 : _c.value) || "" : "",
                    _a[header[3]] = t("workspace.meta.excel.headers.incommer"),
                    _a;
            });
            var wsData = __spreadArray(__spreadArray([], outData, true), inData, true);
            var ws = XLSX.utils.json_to_sheet(wsData, { header: header });
            wb.Sheets[getSheetName(node)] = ws;
        };
        for (var index = 0; index < _nodes.length; index++) {
            _loop_1(index);
        }
        var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        (0, file_saver_1.saveAs)(new Blob([(0, handleExport_1.s2ab)(wbout)], { type: "application/octet-stream" }), "".concat(label, ".xlsx"));
        setTimeout(function () {
            stopLoadingExcel();
        }, 500);
    }, [reactFlowInstance]);
    return { handleExcel: handleExcel, loadingExcel: loadingExcel };
};
exports.default = useExcelExport;
