"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onUpdateHolidayCountry = exports.onToggleIncludeWeekends = exports.onToggleIncludeHolidays = exports.onHandleGanttChanges = exports.onUpdateGanttDateRange = exports.onGetGanttDateRange = exports.onToggleBoardIsManuel = exports.onToggleBoardCompletion = exports.onImportAiFiles = exports.onImportTemplate = exports.onItemWidthChange = void 0;
var types = __importStar(require("../../boardConstants"));
function onItemWidthChange() {
    return function (dispatch) {
        return function (_a) {
            var boardId = _a.boardId, itemWidth = _a.itemWidth;
            dispatch({
                type: types.CHANGE_ITEM_WIDTH_SUCCESS,
                boardId: boardId,
                itemWidth: itemWidth,
            });
        };
    };
}
exports.onItemWidthChange = onItemWidthChange;
function onImportTemplate() {
    return function (dispatch) { return function (data) {
        dispatch(__assign({ type: types.IMPORT_BOARD_TEMPLATE_WS_SUCCESS }, data));
    }; };
}
exports.onImportTemplate = onImportTemplate;
function onImportAiFiles() {
    return function (dispatch) { return function (taskGroups) {
        dispatch({
            type: types.IMPORT_BOARD_AI_FILES_SUCCESS,
            loadingType: "post",
            taskGroups: taskGroups,
        });
    }; };
}
exports.onImportAiFiles = onImportAiFiles;
function onToggleBoardCompletion() {
    return function (dispatch) { return function (response) {
        dispatch(__assign({ type: types.BOARD_TOGGLE_COMPLETE_SUCCESS }, response));
    }; };
}
exports.onToggleBoardCompletion = onToggleBoardCompletion;
function onToggleBoardIsManuel() {
    return function (dispatch) { return function (response) {
        dispatch(__assign({ type: types.BOARD_TOGGLE_IS_MANUEL_INDEX_SUCCESS }, response));
    }; };
}
exports.onToggleBoardIsManuel = onToggleBoardIsManuel;
function onGetGanttDateRange() {
    return function (dispatch) { return function (data) {
        dispatch(__assign({ type: "GET_GANTT_DATE_RANGE_SUCCESS" }, data));
    }; };
}
exports.onGetGanttDateRange = onGetGanttDateRange;
function onUpdateGanttDateRange() {
    return function (dispatch) { return function (data) {
        dispatch(__assign({ type: "CHANGE_GANTT_DATE_RANGE_SUCCESS" }, data));
    }; };
}
exports.onUpdateGanttDateRange = onUpdateGanttDateRange;
function onHandleGanttChanges() {
    return function (dispatch) { return function (payload) {
        dispatch({ type: "HANDLE_GANTT_CHANGES_SUCCESS", payload: payload });
    }; };
}
exports.onHandleGanttChanges = onHandleGanttChanges;
function onToggleIncludeHolidays() {
    return function (dispatch) {
        return function (_a) {
            var updatedColumns = _a.updatedColumns, includeHolidays = _a.includeHolidays;
            dispatch({ type: types.TOGGLE_INCLUDE_HOLIDAYS, includeHolidays: includeHolidays, updatedColumns: updatedColumns });
        };
    };
}
exports.onToggleIncludeHolidays = onToggleIncludeHolidays;
function onToggleIncludeWeekends() {
    return function (dispatch) {
        return function (_a) {
            var updatedColumns = _a.updatedColumns, includeWeekends = _a.includeWeekends;
            dispatch({ type: types.TOGGLE_INCLUDE_WEEKENDS, includeWeekends: includeWeekends, updatedColumns: updatedColumns });
        };
    };
}
exports.onToggleIncludeWeekends = onToggleIncludeWeekends;
function onUpdateHolidayCountry() {
    return function (dispatch) {
        return function (_a) {
            var updatedColumns = _a.updatedColumns, holidayCountry = _a.holidayCountry;
            dispatch({ type: types.UPDATE_HOLIDAY_COUNTRY, holidayCountry: holidayCountry, updatedColumns: updatedColumns });
        };
    };
}
exports.onUpdateHolidayCountry = onUpdateHolidayCountry;
