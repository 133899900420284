"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var redux_1 = require("@hooks/redux");
var insightActions_http_1 = require("@pages/Insight/reducers/insightActions.http");
var columnActions_websocket_1 = require("@pages/Insight/reducers/WebSocket/actions/columnActions.websocket");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var columnCenterValues = [
    {
        id: 1,
        type: "verbatim",
        title: "Termination conditions",
        prompt: "What are the termination conditions?",
    },
    {
        id: 2,
        type: "longtext",
        title: "Governing law",
        prompt: "What is the governing law of the agreement?",
    },
    {
        id: 3,
        type: "verbatim",
        title: "Confidentiality clause",
        prompt: "Extract the confidentiality clause.",
    },
    {
        id: 4,
        type: "list",
        title: "Parties involved",
        prompt: "Who are the parties involved in the agreement?",
    },
    {
        id: 5,
        type: "verbatim",
        title: "Force majeure clause",
        prompt: "Extract the force majeure clause.",
    },
    {
        id: 6,
        type: "shorttext",
        title: "Contract type",
        prompt: "What type of contract is this (e.g., NDA, MSA, SLA)?",
    },
    {
        id: 7,
        type: "bool",
        title: "Arbitration clause",
        prompt: "Does the agreement contain an arbitration clause?",
    },
    {
        id: 8,
        type: "bool",
        title: "Indemnification clause",
        prompt: "Does the contract include an indemnification clause?",
    },
    {
        id: 9,
        type: "verbatim",
        title: "Indemnification obligations",
        prompt: "What are the indemnification obligations outlined in the contract?",
    },
    {
        id: 10,
        type: "longtext",
        title: "Payment terms",
        prompt: "Summarize the payment terms stated in the contract.",
    },
    {
        id: 11,
        type: "number",
        title: "Payment amount",
        prompt: "What is the total payment amount specified in the contract?",
    },
    {
        id: 12,
        type: "date",
        title: "Effective date",
        prompt: "What is the effective date of the contract?",
    },
    {
        id: 13,
        type: "date",
        title: "Expiration date",
        prompt: "What is the expiration date of the contract?",
    },
    {
        id: 14,
        type: "bool",
        title: "Auto-renewal",
        prompt: "Does the contract have an auto-renewal clause?",
    },
    {
        id: 15,
        type: "verbatim",
        title: "Auto-renewal terms",
        prompt: "What are the auto-renewal terms?",
    },
    {
        id: 16,
        type: "shorttext",
        title: "Jurisdiction",
        prompt: "What jurisdiction governs this contract?",
    },
    {
        id: 17,
        type: "number",
        title: "Limitation of liability",
        prompt: "What is the limitation of liability stated in the contract?",
    },
    {
        id: 18,
        type: "longtext",
        title: "Non-compete obligations",
        prompt: "Summarize any non-compete obligations in the contract.",
    },
    {
        id: 19,
        type: "bool",
        title: "Assignment allowed",
        prompt: "Is the contract assignable to another party?",
    },
    {
        id: 20,
        type: "longtext",
        title: "Intellectual property rights",
        prompt: "Summarize the intellectual property rights section.",
    },
    {
        id: 21,
        type: "verbatim",
        title: "IP ownership clause",
        prompt: "Extract the intellectual property ownership clause.",
    },
    {
        id: 22,
        type: "bool",
        title: "Liquidated damages",
        prompt: "Does the contract specify liquidated damages?",
    },
    {
        id: 23,
        type: "number",
        title: "Liquidated damages amount",
        prompt: "What is the amount of liquidated damages, if specified?",
    },
    {
        id: 24,
        type: "verbatim",
        title: "Dispute resolution mechanism",
        prompt: "Extract the dispute resolution mechanism clause.",
    },
    {
        id: 25,
        type: "longtext",
        title: "Warranty terms",
        prompt: "Summarize the warranty terms of the contract.",
    },
    {
        id: 26,
        type: "number",
        title: "Warranty period",
        prompt: "What is the warranty period stated in the contract?",
    },
    {
        id: 27,
        type: "date",
        title: "Signature date",
        prompt: "What is the date of signing?",
    },
    {
        id: 28,
        type: "bool",
        title: "Severability clause",
        prompt: "Does the contract contain a severability clause?",
    },
    {
        id: 29,
        type: "verbatim",
        title: "Severability clause",
        prompt: "Extract the severability clause.",
    },
    {
        id: 30,
        type: "bool",
        title: "Change of control clause",
        prompt: "Does the contract contain a change of control clause?",
    },
    {
        id: 31,
        type: "longtext",
        title: "Change of control terms",
        prompt: "Summarize the change of control terms.",
    },
    {
        id: 32,
        type: "shorttext",
        title: "Contract status",
        prompt: "Is this contract active, expired, or pending?",
    },
    {
        id: 33,
        type: "verbatim",
        title: "Scope of work",
        prompt: "Extract the scope of work from the contract.",
    },
    {
        id: 34,
        type: "longtext",
        title: "Service levels",
        prompt: "Summarize the service levels outlined in the agreement.",
    },
    {
        id: 35,
        type: "number",
        title: "Service level penalties",
        prompt: "What penalties are specified for failing to meet service levels?",
    },
    {
        id: 36,
        type: "bool",
        title: "Third-party involvement",
        prompt: "Does the contract allow third-party subcontracting?",
    },
    {
        id: 37,
        type: "shorttext",
        title: "Payment frequency",
        prompt: "What is the payment frequency (e.g., monthly, quarterly)?",
    },
    {
        id: 38,
        type: "bool",
        title: "Late payment penalties",
        prompt: "Are there penalties for late payments?",
    },
    {
        id: 39,
        type: "number",
        title: "Late payment penalty amount",
        prompt: "What is the late payment penalty amount?",
    },
    {
        id: 40,
        type: "longtext",
        title: "Data protection requirements",
        prompt: "Summarize the data protection obligations.",
    },
    {
        id: 41,
        type: "verbatim",
        title: "Data processing agreement clause",
        prompt: "Extract the data processing agreement clause.",
    },
    {
        id: 42,
        type: "shorttext",
        title: "Contract renewal method",
        prompt: "Is the contract renewal automatic, manual, or conditional?",
    },
    {
        id: 43,
        type: "longtext",
        title: "Penalties for breach",
        prompt: "Summarize the penalties for contract breaches.",
    },
    {
        id: 44,
        type: "bool",
        title: "Mutual termination",
        prompt: "Can the contract be terminated by mutual agreement?",
    },
    {
        id: 45,
        type: "verbatim",
        title: "Mutual termination terms",
        prompt: "What are the terms for mutual termination?",
    },
    {
        id: 46,
        type: "date",
        title: "Notice period for termination",
        prompt: "What is the notice period for contract termination?",
    },
    {
        id: 47,
        type: "longtext",
        title: "Regulatory compliance obligations",
        prompt: "Summarize any regulatory compliance requirements in the contract.",
    },
    {
        id: 48,
        type: "bool",
        title: "Non-solicitation clause",
        prompt: "Does the contract contain a non-solicitation clause?",
    },
    {
        id: 49,
        type: "longtext",
        title: "Non-solicitation terms",
        prompt: "Summarize the non-solicitation obligations.",
    },
    {
        id: 50,
        type: "shorttext",
        title: "Contract priority level",
        prompt: "Is this contract high, medium, or low priority?",
    },
];
var useColumns = function () {
    var dispatch = (0, redux_1.useAppDispatch)();
    var user = (0, auth0_react_1.useAuth0)().user;
    var t = (0, react_i18next_1.useTranslation)().t;
    var location = (0, react_router_dom_1.useLocation)();
    var insightId = (0, constants_1.getId)(location);
    var columnTemplates = (0, redux_1.useAppSelector)(function (state) { return state.insight.columnTemplates; });
    (0, react_1.useEffect)(function () {
        dispatch((0, insightActions_http_1.getColumnTemplates)(user));
    }, []);
    var initialColumnCenterValues = (0, react_1.useMemo)(function () { return columnCenterValues; }, []);
    var _a = (0, react_1.useState)(initialColumnCenterValues), filteredColumnCenterValues = _a[0], setFilteredColumnCenterValues = _a[1];
    var _b = (0, react_1.useState)([]), filteredOwnColumns = _b[0], setFilteredOwnColumns = _b[1];
    (0, react_1.useEffect)(function () {
        setFilteredOwnColumns(columnTemplates);
    }, [columnTemplates]);
    var halfColumnCenter = Math.ceil(filteredColumnCenterValues.length / 2);
    var firstHalfColumnCenterValues = filteredColumnCenterValues.slice(0, halfColumnCenter);
    var secondHalfColumnCenterValues = filteredColumnCenterValues.slice(halfColumnCenter);
    var halfOwn = Math.ceil(filteredOwnColumns.length / 2);
    var firstHalfOwnColumns = filteredOwnColumns.slice(0, halfOwn);
    var secondHalfOwnColumns = filteredOwnColumns.slice(halfOwn);
    var filterList = function (e) {
        var searchValue = e.target.value.toLowerCase();
        if (searchValue === "") {
            setFilteredColumnCenterValues(initialColumnCenterValues);
            setFilteredOwnColumns(columnTemplates);
            return;
        }
        var filteredValuesCenter = initialColumnCenterValues.filter(function (item) {
            return t(item.title).toLowerCase().includes(searchValue);
        });
        setFilteredColumnCenterValues(filteredValuesCenter);
        var filteredValuesOwn = columnTemplates.filter(function (item) {
            return t(item.title).toLowerCase().includes(searchValue);
        });
        setFilteredOwnColumns(filteredValuesOwn);
    };
    var addColumn = function (item) {
        dispatch((0, columnActions_websocket_1.postColumn)(item.prompt, item.title, item.type, insightId));
    };
    return {
        firstHalfColumnCenterValues: firstHalfColumnCenterValues,
        secondHalfColumnCenterValues: secondHalfColumnCenterValues,
        firstHalfOwnColumns: firstHalfOwnColumns,
        secondHalfOwnColumns: secondHalfOwnColumns,
        filterList: filterList,
        addColumn: addColumn,
    };
};
exports.default = useColumns;
