"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var x_data_grid_premium_1 = require("@mui/x-data-grid-premium");
var TaskGroupDataGrid_1 = require("./TaskGroupDataGrid");
var TaskGroupIndentedDataGrid = (0, material_1.styled)(x_data_grid_premium_1.DataGridPremium)(function () { return ({
    "& .MuiDataGrid-columnHeaderReorder": {
        borderLeft: "6px solid var(".concat(TaskGroupDataGrid_1.TASK_GROUP_CSS_VARIABLE, ")"),
    },
}); });
exports.default = TaskGroupIndentedDataGrid;
