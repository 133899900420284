"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var constants_1 = require("../constants");
var insightActions_websocket_1 = require("@pages/Insight/reducers/WebSocket/actions/insightActions.websocket");
var react_1 = require("react");
var columnActions_websocket_1 = require("@pages/Insight/reducers/WebSocket/actions/columnActions.websocket");
var rowActions_websocket_1 = require("@pages/Insight/reducers/WebSocket/actions/rowActions.websocket");
var getRowClassName = function (params) {
    if (!params.row.document)
        return "";
    return params.row.document.readable ? "" : "juristic-theme--error";
};
function useDataGrid() {
    var dispatch = (0, redux_1.useAppDispatch)();
    var onRowOrderChange = (0, react_1.useCallback)(function (_a) {
        var row = _a.row, oldIndex = _a.oldIndex, targetIndex = _a.targetIndex;
        dispatch((0, rowActions_websocket_1.changeRowIndex)(row.id, oldIndex, targetIndex));
    }, []);
    var onColumnOrderChange = (0, react_1.useCallback)(function (_a) {
        var column = _a.column, oldIndex = _a.oldIndex, targetIndex = _a.targetIndex;
        var id = Number(column.field);
        var deduction = constants_1.nonReorderableColumns.length;
        dispatch((0, columnActions_websocket_1.changeColumnIndex)(id, oldIndex - deduction, targetIndex - deduction));
    }, []);
    var onColumnWidthChange = (0, react_1.useCallback)(function (params) {
        var colDef = params.colDef, width = params.width;
        var id = Number(colDef.field);
        switch (colDef.field) {
            case constants_1.MAIN_COLUMN:
                dispatch((0, insightActions_websocket_1.changeInsigtWidth)(width, "itemWidth"));
                break;
            case constants_1.CATEGORY_COLUMN:
                dispatch((0, insightActions_websocket_1.changeInsigtWidth)(width, "categoryWidth"));
                break;
            case constants_1.INFO_COLUMN:
                dispatch((0, insightActions_websocket_1.changeInsigtWidth)(width, "infoWidth"));
                break;
            default:
                dispatch((0, columnActions_websocket_1.changeColumnWidth)(id, width));
                break;
        }
    }, []);
    return {
        onColumnOrderChange: onColumnOrderChange,
        onRowOrderChange: onRowOrderChange,
        onColumnWidthChange: onColumnWidthChange,
        getRowClassName: getRowClassName,
    };
}
exports.default = useDataGrid;
