"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.px2cm = exports.cm2px = exports.cm2inch = exports.inch2px = void 0;
var constants_1 = require("./constants");
var inch2px = function (inch) { return inch * constants_1.PPI; };
exports.inch2px = inch2px;
var cm2inch = function (cm) { return cm / constants_1.CM_PR_INCH; };
exports.cm2inch = cm2inch;
var cm2px = function (cm) { return (0, exports.inch2px)((0, exports.cm2inch)(cm)); };
exports.cm2px = cm2px;
var px2cm = function (px) { return px / constants_1.PX_PR_CM; };
exports.px2cm = px2cm;
