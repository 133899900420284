"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Tools;
(function (Tools) {
    Tools["Workspace"] = "workspace";
    Tools["Structure"] = "structure";
    Tools["Timeline"] = "timeline";
    Tools["Deadline"] = "deadline";
    Tools["Board"] = "board";
    Tools["Insight"] = "insight";
    Tools["JuristIQ"] = "juristIQ";
})(Tools || (Tools = {}));
exports.default = Tools;
