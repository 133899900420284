"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withPreservedSelection = exports.restoreSelection = exports.saveSelection = void 0;
// Store the current editor selection
var savedSelection = null;
/**
 * Saves the current editor selection state
 * @param editor The Froala editor instance
 */
function saveSelection(editor) {
    if (!editor)
        return;
    try {
        savedSelection = editor.selection.get();
        // Also save HTML markers for more reliable restoration
        editor.selection.save();
    }
    catch (e) {
        console.error("Error saving selection:", e);
    }
}
exports.saveSelection = saveSelection;
/**
 * Restores the previously saved selection
 * @param editor The Froala editor instance
 */
function restoreSelection(editor) {
    if (!editor || !savedSelection)
        return;
    try {
        // First focus the editor
        editor.events.focus();
        // Restore from HTML markers first (more reliable)
        editor.selection.restore();
        // Then also try the direct approach as backup
        // editor.selection.setWithRange(savedSelection);
    }
    catch (e) {
        console.error("Error restoring selection:", e);
    }
}
exports.restoreSelection = restoreSelection;
/**
 * Wrapper to execute a command while preserving text selection
 * @param editor The Froala editor instance
 * @param callback The function to execute
 */
function withPreservedSelection(editor, callback) {
    if (!editor)
        return;
    saveSelection(editor);
    callback();
    // Use setTimeout to ensure the UI updates complete before restoring selection
    setTimeout(function () {
        restoreSelection(editor);
    }, 10);
}
exports.withPreservedSelection = withPreservedSelection;
