"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var PanelItemIcon = (0, material_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        "& > svg": {
            fontSize: theme.typography.pxToRem(28),
        },
    });
});
exports.default = PanelItemIcon;
