"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_dom_1 = require("react-dom");
var reactflow_1 = require("reactflow");
var shallow_1 = require("zustand/shallow");
var selector = function (s) { return s.nodesSelectionActive; };
function SelectionPortal(_a) {
    var children = _a.children;
    var isNodesSelectionActive = (0, reactflow_1.useStore)(selector, shallow_1.shallow);
    var _b = (0, react_1.useState)(null), selectionElement = _b[0], setSetSelectionElement = _b[1];
    (0, react_1.useEffect)(function () {
        setSetSelectionElement(document.querySelector(".react-flow__nodesselection-rect"));
    }, [isNodesSelectionActive]);
    if (!selectionElement) {
        return null;
    }
    return (0, react_dom_1.createPortal)(children, selectionElement);
}
exports.default = SelectionPortal;
