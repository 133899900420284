"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iManageDocumentToFileLike = exports.findItemByIdInTree = exports.splitImanageItemToContainerAndDocuments = void 0;
function splitImanageItemToContainerAndDocuments(items) {
    return items.reduce(function (acc, item) {
        var _containers = acc[0], _documents = acc[1];
        if (item.wstype === "document" || item.wstype === "email") {
            _documents.push(item);
        }
        else {
            _containers.push(item);
        }
        return acc;
    }, [[], []]);
}
exports.splitImanageItemToContainerAndDocuments = splitImanageItemToContainerAndDocuments;
function findItemByIdInTree(root, id) {
    var queue = [root]; // Start with the root node
    while (queue.length > 0) {
        var current = queue.shift();
        if (!current)
            return null;
        if (current.id === id)
            return current;
        if ("children" in current) {
            queue.push.apply(queue, Object.values(current.children));
        }
    }
    return null;
}
exports.findItemByIdInTree = findItemByIdInTree;
function iManageDocumentToFileLike(document) {
    return {
        type: "imanage",
        id: document.id,
        name: document.name,
        size: document.size,
    };
}
exports.iManageDocumentToFileLike = iManageDocumentToFileLike;
