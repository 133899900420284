"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onUpdateRowIndex = exports.onDeleteRows = exports.onDeleteRow = void 0;
function onDeleteRow() {
    return function (dispatch) { return function (rowId) {
        dispatch({ type: "INSIGHT_DELETE_ROW_SUCCESS", rowId: rowId });
    }; };
}
exports.onDeleteRow = onDeleteRow;
function onDeleteRows() {
    return function (dispatch) { return function (rowIds) {
        dispatch({ type: "INSIGHT_DELETE_ROWS_SUCCESS", rowIds: rowIds });
    }; };
}
exports.onDeleteRows = onDeleteRows;
function onUpdateRowIndex() {
    return function (dispatch) { return function (payload) {
        dispatch(__assign({ type: "INSIGHT_CHANGE_ROW_INDEX" }, payload));
    }; };
}
exports.onUpdateRowIndex = onUpdateRowIndex;
