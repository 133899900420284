"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var insight_1 = require("@customTypes/reducers/insight");
var arrayMove_1 = require("@utils/arrayMove");
var initialLoadings = __assign(__assign({}, constants_1.initialLoadings), { recents: false, table: false, columnTemplates: false, excelDownload: false, column: false, columnQuestion: false, columnLoadings: {} });
var initialState = {
    loadings: initialLoadings,
    insights: [],
    insight: insight_1.initialInsight,
    drawerRow: null,
    recentInsights: [],
    columns: [],
    rows: [],
    columnTemplates: [],
    isShowingDocument: false,
    failedUploads: [],
    progresses: [],
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    var _0;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "CLEAR_STATE_INSIGHT":
            return initialState;
        case "GET_INSIGHTS_FAILED":
        case "STORE_INSIGHT_FAILED":
        case "STORE_INSIGHT_SUCCESS":
        case "INSIGHT_CHANGE_ITEM_WIDTH_FAILED":
        case "INSIGHT_CHANGE_COLUMN_WIDTH_FAILED":
        case "SHOW_INSIGHT_FAILED":
        case "GET_RECENT_INSIGHTS_FAILED":
        case "INSIGHT_UPDATE_COLUMN_FAILED":
        case "INSIGHT_POST_COLUMN_FAILED":
        case "DELETE_INSIGHT_FAILED":
        case "GET_TITLE_FROM_QUESTION_SUCCESS":
        case "GET_TITLE_FROM_QUESTION_FAILED":
        case "INSIGHT_DELETE_ROW_FAILED":
        case "INSIGHT_DELETE_ROWS_FAILED":
        case "INSIGHT_EXCEL_DOWNLOAD_SUCCESS":
        case "INSIGHT_UPLOAD_FILES_FAILED":
        case "IMPORT_INSIGHT_TEMPLATE_FAILED":
        case "GET_COLUMN_TEMPLATES_FAILED":
        case "POST_COLUMN_TEMPLATE_FAILED":
        case "UPDATE_COLUMN_TEMPLATE_FAILED":
        case "INSIGHT_EXCEL_DOWNLOAD_FAILED":
        case "MAKE_TIMELINE_FROM_FAILED":
        case "DELETE_COLUMN_TEMPLATE_FAILED":
            return __assign(__assign({}, state), (action.loadingType && __assign({}, (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = false, _a)),
            }))));
        case "GET_INSIGHTS_LOADING":
        case "STORE_INSIGHT_LOADING":
        case "GET_RECENT_INSIGHTS_LOADING":
        case "GET_TITLE_FROM_QUESTION_LOADING":
        case "INSIGHT_DELETE_ROW_LOADING":
        case "INSIGHT_DELETE_ROWS_LOADING":
        case "MAKE_TIMELINE_FROM_LOADING":
        case "INSIGHT_CHANGE_ITEM_WIDTH_LOADING":
        case "INSIGHT_UPDATE_COLUMN_LOADING":
        case "INSIGHT_CHANGE_COLUMN_WIDTH_LOADING":
        case "INSIGHT_CHANGE_COLUMN_INDEX_LOADING":
        case "INSIGHT_CHANGE_ROW_INDEX_LOADING":
        case "INSIGHT_EXCEL_DOWNLOAD_LOADING":
        case "INSIGHT_POST_COLUMN_LOADING":
        case "IMPORT_INSIGHT_TEMPLATE_LOADING":
        case "SHOW_INSIGHT_LOADING":
        case "DELETE_INSIGHT_LOADING":
        case "INSIGHT_UPLOAD_FILES_LOADING":
        case "GET_COLUMN_TEMPLATES_LOADING":
        case "POST_COLUMN_TEMPLATE_LOADING":
        case "UPDATE_COLUMN_TEMPLATE_LOADING":
        case "DELETE_COLUMN_TEMPLATE_LOADING":
            return __assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = true, _b)),
            }));
        case "GET_INSIGHTS_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = false, _c)),
            })), { insights: action.insights });
        case "GET_RECENT_INSIGHTS_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = false, _d)),
            })), { recentInsights: action.insights });
        case "CLEAR_RECENT_INSIGHTS":
            return __assign(__assign({}, state), { recentInsights: [] });
        case "SHOW_INSIGHT_SUCCESS":
        case "IMPORT_INSIGHT_TEMPLATE_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_e = {}, _e[action.loadingType] = false, _e)),
            })), { insight: action.insight, columns: action.columns, rows: action.rows });
        case "CREATE_INSIGHT":
            return __assign(__assign({}, state), { insight: insight_1.initialInsight });
        case "DELETE_INSIGHT_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_f = {}, _f[action.loadingType] = false, _f)),
            })), { insights: state.insights.filter(function (insight) { return insight.id !== action.id; }) });
        case "INSIGHT_CHANGE":
            return __assign(__assign({}, state), { insight: __assign(__assign({}, state.insight), (_g = {}, _g[action.key] = action.value, _g)) });
        case "INSIGHT_CHANGE_ITEM_WIDTH_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_h = {}, _h[action.loadingType] = false, _h)),
            })), { insight: __assign(__assign({}, state.insight), { itemWidth: action.item, categoryWidth: action.category, infoWidth: action.info }) });
        case "INSIGHT_CHANGE_COLUMN_WIDTH_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_j = {}, _j[action.loadingType] = false, _j)),
            })), { columns: state.columns.map(function (column) {
                    if (column.id === action.columnId) {
                        return __assign(__assign({}, column), { width: action.width });
                    }
                    return column;
                }) });
        case "INSIGHT_CHANGE_COLUMN_INDEX_FAILED":
        case "INSIGHT_CHANGE_COLUMN_INDEX":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_k = {}, _k[action.loadingType] = false, _k)),
            })), { columns: (0, arrayMove_1.arrayMoveElement)(state.columns, action.oldIndex, action.targetIndex).map(function (column, index) { return (__assign(__assign({}, column), { index: index })); }) });
        case "INSIGHT_CHANGE_ROW_INDEX_FAILED":
        case "INSIGHT_CHANGE_ROW_INDEX":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_l = {}, _l[action.loadingType] = false, _l)),
            })), { rows: (0, arrayMove_1.arrayMoveElement)(state.rows, action.oldIndex, action.targetIndex).map(function (row, index) { return (__assign(__assign({}, row), { index: index })); }) });
        case "INSIGHT_POST_COLUMN_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_m = {}, _m[action.loadingType] = false, _m)),
            })), { columns: __spreadArray(__spreadArray([], state.columns, true), [action.column], false) });
        case "INSIGHT_DELETE_COLUMN_FAILED":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { columnLoadings: __assign(__assign({}, state.loadings.columnLoadings), (_o = {}, _o[action.columnId] = false, _o)) }) });
        case "INSIGHT_DELETE_COLUMN_LOADING":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { columnLoadings: __assign(__assign({}, state.loadings.columnLoadings), (_p = {}, _p[action.columnId] = true, _p)) }) });
        case "INSIGHT_DELETE_COLUMN_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { columnLoadings: __assign(__assign({}, state.loadings.columnLoadings), (_q = {}, _q[action.columnId] = false, _q)) }), columns: state.columns.filter(function (column) { return column.id !== action.columnId; }) });
        case "INSIGHT_UPDATE_COLUMN_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_r = {}, _r[action.loadingType] = false, _r)),
            })), { columns: state.columns.map(function (column) {
                    if (column.id === action.column.id) {
                        return action.column;
                    }
                    return column;
                }) });
        case "TOGGLE_SHOW_DOCUMENT":
            return __assign(__assign({}, state), { isShowingDocument: !state.isShowingDocument });
        case "INSIGHT_UPLOAD_FILES_SUCCESS":
            return __assign(__assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_s = {}, _s[action.loadingType] = false, _s)),
            })), { rows: __spreadArray(__spreadArray([], state.rows, true), action.rows, true) }), (action.failedUploads &&
                action.failedUploads.length > 0 && {
                failedUploads: __spreadArray(__spreadArray([], state.failedUploads, true), action.failedUploads, true),
            }));
        case "INSIGHT_AI_FILE_META_CHUNK": {
            var drawerRow_1 = state.drawerRow;
            var rows = state.rows.map(function (row) {
                var _a;
                if (row.id === action.payload.rowId) {
                    row = __assign(__assign({}, row), (_a = { category: action.payload.aiData.legalDocType, info: action.payload.aiData.description }, _a[action.payload.loadingId] = false, _a));
                    if (row.id === (drawerRow_1 === null || drawerRow_1 === void 0 ? void 0 : drawerRow_1.id)) {
                        drawerRow_1 = row;
                    }
                }
                return row;
            });
            return __assign(__assign({}, state), { rows: rows, drawerRow: drawerRow_1 });
        }
        case "INSIGHT_AI_ANALYSIS_CHUNK": {
            var drawerRow_2 = state.drawerRow;
            var rows = state.rows.map(function (row) {
                var _a;
                if (row.id === action.payload.rowId) {
                    row = __assign(__assign({}, row), (_a = {}, _a[action.payload.columnId] = action.payload, _a[action.payload.loadingId] = false, _a));
                    if (row.id === (drawerRow_2 === null || drawerRow_2 === void 0 ? void 0 : drawerRow_2.id)) {
                        drawerRow_2 = row;
                    }
                }
                return row;
            });
            return __assign(__assign({}, state), { rows: rows, drawerRow: drawerRow_2 });
        }
        case "DRAWER_ROW":
            return __assign(__assign({}, state), { drawerRow: action.row });
        case "INSIGHT_REMOVE_PROGRESS":
            return __assign(__assign({}, state), { progresses: state.progresses.filter(function (progress) {
                    return !(progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name);
                }) });
        case "INSIGHT_DOCUMENTS_OCR_STARTED":
        case "INSIGHT_PROGRESS_STARTED":
        case "INSIGHT_UPLOAD_INDEXATION_STARTED":
            return __assign(__assign({}, state), { progresses: __spreadArray(__spreadArray([], state.progresses, true), [__assign(__assign({}, action.progress), { state: "not-started" })], false) });
        case "INSIGHT_PROGRESS_COMPLETED":
            return __assign(__assign({}, state), { progresses: state.progresses.map(function (progress) {
                    if (progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name) {
                        return __assign(__assign({}, action.progress), { state: "completed" });
                    }
                    return progress;
                }) });
        case "INSIGHT_UPLOAD_INDEXATION_COMPLETED":
            return __assign(__assign({}, state), { rows: state.rows.map(function (row) { return (__assign(__assign({}, row), (row.document && {
                    document: __assign(__assign({}, row.document), { readable: action.unreadableRowIds.includes(row.id)
                            ? false
                            : row.document.readable }),
                }))); }), progresses: state.progresses.map(function (progress) {
                    if (progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name) {
                        return __assign(__assign({}, action.progress), { state: "completed" });
                    }
                    return progress;
                }) });
        case "INSIGHT_UPLOAD_INDEXATION_CHUNK":
            return __assign(__assign({}, state), { rows: state.rows.map(function (row) { return (__assign(__assign({}, row), (row.document && {
                    document: __assign(__assign({}, row.document), { readable: action.unreadableRowIds.includes(row.id)
                            ? false
                            : row.document.readable }),
                }))); }) });
        case "INSIGHT_PROGRESS_FAILED":
        case "INSIGHT_DOCUMENTS_OCR_FAILED":
        case "INSIGHT_UPLOAD_INDEXATION_FAILED": {
            return __assign(__assign({}, state), { progresses: state.progresses.map(function (progress) {
                    if (progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name) {
                        return __assign(__assign({}, action.progress), { state: "failed" });
                    }
                    return progress;
                }) });
        }
        case "INSIGHT_PROGRESS":
        case "INSIGHT_DOCUMENTS_OCR_PROGRESS":
        case "INSIGHT_UPLOAD_INDEXATION_PROGRESS": {
            var index = state.progresses.findIndex(function (progress) {
                return progress.jobId === action.progress.jobId &&
                    progress.name === action.progress.name;
            });
            var progresses = __spreadArray([], state.progresses, true);
            var progressState = ((_0 = progresses[index]) === null || _0 === void 0 ? void 0 : _0.state) || "started";
            var progress = __assign(__assign({}, action.progress), { state: progressState === "failed" ? "failed" : "started" });
            if (index < 0) {
                progresses.push(progress);
            }
            else {
                progresses.splice(index, 1, progress);
            }
            return __assign(__assign({}, state), { progresses: progresses });
        }
        case "INSIGHT_AI_FILE_META_STARTED":
        case "INSIGHT_AI_ANALYSIS_STARTED": {
            var rowIds_1 = action.loadingIds.reduce(function (acc, loadingId) {
                var rowId = Number(loadingId.split("-")[1]);
                if (!acc[rowId]) {
                    acc[rowId] = {};
                }
                acc[rowId][loadingId] = true;
                return acc;
            }, {});
            return __assign(__assign({}, state), { rows: state.rows.map(function (row) {
                    var rowId = row.id;
                    var loadingIds = rowIds_1[rowId];
                    if (loadingIds) {
                        return __assign(__assign({}, row), loadingIds);
                    }
                    return row;
                }) });
        }
        case "INSIGHT_AI_FILE_META_COMPLETED":
        case "INSIGHT_AI_FILE_META_FAILED":
        case "INSIGHT_AI_ANALYSIS_COMPLETED":
        case "INSIGHT_AI_ANALYSIS_FAILED": {
            var rowIds_2 = action.loadingIds.reduce(function (acc, loadingId) {
                var rowId = Number(loadingId.split("-")[1]);
                if (!acc[rowId]) {
                    acc[rowId] = {};
                }
                acc[rowId][loadingId] = false;
                return acc;
            }, {});
            return __assign(__assign({}, state), { rows: state.rows.map(function (row) {
                    var rowId = row.id;
                    var loadingIds = rowIds_2[rowId];
                    if (loadingIds) {
                        return __assign(__assign({}, row), loadingIds);
                    }
                    return row;
                }) });
        }
        case "GET_COLUMN_TEMPLATES_SUCCESS":
            return __assign(__assign(__assign({}, state), { columnTemplates: action.templates }), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_t = {}, _t[action.loadingType] = false, _t)),
            }));
        case "POST_COLUMN_TEMPLATE_SUCCESS":
            return __assign(__assign(__assign({}, state), { columnTemplates: __spreadArray(__spreadArray([], state.columnTemplates, true), [action.template], false) }), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_u = {}, _u[action.loadingType] = false, _u)),
            }));
        case "UPDATE_COLUMN_TEMPLATE_SUCCESS":
            return __assign(__assign(__assign({}, state), { columnTemplates: state.columnTemplates.map(function (template) {
                    return template.id === action.template.id ? action.template : template;
                }) }), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_v = {}, _v[action.loadingType] = false, _v)),
            }));
        case "DELETE_COLUMN_TEMPLATE_SUCCESS":
            return __assign(__assign(__assign({}, state), { columnTemplates: state.columnTemplates.filter(function (template) { return template.id !== action.id; }) }), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_w = {}, _w[action.loadingType] = false, _w)),
            }));
        case "INSIGHT_DELETE_ROW_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_x = {}, _x[action.loadingType] = false, _x)),
            })), { rows: state.rows.filter(function (row) { return row.id !== action.rowId; }) });
        case "INSIGHT_DELETE_ROWS_SUCCESS":
            return __assign(__assign(__assign({}, state), (action.loadingType && {
                loadings: __assign(__assign({}, state.loadings), (_y = {}, _y[action.loadingType] = false, _y)),
            })), { rows: state.rows.filter(function (row) { return !action.rowIds.includes(row.id); }) });
        case "INSIGHT_DOCUMENTS_OCR_CHUNK":
            return __assign(__assign({}, state), { rows: state.rows.map(function (row) { return (__assign(__assign({}, row), (row.document && {
                    document: __assign(__assign({}, row.document), { readable: row.document.id === action.payload.readableDocument
                            ? true
                            : row.document.readable }),
                }))); }) });
        case "INSIGHT_DOCUMENTS_OCR_COMPLETED":
            return __assign(__assign({}, state), { rows: state.rows.map(function (row) { return (__assign(__assign({}, row), (row.document && {
                    document: __assign(__assign({}, row.document), { readable: action.readableDocuments.includes(row.document.id)
                            ? true
                            : row.document.readable }),
                }))); }), progresses: state.progresses.map(function (progress) {
                    if (progress.jobId === action.progress.jobId &&
                        progress.name === action.progress.name) {
                        return __assign(__assign(__assign({}, progress), action.progress), { state: "completed" });
                    }
                    return progress;
                }) });
        case "INSIGHT_CLEAR_FAILED_UPLOADS":
            return __assign(__assign({}, state), { failedUploads: [] });
        case "MAKE_TIMELINE_FROM_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (action.loadingType && __assign({}, (action.loadingType && {
                    loadings: __assign(__assign({}, state.loadings), (_z = {}, _z[action.loadingType] = false, _z)),
                })))) });
        default:
            return state;
    }
}
exports.default = reducer;
