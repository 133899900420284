"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialInsight = void 0;
var lawsuits_1 = require("./lawsuits");
exports.initialInsight = {
    title: "",
    description: "",
    groupOrLawsuit: lawsuits_1.INITIALLAWSUIT,
    isDraft: false,
    itemWidth: 100,
    categoryWidth: 100,
    infoWidth: 100,
    tags: [],
};
