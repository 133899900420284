"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var chronoUtils_1 = require("./chronoUtils");
var material_1 = require("@mui/material");
var date_fns_1 = require("date-fns");
var HourMarks = function (_a) {
    var x = _a.x, zoom = _a.zoom, stretch = _a.stretch, styles = _a.styles, t = _a.t;
    var classes = styles.classes;
    var realX = -x / zoom;
    var leftDate = (0, chronoUtils_1.coordinateToDate)(realX, stretch);
    var width = window.innerWidth;
    var loopLength = width / (stretch / (48 / zoom));
    var lines = [];
    for (var i = 0; i < loopLength; i += 1) {
        var date = new Date(leftDate);
        date.setUTCHours(date.getUTCHours() + i);
        var dateX = (0, chronoUtils_1.dateToCoordinate)(date, stretch) * zoom;
        var isZeroHour = date.getUTCHours() === 0;
        var text = "";
        if (isZeroHour) {
            text = date.getUTCDate();
        }
        else {
            var time = date.toISOString().split("T")[1].split(":");
            text = time[0] + ":" + time[1];
        }
        lines.push(react_1.default.createElement(material_1.Box, { key: "hour-" + date.toISOString(), className: classes.marker, sx: [{
                    transform: "translateX(".concat(dateX, "px)"),
                    border: "none !important"
                }, isZeroHour ? {
                    opacity: 1
                } : {
                    opacity: 0.7
                }] }, isZeroHour ? (react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap", sx: { transform: "translateX(-50%)", mt: 2 } }, text)) : (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(material_1.Box, { className: classes.markerLine, style: { height: "7px" } }),
            react_1.default.createElement(material_1.Box, { className: classes.markerText }, text)))));
    }
    for (var i = -50; i < 50; i++) {
        var date = new Date(leftDate);
        date.setUTCDate(date.getUTCDate() + i);
        var dateX = (0, chronoUtils_1.dateToCoordinate)(date, stretch) * zoom;
        var endDate = new Date(date);
        endDate.setUTCDate(endDate.getUTCDate() + 1);
        var endDateX = (0, chronoUtils_1.dateToCoordinate)(endDate, stretch) * zoom;
        var dateWidth = Math.abs(dateX - endDateX);
        var month = t("months.".concat(date.getUTCMonth()));
        var monthYearDate = "".concat(date.getUTCDate(), " ").concat(month, " - ").concat(date.getUTCFullYear());
        lines.push(react_1.default.createElement(material_1.Box, { key: "day-month-year-" + monthYearDate, className: classes.marker, sx: {
                transform: "translateX(".concat(dateX, "px)"),
                width: dateWidth,
                bottom: -1,
            } },
            react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, monthYearDate)));
    }
    return lines;
};
var MemoHourMarks = react_1.default.memo(HourMarks);
var DateMarks = function (_a) {
    var x = _a.x, zoom = _a.zoom, stretch = _a.stretch, styles = _a.styles, t = _a.t;
    var cx = styles.cx, classes = styles.classes;
    var realX = -x / zoom;
    var leftDate = (0, chronoUtils_1.coordinateToDate)(realX, stretch);
    var width = window.innerWidth;
    var loopLength = width / (stretch * zoom);
    var lines = [];
    var startX;
    for (var i = -7; i < loopLength; i += 1) {
        var date = new Date(leftDate);
        date.setUTCDate(date.getUTCDate() + i);
        if (startX === undefined) {
            startX = (0, chronoUtils_1.dateToCoordinate)(date, stretch) * zoom;
        }
        var endDate = new Date(date);
        endDate.setUTCDate(endDate.getUTCDate() + 1);
        var endX = (0, chronoUtils_1.dateToCoordinate)(endDate, stretch) * zoom;
        var boxWidth = Math.abs(endX - startX);
        var day = date.getUTCDate();
        lines.push(react_1.default.createElement(material_1.Box, { key: "date-" + date.toISOString(), className: classes.marker, sx: {
                transform: "translateX(".concat(startX, "px)"),
                width: boxWidth,
                bottom: -1,
            } },
            react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, day)));
        startX = endX;
    }
    for (var i = -50; i < 50; i++) {
        var thisMonth = new Date(leftDate);
        thisMonth.setUTCDate(1);
        thisMonth.setUTCMonth(thisMonth.getUTCMonth() + i);
        var startMonthX = (0, chronoUtils_1.dateToCoordinate)(thisMonth, stretch) * zoom;
        var nextMonth = new Date(thisMonth);
        nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1);
        var endMonthX = (0, chronoUtils_1.dateToCoordinate)(nextMonth, stretch) * zoom;
        var yearWidth = Math.abs(endMonthX - startMonthX);
        var month = t("months.".concat(thisMonth.getUTCMonth()));
        var text = "".concat(month, " - ").concat(thisMonth.getUTCFullYear());
        lines.push(react_1.default.createElement(material_1.Box, { key: "week-month-" + text, className: cx(classes.marker, classes.markerAlt), sx: {
                transform: "translateX(".concat(startMonthX, "px)"),
                width: yearWidth,
            } },
            react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, text)));
    }
    return lines;
};
var MemoDateMarks = react_1.default.memo(DateMarks);
var WeekMarks = function (_a) {
    var x = _a.x, zoom = _a.zoom, stretch = _a.stretch, styles = _a.styles, t = _a.t;
    var cx = styles.cx, classes = styles.classes;
    var realX = -x / zoom;
    var leftDate = (0, chronoUtils_1.coordinateToDate)(realX, stretch);
    // set to monday
    leftDate.setUTCDate(leftDate.getUTCDate() - leftDate.getUTCDay() + 1);
    var width = window.innerWidth;
    var loopLength = width / (stretch * zoom) / 7;
    var lines = [];
    var startX;
    for (var i = -2; i < loopLength; i += 1) {
        var date = new Date(leftDate);
        date.setUTCDate(date.getUTCDate() + i * 7);
        if (startX === undefined) {
            startX = (0, chronoUtils_1.dateToCoordinate)(date, stretch) * zoom;
        }
        var endDate = new Date(date);
        endDate.setUTCDate(endDate.getUTCDate() + 7);
        var endX = (0, chronoUtils_1.dateToCoordinate)(endDate, stretch) * zoom;
        var boxWidth = Math.abs(endX - startX);
        var year = date.getUTCFullYear();
        var week = (0, date_fns_1.getWeek)(date, { weekStartsOn: 1, firstWeekContainsDate: 4 });
        lines.push(react_1.default.createElement(material_1.Box, { key: "week-" + week + "-" + year, className: classes.marker, sx: {
                transform: "translateX(".concat(startX, "px)"),
                width: boxWidth,
                bottom: -1,
            } },
            react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, t("generic.week").substring(0, 1) + week)));
        startX = endX;
    }
    for (var i = -50; i < 50; i++) {
        var thisMonth = new Date(leftDate);
        thisMonth.setUTCDate(1);
        thisMonth.setUTCMonth(thisMonth.getUTCMonth() + i);
        var startMonthX = (0, chronoUtils_1.dateToCoordinate)(thisMonth, stretch) * zoom;
        var nextMonth = new Date(thisMonth);
        nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1);
        var endMonthX = (0, chronoUtils_1.dateToCoordinate)(nextMonth, stretch) * zoom;
        var yearWidth = Math.abs(endMonthX - startMonthX);
        var month = t("months.".concat(thisMonth.getUTCMonth()));
        var text = "".concat(month, " - ").concat(thisMonth.getUTCFullYear());
        lines.push(react_1.default.createElement(material_1.Box, { key: "week-month-" + text, className: cx(classes.marker, classes.markerAlt), sx: {
                transform: "translateX(".concat(startMonthX, "px)"),
                width: yearWidth,
            } },
            react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, text)));
    }
    return lines;
};
var MemoWeekMarks = react_1.default.memo(WeekMarks);
var MonthMarks = function (_a) {
    var x = _a.x, zoom = _a.zoom, stretch = _a.stretch, styles = _a.styles, t = _a.t;
    var cx = styles.cx, classes = styles.classes;
    var realX = -x / zoom;
    var leftDate = (0, chronoUtils_1.coordinateToDate)(realX, stretch);
    // set to the first of the month
    leftDate.setUTCDate(1);
    var width = window.innerWidth;
    var loopLength = width / (stretch * 24 * zoom);
    var lines = [];
    var startX;
    for (var i = -12; i < loopLength; i += 1) {
        var date = new Date(leftDate);
        date.setUTCMonth(date.getUTCMonth() + i);
        if (startX === undefined) {
            startX = (0, chronoUtils_1.dateToCoordinate)(date, stretch) * zoom;
        }
        var endDate = new Date(date);
        endDate.setUTCMonth(endDate.getUTCMonth() + 1);
        var endX = (0, chronoUtils_1.dateToCoordinate)(endDate, stretch) * zoom;
        var boxWidth = Math.abs(endX - startX);
        var month = t("months.".concat(date.getUTCMonth()));
        var year = date.getUTCFullYear();
        var text = "".concat(month[0]).concat(month[1]).concat(month[2]);
        lines.push(react_1.default.createElement(material_1.Box, { key: "month-" + month + "-" + year, className: classes.marker, sx: {
                transform: "translateX(".concat(startX, "px)"),
                width: boxWidth,
                bottom: -1,
            } },
            react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, text)));
        if (date.getUTCMonth() === 0) {
            var nextYear = new Date(date);
            nextYear.setUTCMonth(nextYear.getUTCMonth() + 12);
            var endYearX = (0, chronoUtils_1.dateToCoordinate)(nextYear, stretch) * zoom;
            var yearWidth = Math.abs(endYearX - startX);
            lines.push(react_1.default.createElement(material_1.Box, { key: "year-" + year, className: cx(classes.marker, classes.markerAlt), sx: {
                    transform: "translateX(".concat(startX, "px)"),
                    width: yearWidth,
                } },
                react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, date.getUTCFullYear())));
        }
        startX = endX;
    }
    return lines;
};
var MemoMonthMarks = react_1.default.memo(MonthMarks);
var YearMarks = function (_a) {
    var x = _a.x, zoom = _a.zoom, stretch = _a.stretch, styles = _a.styles;
    var cx = styles.cx, classes = styles.classes;
    var realX = -x / zoom;
    var leftDate = (0, chronoUtils_1.coordinateToDate)(realX, stretch);
    // set to the first of the month
    leftDate.setUTCMonth(0);
    leftDate.setUTCDate(1);
    var lines = [];
    var startX;
    for (var i = -40; i < 40; i++) {
        var date = new Date(leftDate);
        date.setUTCMonth(date.getUTCMonth() + i * 12);
        if (startX === undefined) {
            startX = (0, chronoUtils_1.dateToCoordinate)(date, stretch) * zoom;
        }
        var endDate = new Date(date);
        endDate.setUTCMonth(endDate.getUTCMonth() + 12);
        var endX = (0, chronoUtils_1.dateToCoordinate)(endDate, stretch) * zoom;
        var boxWidth = Math.abs(endX - startX);
        var year = date.getUTCFullYear();
        lines.push(react_1.default.createElement(material_1.Box, { key: "year-long-" + year, className: cx(classes.marker, classes.markerAlt), sx: {
                transform: "translateX(".concat(startX, "px)"),
                width: boxWidth,
            } },
            react_1.default.createElement(material_1.Typography, { className: classes.markerFont, variant: "body2", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }, year)));
        startX = endX;
    }
    return lines;
};
var MemoYearMarks = react_1.default.memo(YearMarks);
function Marks(_a) {
    var x = _a.x, zoom = _a.zoom, stretch = _a.stretch, t = _a.t, styles = _a.styles;
    var comparator = (0, react_1.useMemo)(function () { return stretch * (zoom + 0.25); }, [stretch, zoom]);
    if (comparator > 300) {
        return react_1.default.createElement(MemoHourMarks, { x: x, zoom: zoom, stretch: stretch, styles: styles, t: t });
    }
    if (comparator > 30) {
        return react_1.default.createElement(MemoDateMarks, { x: x, zoom: zoom, stretch: stretch, styles: styles, t: t });
    }
    if (comparator > 5) {
        return react_1.default.createElement(MemoWeekMarks, { x: x, zoom: zoom, stretch: stretch, styles: styles, t: t });
    }
    if (comparator > 3.5) {
        return react_1.default.createElement(MemoMonthMarks, { x: x, zoom: zoom, stretch: stretch, styles: styles, t: t });
    }
    return react_1.default.createElement(MemoYearMarks, { x: x, zoom: zoom, stretch: stretch, styles: styles });
}
exports.default = react_1.default.memo(Marks);
