"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTFlowNode = exports.nodeTypesWithHandlesSet = exports.TWElementVariantWithHandles = exports.WElementVariant = void 0;
exports.WElementVariant = {
    CUSTOM: "custom",
    TEXT: "text",
    STICKY: "sticky",
    ICON: "icon",
    FILE: "file",
    GROUP: "customgroup",
    DRILLDOWN: "drilldown",
    GHOST: "ghost",
};
function pick(obj, keys) {
    var result = {};
    keys.forEach(function (key) {
        result[key] = obj[key];
    });
    return result;
}
exports.TWElementVariantWithHandles = pick(exports.WElementVariant, [
    "CUSTOM",
    "DRILLDOWN",
    "GHOST",
]);
exports.nodeTypesWithHandlesSet = new Set(Object.values(exports.TWElementVariantWithHandles).map(function (v) { return v; }));
var isTFlowNode = function (workspaceNode) {
    return workspaceNode.type === exports.WElementVariant.CUSTOM ||
        workspaceNode.type === exports.WElementVariant.DRILLDOWN;
};
exports.isTFlowNode = isTFlowNode;
