"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(material_1.Paper)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    maxWidth: 800,
    height: "70%",
    maxHeight: 600,
});
