"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var x_tree_view_1 = require("@mui/x-tree-view");
var TreeItem = (0, material_1.styled)(x_tree_view_1.TreeItem)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& .".concat(x_tree_view_1.treeItemClasses.groupTransition)] = {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: "1px dashed ".concat((0, material_1.alpha)(theme.palette.text.primary, 0.4)),
        },
        _b);
});
exports.default = TreeItem;
