"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_dom_1 = require("react-dom");
var reactflow_1 = require("reactflow");
var selector = function (state) { var _a; return (_a = state.domNode) === null || _a === void 0 ? void 0 : _a.querySelector(".react-flow__renderer"); };
function CustomNodeToolbarPortal(_a) {
    var children = _a.children;
    var wrapperRef = (0, reactflow_1.useStore)(selector);
    if (!wrapperRef) {
        return null;
    }
    return (0, react_dom_1.createPortal)(children, wrapperRef);
}
exports.default = (0, react_1.memo)(CustomNodeToolbarPortal);
