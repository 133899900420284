"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var usePane = function (_a) {
    var _b = _a.editable, editable = _b === void 0 ? true : _b, groupNodeActive = _a.groupNodeActive, handlePostDefaultNode = _a.handlePostDefaultNode, handlePostGroupNode = _a.handlePostGroupNode, handlePostSticky = _a.handlePostSticky, handlePostText = _a.handlePostText, mouseActive = _a.mouseActive, nodeActive = _a.nodeActive, reactFlowContainer = _a.reactFlowContainer, removeAllUpdatingRefference = _a.removeAllUpdatingRefference, rfInstance = _a.rfInstance, signed = _a.signed, stickyActive = _a.stickyActive, textActive = _a.textActive, toggleMouse = _a.toggleMouse;
    var onPaneClick = (0, react_1.useCallback)(function (event) {
        removeAllUpdatingRefference();
        if (reactFlowContainer && rfInstance) {
            var reactFlowBounds = reactFlowContainer.current.getBoundingClientRect();
            var position = rfInstance.screenToFlowPosition({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            if (stickyActive) {
                handlePostSticky(event, false, position.x, position.y);
            }
            if (textActive) {
                handlePostText(event, false, position.x, position.y);
            }
            if (groupNodeActive) {
                handlePostGroupNode(event, false, position.x, position.y);
            }
            if (nodeActive) {
                handlePostDefaultNode(position.x, position.y);
            }
        }
        toggleMouse(false);
    }, [
        removeAllUpdatingRefference,
        handlePostDefaultNode,
        rfInstance,
        stickyActive,
        textActive,
        nodeActive,
        groupNodeActive,
    ]);
    var isInteractive = (0, react_1.useMemo)(function () { return editable && !signed && mouseActive; }, [signed, mouseActive]);
    return { onPaneClick: onPaneClick, interactive: isInteractive };
};
exports.default = usePane;
