"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var workspaceFrameActions_http_1 = require("@pages/Workspaces/reducers/Frame/workspaceFrameActions.http");
var workspaceFrameActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/Frame/workspaceFrameActions.websocket");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var useFrames = function (rf) {
    var dispatch = (0, redux_1.useAppDispatch)();
    var framePanelOpen = (0, redux_1.useAppSelector)(function (state) { return state.workspace.framePanelOpen; });
    var reportPanelOpen = (0, redux_1.useAppSelector)(function (state) { return state.workspace.reportPanelOpen; });
    var pickingElementsFor = (0, redux_1.useAppSelector)(function (state) { return state.workspaceFrame.pickingElementsFor; });
    var nodeElements = (0, redux_1.useAppSelector)(function (state) { return state.workspace.nodeElements; });
    var edgeElements = (0, redux_1.useAppSelector)(function (state) { return state.workspace.edgeElements; });
    var resetPickedElements = function () {
        nodeElements.forEach(function (element) {
            var htmlNode = document.querySelector("[data-id=\"".concat(element.id, "\"]"));
            if (htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.style) {
                htmlNode.style.opacity = "";
            }
        });
        edgeElements.forEach(function (element) {
            var htmlEdge = document.querySelector(".id_".concat(element.id));
            if (htmlEdge === null || htmlEdge === void 0 ? void 0 : htmlEdge.style) {
                htmlEdge.style.opacity = "";
            }
            var htmlEdgeLabel = document.querySelector("[data-id=\"".concat(element.id, "\"]"));
            if (htmlEdgeLabel === null || htmlEdgeLabel === void 0 ? void 0 : htmlEdgeLabel.style) {
                htmlEdgeLabel.style.opacity = "";
            }
        });
    };
    var setPickedElements = function (activeSlide) {
        var activeSlideNodes = activeSlide.nodes.map(function (x) { return "".concat(x); });
        var activeSlideEdges = activeSlide.edges.map(function (x) { return "".concat(x); });
        nodeElements
            .filter(function (node) { return !activeSlideNodes.includes(node.id); })
            .forEach(function (element) {
            var htmlNode = document.querySelector("[data-id=\"".concat(element.id, "\"]"));
            if (htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.style) {
                htmlNode.style.opacity = "0.2";
            }
        });
        edgeElements
            .filter(function (edge) { return !activeSlideEdges.includes(edge.id); })
            .forEach(function (element) {
            var htmlEdge = document.querySelector(".id_".concat(element.id));
            if (htmlEdge === null || htmlEdge === void 0 ? void 0 : htmlEdge.style) {
                htmlEdge.style.opacity = "0.2";
            }
            var htmlEdgeLabel = document.querySelector("[data-id=\"".concat(element.id, "\"]"));
            if (htmlEdgeLabel === null || htmlEdgeLabel === void 0 ? void 0 : htmlEdgeLabel.style) {
                htmlEdgeLabel.style.opacity = "0.2";
            }
        });
    };
    var _a = (0, react_1.useState)(0), current = _a[0], setCurrent = _a[1];
    (0, react_1.useEffect)(function () {
        if (framePanelOpen) {
            if (pickingElementsFor) {
                resetPickedElements();
                setPickedElements(pickingElementsFor);
                if (current !== pickingElementsFor.id) {
                    var nodes = nodeElements.filter(function (node) {
                        return pickingElementsFor.nodes.includes(node.id);
                    });
                    if (nodes.length > 0) {
                        var bounds = (0, reactflow_1.getNodesBounds)(nodes);
                        bounds.x -= 75;
                        bounds && (rf === null || rf === void 0 ? void 0 : rf.fitBounds(bounds, { duration: 500, padding: 0.5 }));
                    }
                    setCurrent(pickingElementsFor.id);
                }
            }
            else {
                setCurrent(0);
                resetPickedElements();
                rf === null || rf === void 0 ? void 0 : rf.fitView({ duration: 500, padding: 1 });
            }
        }
    }, [pickingElementsFor]);
    (0, react_1.useEffect)(function () {
        if (reportPanelOpen) {
            dispatch((0, workspaceFrameActions_http_1.setPickElementsFor)());
            setCurrent(0);
            resetPickedElements();
        }
    }, [reportPanelOpen]);
    var handleFrameNodeClick = (0, react_1.useCallback)(function (node) {
        var htmlNode = document.querySelector("[data-id=\"".concat(node.id, "\"]"));
        if (htmlNode.style.opacity === "0.2") {
            htmlNode.style.opacity = "";
            dispatch((0, workspaceFrameActions_websocket_1.postWorkspaceFrameNode)(pickingElementsFor.id, node.id));
        }
        else {
            htmlNode.style.opacity = "0.2";
            dispatch((0, workspaceFrameActions_websocket_1.deleteWorkspaceFrameNode)(pickingElementsFor.id, node.id));
        }
    }, [pickingElementsFor]);
    var handleFrameEdgeClick = (0, react_1.useCallback)(function (edge) {
        var htmlEdge = document.querySelector(".id_".concat(edge.id));
        if (htmlEdge.style.opacity === "0.2") {
            htmlEdge.style.opacity = "";
            dispatch((0, workspaceFrameActions_websocket_1.postWorkspaceFrameEdge)(pickingElementsFor.id, +edge.id));
        }
        else {
            htmlEdge.style.opacity = "0.2";
            dispatch((0, workspaceFrameActions_websocket_1.deleteWorkspaceFrameEdge)(pickingElementsFor.id, +edge.id));
        }
    }, [pickingElementsFor]);
    return { handleFrameNodeClick: handleFrameNodeClick, handleFrameEdgeClick: handleFrameEdgeClick };
};
exports.default = useFrames;
