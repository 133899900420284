"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onInsightProgressFailed = exports.onInsightProgressCompleted = exports.onInsightProgress = exports.onInsightProgressStarted = exports.onUploadIndexationChunk = exports.onUploadIndexationProgress = exports.onUploadIndexationStart = exports.onUploadIndexationFailed = exports.onUploadIndexationComplete = exports.onOCRStart = exports.onOCRFailed = exports.onOCRComplete = exports.onOCRProgress = exports.onOCRChunk = exports.onAnalysisFailed = exports.onAnalysisSuccess = exports.onAnalysisChunk = exports.onAnalysisStart = exports.onFileMetaFailed = exports.onFileMetaSuccess = exports.onFileMetaChunk = exports.onFileMetaStart = exports.onUploadFiles = exports.onChangeItemWidth = void 0;
function onChangeItemWidth() {
    return function (dispatch) { return function (widths) {
        dispatch(__assign({ type: "INSIGHT_CHANGE_ITEM_WIDTH_SUCCESS" }, widths));
    }; };
}
exports.onChangeItemWidth = onChangeItemWidth;
function onUploadFiles() {
    return function (dispatch) {
        return function (_a) {
            var rows = _a.rows;
            dispatch({ type: "INSIGHT_UPLOAD_FILES_SUCCESS", rows: rows });
        };
    };
}
exports.onUploadFiles = onUploadFiles;
function onFileMetaStart() {
    return function (dispatch) {
        return function (_a) {
            var loadingIds = _a.loadingIds;
            dispatch({ type: "INSIGHT_AI_FILE_META_STARTED", loadingIds: loadingIds });
        };
    };
}
exports.onFileMetaStart = onFileMetaStart;
function onFileMetaChunk() {
    return function (dispatch) { return function (payload) {
        dispatch({ type: "INSIGHT_AI_FILE_META_CHUNK", payload: payload });
    }; };
}
exports.onFileMetaChunk = onFileMetaChunk;
function onFileMetaSuccess() {
    return function (dispatch) {
        return function (_a) {
            var loadingIds = _a.loadingIds;
            dispatch({ type: "INSIGHT_AI_FILE_META_COMPLETED", loadingIds: loadingIds });
        };
    };
}
exports.onFileMetaSuccess = onFileMetaSuccess;
function onFileMetaFailed() {
    return function (dispatch) {
        return function (_a) {
            var loadingIds = _a.loadingIds;
            dispatch({ type: "INSIGHT_AI_FILE_META_FAILED", loadingIds: loadingIds });
        };
    };
}
exports.onFileMetaFailed = onFileMetaFailed;
function onAnalysisStart() {
    return function (dispatch) {
        return function (_a) {
            var loadingIds = _a.loadingIds;
            dispatch({ type: "INSIGHT_AI_ANALYSIS_STARTED", loadingIds: loadingIds });
        };
    };
}
exports.onAnalysisStart = onAnalysisStart;
function onAnalysisChunk() {
    return function (dispatch) { return function (payload) {
        dispatch({ type: "INSIGHT_AI_ANALYSIS_CHUNK", payload: payload });
    }; };
}
exports.onAnalysisChunk = onAnalysisChunk;
function onAnalysisSuccess() {
    return function (dispatch) {
        return function (_a) {
            var loadingIds = _a.loadingIds;
            dispatch({ type: "INSIGHT_AI_ANALYSIS_COMPLETED", loadingIds: loadingIds });
        };
    };
}
exports.onAnalysisSuccess = onAnalysisSuccess;
function onAnalysisFailed() {
    return function (dispatch) {
        return function (_a) {
            var loadingIds = _a.loadingIds;
            dispatch({ type: "INSIGHT_AI_ANALYSIS_FAILED", loadingIds: loadingIds });
        };
    };
}
exports.onAnalysisFailed = onAnalysisFailed;
function onOCRChunk() {
    return function (dispatch) { return function (payload) {
        dispatch({ type: "INSIGHT_DOCUMENTS_OCR_CHUNK", payload: payload });
    }; };
}
exports.onOCRChunk = onOCRChunk;
function onOCRProgress() {
    return function (dispatch) {
        return function (_a) {
            var progress = __rest(_a, []);
            dispatch({ type: "INSIGHT_DOCUMENTS_OCR_PROGRESS", progress: progress });
        };
    };
}
exports.onOCRProgress = onOCRProgress;
function onOCRComplete() {
    return function (dispatch) {
        return function (_a) {
            var readableDocuments = _a.readableDocuments, progress = __rest(_a, ["readableDocuments"]);
            dispatch({
                type: "INSIGHT_DOCUMENTS_OCR_COMPLETED",
                readableDocuments: readableDocuments,
                progress: progress,
            });
            setTimeout(function () {
                dispatch({ type: "INSIGHT_REMOVE_PROGRESS", progress: progress });
            }, 5000);
        };
    };
}
exports.onOCRComplete = onOCRComplete;
function onOCRFailed() {
    return function (dispatch) {
        return function (_a) {
            var progress = __rest(_a, []);
            dispatch({ type: "INSIGHT_DOCUMENTS_OCR_FAILED", progress: progress });
            setTimeout(function () {
                dispatch({ type: "INSIGHT_REMOVE_PROGRESS", progress: progress });
            }, 5000);
        };
    };
}
exports.onOCRFailed = onOCRFailed;
function onOCRStart() {
    return function (dispatch) {
        return function (_a) {
            var progress = __rest(_a, []);
            dispatch({ type: "INSIGHT_DOCUMENTS_OCR_STARTED", progress: progress });
        };
    };
}
exports.onOCRStart = onOCRStart;
function onUploadIndexationComplete() {
    return function (dispatch) {
        return function (_a) {
            var unreadableRowIds = _a.unreadableRowIds, progress = __rest(_a, ["unreadableRowIds"]);
            dispatch({
                type: "INSIGHT_UPLOAD_INDEXATION_COMPLETED",
                unreadableRowIds: unreadableRowIds,
                progress: progress,
            });
            setTimeout(function () {
                dispatch({ type: "INSIGHT_REMOVE_PROGRESS", progress: progress });
            }, 5000);
        };
    };
}
exports.onUploadIndexationComplete = onUploadIndexationComplete;
function onUploadIndexationFailed() {
    return function (dispatch) {
        return function (_a) {
            var progress = __rest(_a, []);
            dispatch({ type: "INSIGHT_UPLOAD_INDEXATION_FAILED", progress: progress });
        };
    };
}
exports.onUploadIndexationFailed = onUploadIndexationFailed;
function onUploadIndexationStart() {
    return function (dispatch) {
        return function (_a) {
            var progress = __rest(_a, []);
            dispatch({ type: "INSIGHT_UPLOAD_INDEXATION_STARTED", progress: progress });
        };
    };
}
exports.onUploadIndexationStart = onUploadIndexationStart;
function onUploadIndexationProgress() {
    return function (dispatch) {
        return function (_a) {
            var progress = __rest(_a, []);
            dispatch({ type: "INSIGHT_UPLOAD_INDEXATION_PROGRESS", progress: progress });
        };
    };
}
exports.onUploadIndexationProgress = onUploadIndexationProgress;
function onUploadIndexationChunk() {
    return function (dispatch) {
        return function (_a) {
            var unreadableRowIds = _a.unreadableRowIds;
            dispatch({ type: "INSIGHT_UPLOAD_INDEXATION_CHUNK", unreadableRowIds: unreadableRowIds });
        };
    };
}
exports.onUploadIndexationChunk = onUploadIndexationChunk;
function onInsightProgressStarted() {
    return function (dispatch) { return function (progress) {
        dispatch({ type: "INSIGHT_PROGRESS_STARTED", progress: progress });
    }; };
}
exports.onInsightProgressStarted = onInsightProgressStarted;
function onInsightProgress() {
    return function (dispatch) { return function (progress) {
        dispatch({ type: "INSIGHT_PROGRESS", progress: progress });
    }; };
}
exports.onInsightProgress = onInsightProgress;
function onInsightProgressCompleted() {
    return function (dispatch) { return function (progress) {
        dispatch({ type: "INSIGHT_PROGRESS_COMPLETED", progress: progress });
    }; };
}
exports.onInsightProgressCompleted = onInsightProgressCompleted;
function onInsightProgressFailed() {
    return function (dispatch) { return function (progress) {
        dispatch({ type: "INSIGHT_PROGRESS_FAILED", progress: progress });
    }; };
}
exports.onInsightProgressFailed = onInsightProgressFailed;
