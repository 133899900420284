"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var redux_1 = require("@hooks/redux");
var HelperLines_1 = require("@components/Flow/HelperLines/HelperLines");
var useChangeElements = function (_a) {
    var dispatch = _a.dispatch, reactFlowInstance = _a.reactFlowInstance, nodeElements = _a.nodeElements, edgeElements = _a.edgeElements;
    var showHelperLines = (0, redux_1.useAppSelector)(function (state) { return state.workspace.showHelperLines; });
    var _b = (0, HelperLines_1.useHelperLines)(), updateHelperLines = _b.updateHelperLines, updateSnapPositions = _b.updateSnapPositions, getHelperLines = _b.getHelperLines;
    var customApplyNodeChanges = (0, react_1.useCallback)(function (changes, nodes) {
        if (!reactFlowInstance)
            return;
        var nodeChanges = [];
        var nodeDragEvent = changes.length >= 1 &&
            !(changes.length === nodes.length) &&
            changes[0].type === "position" &&
            changes[0].dragging &&
            changes[0].position;
        if (nodeDragEvent && showHelperLines) {
            var _a = getHelperLines(changes), horizontal = _a.horizontal, vertical = _a.vertical, updatedChanges = _a.updatedChanges;
            updateHelperLines(horizontal, vertical);
            var shouldSnapNode = horizontal || vertical;
            if (shouldSnapNode) {
                // @ts-ignore
                nodeChanges = (0, reactflow_1.applyNodeChanges)(updatedChanges, nodes);
            }
        }
        if (nodeChanges.length > 0) {
            reactFlowInstance.setNodes(nodeChanges);
        }
    }, [showHelperLines, reactFlowInstance, updateHelperLines, getHelperLines]);
    var onNodesChange = (0, react_1.useCallback)(function (changes) {
        customApplyNodeChanges(changes, nodeElements);
    }, [nodeElements, showHelperLines, customApplyNodeChanges]);
    var onEdgesChange = (0, react_1.useCallback)(function (changes) {
        if (changes.length === 0) {
            return;
        }
        dispatch((0, workspaceActions_http_1.changeEdges)((0, reactflow_1.applyEdgeChanges)(changes, edgeElements)));
    }, [edgeElements]);
    return {
        onNodesChange: onNodesChange,
        onEdgesChange: onEdgesChange,
        updateHelperLines: updateHelperLines,
        updateSnapPositions: updateSnapPositions,
    };
};
exports.default = useChangeElements;
