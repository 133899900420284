"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var CustomConnectionLine_1 = require("@components/Workspace/Edge/CustomConnectionLine");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var useCustomHandle_1 = require("./useCustomHandle");
var useMoveEdge_1 = require("./useMoveEdge");
var redux_1 = require("@hooks/redux");
var vanillaEdgeData_1 = require("@components/Workspace/Edge/vanillaEdgeData");
var converters_1 = require("@helpers/colors/converters");
var DoubleSideHandle_1 = require("@components/Workspace/Node/Shapes/utilities/DoubleSideHandle");
var pathFunctions_1 = require("@components/Workspace/Edge/customPathFunctions/pathFunctions");
function getNodeIntersection(intersectionNode, targetNode) {
    // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
    var _a = intersectionNode.data, intersectionNodeWidth = _a.width, intersectionNodeHeight = _a.height;
    var intersectionNodePosition = intersectionNode.position;
    var targetPosition = targetNode.position;
    var w = intersectionNodeWidth / 2;
    var h = intersectionNodeHeight / 2;
    var x2 = intersectionNodePosition.x + w;
    var y2 = intersectionNodePosition.y + h;
    var x1 = targetPosition.x + targetNode.data.width / 2;
    var y1 = targetPosition.y + targetNode.data.height / 2;
    var xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    var yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    var a = 1 / (Math.abs(xx1) + Math.abs(yy1) || 1);
    var xx3 = a * xx1;
    var yy3 = a * yy1;
    var x = w * (xx3 + yy3) + x2;
    var y = h * (-xx3 + yy3) + y2;
    return { x: x, y: y };
}
// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
    var n = __assign(__assign({}, node.position), node);
    var nx = Math.round(n.x);
    var ny = Math.round(n.y);
    var px = Math.round(intersectionPoint.x);
    var py = Math.round(intersectionPoint.y);
    if (px <= nx + 1) {
        return reactflow_1.Position.Left;
    }
    if (px >= nx + n.data.width - 1) {
        return reactflow_1.Position.Right;
    }
    if (py <= ny + 1) {
        return reactflow_1.Position.Top;
    }
    if (py >= n.y + n.data.height - 1) {
        return reactflow_1.Position.Bottom;
    }
    return reactflow_1.Position.Top;
}
var useEdgeConnect = function (reactFlowContainer, location, id, workspaceTab, mirror, defaultEdge, handleEdgeDeselect, removeAllUpdatingRefference, relationships, defaultEdgeValue, defaultRelationshipType, project, workspaceStepId) {
    var getState = (0, reactflow_1.useStoreApi)().getState;
    var getNode = (0, reactflow_1.useReactFlow)().getNode;
    var dispatch = (0, redux_1.useAppDispatch)();
    var _a = (0, react_1.useState)(null), updatingEdgeHandle = _a[0], setUpdatingEdgeHandle = _a[1];
    var _b = (0, react_1.useState)(null), sourceNode = _b[0], setSourceNode = _b[1];
    var _c = (0, react_1.useState)(null), updatingEdge = _c[0], setUpdatingEdge = _c[1];
    var _d = (0, react_1.useState)({
        x: 0,
        y: 0,
    }), sourceHandleCoordinates = _d[0], setSourceHandleCoordiantes = _d[1];
    var getNodeSourceHandlePosition = (0, react_1.useCallback)(function (node, handleId) {
        var _a, _b;
        var defaultHandle = (_a = {},
            _a[reactflow_1.Position.Bottom] = {
                x: 50,
                y: 100,
                position: reactflow_1.Position.Bottom,
            },
            _a[reactflow_1.Position.Top] = {
                x: 50,
                y: 0,
                position: reactflow_1.Position.Top,
            },
            _a[reactflow_1.Position.Right] = {
                x: 100,
                y: 50,
                position: reactflow_1.Position.Right,
            },
            _a[reactflow_1.Position.Left] = {
                x: 0,
                y: 50,
                position: reactflow_1.Position.Left,
            },
            _a.easyHandle = {
                x: 50,
                y: 100,
                position: reactflow_1.Position.Bottom,
            },
            _a);
        var handle = defaultHandle[handleId] ||
            node.data.handles.find(function (h) { return h.id.toString() === handleId; });
        // we must be sure that x or y are 100 or 0 depending on their position
        var calcPos = (_b = {},
            _b[reactflow_1.Position.Bottom] = {
                x: (handle === null || handle === void 0 ? void 0 : handle.x) || 50,
                y: 100,
            },
            _b[reactflow_1.Position.Top] = {
                x: (handle === null || handle === void 0 ? void 0 : handle.x) || 50,
                y: 0,
            },
            _b[reactflow_1.Position.Right] = {
                x: 100,
                y: (handle === null || handle === void 0 ? void 0 : handle.y) || 50,
            },
            _b[reactflow_1.Position.Left] = {
                x: 0,
                y: (handle === null || handle === void 0 ? void 0 : handle.y) || 50,
            },
            _b.easyHandle = {
                x: (handle === null || handle === void 0 ? void 0 : handle.x) || 50,
                y: 100,
            },
            _b);
        var position = calcPos[handle.position];
        return {
            x: node.position.x + (node.data.width * position.x) / 100,
            y: node.position.y + (node.data.height * position.y) / 100,
        };
    }, []);
    var onEdgeUpdateStart = (0, react_1.useCallback)(function (_event, edge, handle) {
        setUpdatingEdge(edge);
        (0, useMoveEdge_1.setGlobalMovingEdge)(edge);
        setUpdatingEdgeHandle(handle);
        (0, useMoveEdge_1.setGlobalMovingEdgeHandle)(handle);
        dispatch((0, workspaceActions_websocket_1.userEditEdges)(edge.id));
    }, []);
    var onEdgeUpdate = (0, react_1.useCallback)(function (oldEdge, newConnection) {
        var globalMovingEdge = (0, useMoveEdge_1.getGlobalMovingEdge)();
        var type = globalMovingEdge === null || globalMovingEdge === void 0 ? void 0 : globalMovingEdge.type;
        if (!type)
            return;
        setUpdatingEdge(null);
        (0, useMoveEdge_1.setGlobalMovingEdge)(null);
        var oldConnection = {
            source: oldEdge.source,
            target: oldEdge.target,
            sourceHandle: oldEdge.sourceHandle,
            targetHandle: oldEdge.targetHandle,
        };
        dispatch((0, workspaceActions_websocket_1.updateEdgePosition)(oldEdge.id, newConnection, oldConnection, type));
    }, [updatingEdge]);
    var onConnectStart = (0, react_1.useCallback)(function (_event, sNode) {
        setSourceNode(sNode);
        handleEdgeDeselect(true);
        removeAllUpdatingRefference(true);
        var node = getNode(sNode.nodeId || "");
        if (node && sNode.handleId) {
            setSourceHandleCoordiantes(getNodeSourceHandlePosition(node, sNode.handleId));
        }
    }, [removeAllUpdatingRefference, handleEdgeDeselect, getNodeSourceHandlePosition]);
    var onConnect = (0, react_1.useCallback)(function (data, customHandle, ghostNode) {
        setSourceNode(null);
        var sourceHandleId = data.sourceHandle, targetHandleId = data.targetHandle;
        var source = data.source, target = data.target;
        if (!source || !sourceHandleId || !targetHandleId)
            return;
        var sNode = getNode(source);
        var tNode = getNode(target || "");
        if (sourceHandleId === "easyHandle") {
            if (!sNode || !tNode)
                return;
            var sourceIntersectionPoint = getNodeIntersection(sNode, tNode);
            var sourcePos = getEdgePosition(sNode, sourceIntersectionPoint);
            sourceHandleId = sourcePos;
        }
        if (targetHandleId === "easyHandle") {
            if (!sNode || !tNode)
                return;
            var targetIntersectionPoint = getNodeIntersection(tNode, sNode);
            var targetPos = getEdgePosition(tNode, targetIntersectionPoint);
            targetHandleId = targetPos;
        }
        var defaultRelationship = relationships.find(function (r) { return r.id === (defaultEdge === null || defaultEdge === void 0 ? void 0 : defaultEdge.value); });
        var edge = {
            relationship_id: defaultEdge
                ? "".concat(defaultEdge.value)
                : vanillaEdgeData_1.vanillaEdgeData.data.relationshipId,
            relationshipLabel: defaultEdge ? "".concat(defaultEdge.label) : vanillaEdgeData_1.vanillaEdgeData.label,
            relationshipValue: defaultEdgeValue || vanillaEdgeData_1.vanillaEdgeData.data.value,
            relationshipColor: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.color) || vanillaEdgeData_1.vanillaEdgeData.data.color,
            relationshipLabelBackgroundColor: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.labelBackgroundColor) ||
                (0, converters_1.rgbaStringToRgbaObject)(vanillaEdgeData_1.vanillaEdgeData.labelBgStyle.background),
            relationshipType: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.type) || defaultRelationshipType,
            notes: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.notes) || vanillaEdgeData_1.vanillaEdgeData.data.notes,
            documents: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.documents) || vanillaEdgeData_1.vanillaEdgeData.data.documents,
            animatedLine: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.animated) || vanillaEdgeData_1.vanillaEdgeData.data.animated,
            hideValue: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.hideValue) || vanillaEdgeData_1.vanillaEdgeData.data.hideValue,
            hideLabel: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.hideLabel) || vanillaEdgeData_1.vanillaEdgeData.data.hideLabel,
            lineThrough: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.lineThrough) || vanillaEdgeData_1.vanillaEdgeData.data.lineThrough,
            source: source,
            target: target,
            sourceHandle: sourceHandleId,
            targetHandle: targetHandleId,
            curveScalar: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.curveScalar) || vanillaEdgeData_1.vanillaEdgeData.data.curveScalar,
            offsetBearing: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.offsetBearing) || vanillaEdgeData_1.vanillaEdgeData.data.offsetBearing,
            customHandle: customHandle,
            pathKnots: vanillaEdgeData_1.vanillaEdgeData.data.pathKnots,
            fontSize: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.fontSize) || undefined,
            fontFamily: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.fontFamily) || undefined,
            fontColor: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.fontColor) || undefined,
            isBold: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.isBold) || false,
            isItalic: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.isItalic) || false,
            isUnderline: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.isUnderline) || false,
            targetMarker: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.targetMarker) || vanillaEdgeData_1.vanillaEdgeData.data.markers.target,
            sourceMarker: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.sourceMarker) || vanillaEdgeData_1.vanillaEdgeData.data.markers.source,
            ghostNode: ghostNode,
        };
        dispatch((0, workspaceActions_websocket_1.postEdge)(id, workspaceTab.value, workspaceStepId || null, edge, true, location, mirror()));
    }, [
        relationships,
        defaultEdge,
        defaultEdgeValue,
        workspaceTab.value,
        workspaceStepId,
        id,
        mirror,
        getNode,
    ]);
    var onConnectEnd = (0, react_1.useCallback)(function (event) {
        try {
            dispatch(workspaceActions_http_1.handleConnectEnd);
            if (!reactFlowContainer.current || !sourceNode) {
                return;
            }
            var sourceNodeId = sourceNode.nodeId, sourceHandle = sourceNode.handleId;
            if (!sourceNodeId || !sourceHandle) {
                return;
            }
            var reactFlowBounds = reactFlowContainer.current.getBoundingClientRect();
            var x = ("clientX" in event ? event.clientX : 0) - reactFlowBounds.left;
            var y = ("clientY" in event ? event.clientY : 0) - reactFlowBounds.top;
            var mouseCoordinates = project({
                x: x,
                y: y,
            });
            var _a = getState(), getNodes = _a.getNodes, connectionEndHandle = _a.connectionEndHandle;
            var internalNodes = getNodes();
            var closestNodeObj = (0, CustomConnectionLine_1.getClosestNode)(sourceNodeId, mouseCoordinates, internalNodes);
            if (closestNodeObj &&
                closestNodeObj.node !== null &&
                closestNodeObj.node.type === "custom" &&
                closestNodeObj.closestPoint !== null) {
                var customHandle = (0, useCustomHandle_1.generateHandle)(closestNodeObj);
                var position = customHandle.position;
                if (updatingEdgeHandle && updatingEdge && !connectionEndHandle) {
                    var oldConnection = {
                        source: updatingEdge.source,
                        target: updatingEdge.target,
                        sourceHandle: updatingEdge.sourceHandle,
                        targetHandle: updatingEdge.targetHandle,
                    };
                    var connection = {
                        source: updatingEdgeHandle === "source"
                            ? updatingEdge.source
                            : closestNodeObj.node.id,
                        target: updatingEdgeHandle === "target"
                            ? updatingEdge.target
                            : closestNodeObj.node.id,
                        sourceHandle: updatingEdgeHandle === "source"
                            ? updatingEdge.sourceHandle
                            : useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID,
                        targetHandle: updatingEdgeHandle === "target"
                            ? updatingEdge.targetHandle
                            : useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID,
                    };
                    var globalMovingEdge = (0, useMoveEdge_1.getGlobalMovingEdge)();
                    var type = globalMovingEdge === null || globalMovingEdge === void 0 ? void 0 : globalMovingEdge.type;
                    if (!type)
                        return;
                    dispatch((0, workspaceActions_websocket_1.updateEdgePosition)(updatingEdge.id, connection, oldConnection, type, customHandle));
                }
                else if (!updatingEdgeHandle && !connectionEndHandle) {
                    onConnect({
                        source: sourceNodeId,
                        target: closestNodeObj.node.id,
                        sourceHandle: sourceHandle,
                        targetHandle: position,
                    }, customHandle);
                }
            }
            else if (!connectionEndHandle) {
                var _b = (0, pathFunctions_1.angleToHandlePosition)(), determinePosition = _b.determinePosition, calculateCoordinates = _b.calculateCoordinates, calculateAngleFromHandle = _b.calculateAngleFromHandle, isTargetWithinClickThreshholdOfSource = _b.isTargetWithinClickThreshholdOfSource;
                if (isTargetWithinClickThreshholdOfSource(sourceHandleCoordinates, mouseCoordinates)) {
                    return;
                }
                var angle = calculateAngleFromHandle(sourceHandleCoordinates, mouseCoordinates);
                if (Number.isNaN(angle))
                    return;
                var node = getNode(sourceNode.nodeId || "");
                if (!node)
                    return;
                if (node.type === "ghost")
                    return;
                var position = determinePosition(sourceHandleCoordinates, mouseCoordinates);
                var targetHandle = calculateCoordinates(angle, position);
                var customHandle = __assign(__assign({}, targetHandle), { position: position });
                if (!updatingEdgeHandle && !connectionEndHandle) {
                    onConnect({
                        source: sourceNodeId,
                        target: null,
                        sourceHandle: sourceHandle,
                        targetHandle: position,
                    }, customHandle, mouseCoordinates);
                }
                else if (updatingEdge) {
                    var connection = {
                        source: updatingEdgeHandle === "source" ? updatingEdge.source : null,
                        target: updatingEdgeHandle === "target" ? updatingEdge.target : null,
                        sourceHandle: updatingEdgeHandle === "source"
                            ? updatingEdge.sourceHandle
                            : useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID,
                        targetHandle: updatingEdgeHandle === "target"
                            ? updatingEdge.targetHandle
                            : useCustomHandle_1.CUSTOM_DUMMY_HANDLE_ID,
                    };
                    var globalMovingEdge = (0, useMoveEdge_1.getGlobalMovingEdge)();
                    var type = globalMovingEdge === null || globalMovingEdge === void 0 ? void 0 : globalMovingEdge.type;
                    if (!type)
                        return;
                    var oldConnection = {
                        source: updatingEdge.source,
                        target: updatingEdge.target,
                        sourceHandle: updatingEdge.sourceHandle,
                        targetHandle: updatingEdge.targetHandle,
                    };
                    dispatch((0, workspaceActions_websocket_1.updateEdgePositionToGhost)(updatingEdge.id, connection, oldConnection, type, customHandle, mouseCoordinates));
                }
            }
        }
        finally {
            setUpdatingEdge(null);
            (0, useMoveEdge_1.setGlobalMovingEdge)(null);
            setUpdatingEdgeHandle(null);
            (0, useMoveEdge_1.setGlobalMovingEdgeHandle)(null);
            setSourceNode(null);
        }
    }, [
        project,
        reactFlowContainer,
        sourceNode,
        onConnect,
        updatingEdge,
        updatingEdgeHandle,
        sourceHandleCoordinates,
    ]);
    var isValidConnection = function (connection) {
        var source = connection.source, target = connection.target, sourceHandleId = connection.sourceHandle, targetHandleId = connection.targetHandle;
        if (!source || !target || !sourceHandleId || !targetHandleId) {
            return false;
        }
        var sNode = getNode(source);
        var tNode = getNode(target);
        if (!sNode || !tNode) {
            return false;
        }
        if (sNode.id === tNode.id) {
            var isSourceHandleDefault = (0, DoubleSideHandle_1.isDefaultHandle)(sourceHandleId);
            var isTargetHandleDefault = (0, DoubleSideHandle_1.isDefaultHandle)(targetHandleId);
            if (isSourceHandleDefault !== isTargetHandleDefault) {
                var defaultHandleId = (isSourceHandleDefault ? sourceHandleId : targetHandleId);
                var customHandleId_1 = isSourceHandleDefault ? targetHandleId : sourceHandleId;
                var customHandle = (isSourceHandleDefault ? tNode : sNode).data.handles.find(function (h) { return h.id.toString() === customHandleId_1; });
                return !(0, DoubleSideHandle_1.isSamePositionAsDefaultHandle)(customHandle, defaultHandleId);
            }
        }
        return true;
    };
    return {
        onConnectStart: onConnectStart,
        onConnect: onConnect,
        onConnectEnd: onConnectEnd,
        onEdgeUpdateStart: onEdgeUpdateStart,
        onEdgeUpdate: onEdgeUpdate,
        isValidConnection: isValidConnection,
    };
};
exports.default = useEdgeConnect;
