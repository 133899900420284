"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function useCustomGetPointerPosition() {
    var store = (0, reactflow_1.useStoreApi)();
    // returns the pointer position projected to the RF coordinate system
    var getPointerPosition = (0, react_1.useCallback)(function (_a) {
        var x = _a.x, y = _a.y;
        var _b = store.getState(), transform = _b.transform, snapGrid = _b.snapGrid, shouldSnapToGrid = _b.snapToGrid;
        var pointerPos = {
            x: (x - transform[0]) / transform[2],
            y: (y - transform[1]) / transform[2],
        };
        // we need the snapped position in order to be able to skip unnecessary drag events
        return __assign({ xSnapped: shouldSnapToGrid
                ? snapGrid[0] * Math.round(pointerPos.x / snapGrid[0])
                : pointerPos.x, ySnapped: shouldSnapToGrid
                ? snapGrid[1] * Math.round(pointerPos.y / snapGrid[1])
                : pointerPos.y }, pointerPos);
    }, []);
    return getPointerPosition;
}
exports.default = useCustomGetPointerPosition;
