"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var ChronoNode = function (_a) {
    var id = _a.id, data = _a.data;
    var isFirstDayOfMonth = data.date.getDate() === 1;
    var isFirstDayOfYear = isFirstDayOfMonth && data.date.getMonth() === 0;
    return (react_1.default.createElement(material_1.Box, { id: id, position: "relative" },
        react_1.default.createElement(material_1.Box, { sx: [{
                    width: "1px",
                    backgroundColor: "black"
                }, isFirstDayOfYear ? {
                    mt: 0
                } : {
                    mt: isFirstDayOfMonth ? "2.5px" : "5px"
                }, isFirstDayOfYear ? {
                    height: 20
                } : {
                    height: isFirstDayOfMonth ? 15 : 10
                }] }),
        isFirstDayOfMonth && (react_1.default.createElement(material_1.Box, { sx: { position: "absolute", top: -25, left: 0 } },
            react_1.default.createElement(material_1.Typography, { variant: "caption", textTransform: "lowercase", whiteSpace: "nowrap" }, data.date.toLocaleString("default", { month: "short" })))),
        isFirstDayOfYear && (react_1.default.createElement(material_1.Box, { sx: { position: "absolute", top: 30, left: 0 } },
            react_1.default.createElement(material_1.Typography, { variant: "caption", textTransform: "lowercase", whiteSpace: "nowrap" }, data.date.getFullYear())))));
};
exports.default = (0, react_1.memo)(ChronoNode);
