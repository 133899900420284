"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var ToolsContainer = (0, material_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        background: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minWidth: 200,
    });
});
exports.default = ToolsContainer;
