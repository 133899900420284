"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Zoom = exports.Figur = exports.EdgeVariants = exports.Handles = exports.ContextTypes = void 0;
var ContextTypes;
(function (ContextTypes) {
    ContextTypes["Node"] = "node";
    ContextTypes["Pane"] = "pane";
    ContextTypes["Edge"] = "edge";
    ContextTypes["Selection"] = "selection";
})(ContextTypes = exports.ContextTypes || (exports.ContextTypes = {}));
var Handles;
(function (Handles) {
    Handles["TOP"] = "top";
    Handles["LEFT_TOP"] = "leftTop";
    Handles["LEFT_BOTTOM"] = "leftBottom";
    Handles["RIGHT_BOTTOM"] = "rightBottom";
    Handles["RIGHT_TOP"] = "rightTop";
    Handles["BOTTOM"] = "bottom";
})(Handles = exports.Handles || (exports.Handles = {}));
exports.EdgeVariants = {
    CUSTOM: "custom",
    SMOOTHSTEP: "smoothstep",
    STRAIGHT: "straight",
    DEFAULT: "default",
};
var Figur;
(function (Figur) {
    Figur["TRIANGLE"] = "triangle";
    Figur["RECTANGLE"] = "rectangle";
    Figur["ROUNDRECTANGLE"] = "roundRectangle";
    Figur["CIRCLE"] = "circle";
    Figur["PERSON"] = "person";
    Figur["DIAMOND"] = "diamond";
    Figur["STAR"] = "star";
    Figur["ARROW"] = "arrow";
    Figur["PARALLELOGRAM"] = "parallelogram";
    Figur["DOCUMENT"] = "document";
    Figur["PENTAGON"] = "pentagon";
    Figur["SQC_HYBRID"] = "sqcHybrid";
    Figur["TRAPEZOID"] = "trapezoid";
    Figur["HYBRID_ARROW_UP"] = "hybridArrowUp";
    Figur["HYBRID_ARROW_DOWN"] = "hybridArrowDown";
    Figur["STACKED_RECTANGLES"] = "stackedRectangles";
})(Figur = exports.Figur || (exports.Figur = {}));
var Zoom;
(function (Zoom) {
    Zoom["IN"] = "in";
    Zoom["OUT"] = "out";
})(Zoom = exports.Zoom || (exports.Zoom = {}));
