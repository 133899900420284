"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var usePowerPointFitter_1 = require("@hooks/flow/usePowerPointFitter");
var redux_1 = require("@hooks/redux");
var Legend_1 = require("@components/Workspace/Legend");
var material_1 = require("@mui/material");
var FONT_SIZE_IN_PT = 4;
var LEGEND_HEIGTH_IN_PX = 15;
var LEGEND_TEXT_WIDTH_IN_PX = 45;
var LEGEND_FIGURE_MAX_WIDTH_IN_PX = 25;
var LEGEND_GRID_GAP_IN_PX = 5;
var OVERFLOW_LEGEND_ROWS = 4;
var PREFFERED_LEGEND_ROWS = 3;
var PREFFERED_LEGEND_COLUMNS = 3;
var NUM_OF_NODES_BEFORE_OVERFLOW = PREFFERED_LEGEND_ROWS * PREFFERED_LEGEND_COLUMNS;
var EDGE_SCALE = 0.5;
var FLAG_SCALE = 0.75;
var LEGEND_TEXT_TO_FIGURE_GAP_IN_PX = 4;
var Legends = function (_a) {
    var customer = _a.customer;
    var workspaceLegend = (0, redux_1.useAppSelector)(function (state) { return state.workspace.legend; });
    var customerSlideProperties = (0, react_1.useMemo)(function () { return (0, usePowerPointFitter_1.getCustomerSlidePropertiesInPx)(customer); }, [customer]);
    var legendPositioning = customerSlideProperties.legendPositioning;
    var marginX = legendPositioning.marginX, marginY = legendPositioning.marginY, float = legendPositioning.float;
    var isOverflow = workspaceLegend.length > NUM_OF_NODES_BEFORE_OVERFLOW;
    var legends = workspaceLegend.reduce(function (rows, legend, i) {
        var rowIndex = i % rows.length;
        var isEdge = !!legend.edgeShape;
        var isFlag = !!legend.flagId;
        var scale = isEdge ? EDGE_SCALE : isFlag ? FLAG_SCALE : 1;
        var workspaceIcon = (react_1.default.createElement(Legend_1.WorkspaceIcon, { entry: legend, height: LEGEND_HEIGTH_IN_PX, width: LEGEND_FIGURE_MAX_WIDTH_IN_PX, scale: scale }));
        rows[rowIndex].push(react_1.default.createElement(material_1.Box, { key: "pptx-legend-".concat(legend.workspaceId, "-").concat(legend.nodeId), sx: {
                display: "flex",
                gap: "".concat(LEGEND_TEXT_TO_FIGURE_GAP_IN_PX, "px"),
                padding: 0,
                justifyContent: "flex-start",
                alignItems: "center",
            } },
            react_1.default.createElement(material_1.Stack, { width: LEGEND_FIGURE_MAX_WIDTH_IN_PX, height: LEGEND_HEIGTH_IN_PX, justifyContent: "center", alignItems: "center" }, workspaceIcon),
            react_1.default.createElement("span", { style: {
                    fontSize: "".concat(FONT_SIZE_IN_PT, "pt"),
                    minWidth: "".concat(LEGEND_TEXT_WIDTH_IN_PX, "px"),
                    maxWidth: "".concat(LEGEND_TEXT_WIDTH_IN_PX, "px"),
                } }, legend.name)));
        return rows;
    }, Array.from({ length: isOverflow ? OVERFLOW_LEGEND_ROWS : PREFFERED_LEGEND_ROWS }, function () { return []; }));
    return (react_1.default.createElement(material_1.Stack, { sx: [
            {
                position: "absolute",
                bottom: marginY,
                gap: "".concat(LEGEND_GRID_GAP_IN_PX, "px"),
            },
            float === "left" ? { left: marginX } : { right: marginX },
        ] }, legends.reverse().map(function (legendRow, i) { return (react_1.default.createElement(material_1.Stack
    // eslint-disable-next-line react/no-array-index-key
    , { 
        // eslint-disable-next-line react/no-array-index-key
        key: "pptx-legend-row-".concat(i), direction: "row", gap: "".concat(LEGEND_GRID_GAP_IN_PX, "px"), sx: [
            float === "left"
                ? {
                    justifyContent: "flex-start",
                }
                : {
                    justifyContent: "flex-end",
                },
        ] }, float === "left" ? legendRow : legendRow.reverse())); })));
};
exports.default = (0, react_1.memo)(Legends);
