"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_dom_1 = require("react-dom");
var reactflow_1 = require("reactflow");
var selector = function (s) { var _a; return (_a = s.domNode) === null || _a === void 0 ? void 0 : _a.querySelector(".react-flow__container"); };
/**
 * The `<ViewportPortal />` component can be used to add components to the same viewport
 * of the flow where nodes and edges are rendered. This is useful when you want to render
 * your own components that are adhere to the same coordinate system as the nodes & edges
 * and are also affected by zooming and panning
 * @public
 * @example
 *
 * ```jsx
 *import React from 'react';
 *import { ViewportPortal } from '@xyflow/react';
 *
 *export default function () {
 *  return (
 *    <ViewportPortal>
 *      <div
 *        style={{ transform: 'translate(100px, 100px)', position: 'absolute' }}
 *      >
 *        This div is positioned at [100, 100] on the flow.
 *      </div>
 *    </ViewportPortal>
 *  );
 *}
 *```
 */
function ViewportPortal(_a) {
    var children = _a.children;
    var viewPortalDiv = (0, reactflow_1.useStore)(selector);
    if (!viewPortalDiv) {
        return null;
    }
    return (0, react_dom_1.createPortal)(children, viewPortalDiv);
}
exports.default = ViewportPortal;
