"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var redux_1 = require("@hooks/redux");
var constants_2 = require("@pages/Timelines/constants");
var timelineActions_http_1 = require("@pages/Timelines/reducers/timelineActions.http");
var react_1 = require("react");
var useTimelineNode = function (user, data, location, t, collapseSameDates, showThumbnails) {
    var dispatch = (0, redux_1.useAppDispatch)();
    var timePaperRef = (0, react_1.useRef)(null);
    var _a = (0, react_1.useState)(false), showEdit = _a[0], setShowEdit = _a[1];
    var _b = (0, react_1.useState)(false), showAddBox = _b[0], setShowAddBox = _b[1];
    var handleShowEdit = (0, react_1.useCallback)(function () {
        setShowEdit(true);
    }, []);
    var handleHideEdit = (0, react_1.useCallback)(function () {
        setShowEdit(false);
    }, []);
    var handleShowAddBox = (0, react_1.useCallback)(function () {
        setShowAddBox(true);
    }, []);
    var handleHideAddBox = (0, react_1.useCallback)(function () {
        setShowAddBox(false);
    }, []);
    var frontendType = (0, constants_1.getGroupOrLawsuitQueryParams)(location).frontendType;
    var isGroup = frontendType === "groups";
    var nodesToMove = (0, redux_1.useAppSelector)(function (state) { return state.timeline.nodesToMove; });
    var nodes = (0, redux_1.useAppSelector)(function (state) { return state.timeline.nodes; });
    var isMoveState = nodesToMove.size > 0;
    var isNodeMoving = nodesToMove.has(data.id);
    var parentId = data.root ? data.root.toString() : "";
    var isParentMoving = nodesToMove.has(parentId);
    var timelineId = (0, constants_1.getId)(location);
    var toggleMoveNode = (0, react_1.useCallback)(function () {
        if (isNodeMoving) {
            dispatch((0, timelineActions_http_1.removeNodesFromMove)(data.id, nodes));
        }
        else {
            dispatch((0, timelineActions_http_1.addNodesToMove)(data.id, nodes));
        }
    }, [isNodeMoving, data.id, dispatch, nodes]);
    var handleNodeMoveClick = (0, react_1.useCallback)(function () {
        var message = t("timeline.move_relationship_message");
        dispatch((0, timelineActions_http_1.engageMoveNodes)(user, data.id, nodes, timelineId, collapseSameDates, message, constants_2.GetSelectedNodesAction.ADD));
    }, [user, data.id, timelineId, collapseSameDates, showThumbnails, nodes]);
    return {
        timePaperRef: timePaperRef,
        showEdit: showEdit,
        showAddBox: showAddBox,
        handleShowEdit: handleShowEdit,
        handleHideEdit: handleHideEdit,
        handleShowAddBox: handleShowAddBox,
        handleHideAddBox: handleHideAddBox,
        isGroup: isGroup,
        nodesToMove: nodesToMove,
        isMoveState: isMoveState,
        isNodeMoving: isNodeMoving,
        isParentMoving: isParentMoving,
        toggleMoveNode: toggleMoveNode,
        handleNodeMoveClick: handleNodeMoveClick,
    };
};
exports.default = useTimelineNode;
