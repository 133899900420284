"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_GROUP_CSS_VARIABLE = void 0;
var material_1 = require("@mui/material");
var x_data_grid_premium_1 = require("@mui/x-data-grid-premium");
exports.TASK_GROUP_CSS_VARIABLE = "--taskGroupColor";
var TaskGroupDataGrid = (0, material_1.styled)(x_data_grid_premium_1.DataGridPremium)(function (_a) {
    var _b, _c, _d;
    var isEditable = _a.isEditable;
    return (_b = {
            border: "none",
            "& .MuiDataGrid-columnHeaderReorder": {
                borderLeft: "6px solid var(".concat(exports.TASK_GROUP_CSS_VARIABLE, ")"),
            }
        },
        _b["& .".concat(x_data_grid_premium_1.gridClasses.rowReorderCell)] = {
            borderLeft: "6px solid var(".concat(exports.TASK_GROUP_CSS_VARIABLE, ")"),
            pointerEvents: isEditable ? "auto" : "none",
        },
        _b["& .".concat(x_data_grid_premium_1.gridClasses.pinnedRows)] = (_c = {},
            _c["& .".concat(x_data_grid_premium_1.gridClasses.rowReorderCellContainer)] = {
                borderLeft: "6px solid var(".concat(exports.TASK_GROUP_CSS_VARIABLE, ")"),
            },
            _c),
        _b["& [data-id='ADD_ITEM']"] = (_d = {},
            _d["& .".concat(x_data_grid_premium_1.gridClasses.rowReorderCellContainer)] = {
                borderLeft: "6px solid var(".concat(exports.TASK_GROUP_CSS_VARIABLE, ")"),
            },
            _d),
        _b);
});
exports.default = TaskGroupDataGrid;
