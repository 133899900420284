"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.closeConnecton = exports.initiateConnection = void 0;
var constants_1 = require("@api/constants");
var socket_1 = require("@api/socket");
var userInfo_1 = require("@helpers/userInfo");
// let rooms: DocumentRoom;
var socket;
// let emit: ReturnType<typeof Emit>;
var DOCUMENT = "document";
// export function checkSocket<T extends unknown[], R>(
//   fn: FunctionWithArgs<T, R>,
// ): FunctionWithArgs<Parameters<typeof fn>, R | (() => Promise<void>)> {
//   function cb(this: SocketFunction, ...args: Parameters<typeof fn>) {
//     if (!socket || !rooms) {
//       // console.error("Socket not initialized!");
//       return async () => {};
//     }
//     if (socket.disconnected || socket.id === undefined) {
//       // console.error("Socket disconnected");
//       return async () => {};
//     }
//     this.socket = socket;
//     this.emit = emit;
//     this.rooms = rooms;
//     return fn.apply(this, args);
//   }
//   return cb.bind({ socket, emit, rooms });
// }
var initiateConnection = function (user, documentId) { return function () {
    var token = (0, userInfo_1.getToken)(user);
    var io = (0, socket_1.createClient)(socket);
    socket = io("".concat((0, constants_1.baseUrl)(), "/").concat(DOCUMENT), {
        auth: {
            token: token,
            documentId: documentId,
        },
    });
    // emit = Emit(socket);
    // rooms = {
    //   document: documentId,
    // };
    return socket;
}; };
exports.initiateConnection = initiateConnection;
function closeConnecton() {
    socket === null || socket === void 0 ? void 0 : socket.disconnect();
    socket = null;
    return null;
}
exports.closeConnecton = closeConnecton;
