"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchWorkspaceConfig = void 0;
var react_1 = require("react");
var auth0_react_1 = require("@auth0/auth0-react");
var userConfigActions_1 = require("@redux/actions/userConfigActions");
var redux_1 = require("./redux");
function useFetchWorkspaceConfig() {
    var user = (0, auth0_react_1.useAuth0)().user;
    var dispatch = (0, redux_1.useAppDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, userConfigActions_1.getWorkspaceConfig)(user));
    }, []);
}
exports.useFetchWorkspaceConfig = useFetchWorkspaceConfig;
var useWorkspaceLayoutParams = function () {
    var toolsLayout = (0, redux_1.useAppSelector)(function (state) { return state.userConfig.workspace.layout; });
    var user = (0, auth0_react_1.useAuth0)().user;
    var dispatch = (0, redux_1.useAppDispatch)();
    var handleLayoutChange = (0, react_1.useCallback)(function (layout) {
        dispatch((0, userConfigActions_1.updateWorkspaceLayout)(user, layout));
    }, []);
    return {
        handleLayoutChange: handleLayoutChange,
        toolsLayout: toolsLayout,
    };
};
exports.default = useWorkspaceLayoutParams;
