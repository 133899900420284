"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var tss_react_1 = require("tss-react");
var mui_1 = require("tss-react/mui");
var fontSizeImportant = {
    fontSize: "8px !important",
    textAlign: "left !important",
};
var transitionLoad = (0, tss_react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n0% {\n  opacity: 0;\n}\n100% {\n  opacity: 1;\n}"], ["\n0% {\n  opacity: 0;\n}\n100% {\n  opacity: 1;\n}"])));
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    fadeInAnimation: {
        animation: "".concat(transitionLoad, " ease 300ms"),
        animationIterationCount: 1,
        animationFillMode: "forwards",
    },
    field: {
        width: "100%",
        marginBottom: 10,
        fontSizeImportant: fontSizeImportant,
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
    styleLabelInput: {
        "& input": {
            textAlign: "right",
            width: "35px",
        },
        fontFamily: 'SegoeUI-SemiBold-final, "Segoe UI Semibold", SegoeUI-Regular-final, "Segoe UI", "Segoe UI Web (West European)", Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma, Helvetica, Arial, sans-serif',
        fontWeight: 400,
    },
    nonSvgFill: {
        "& svg": {
            fill: "none !important",
        },
    },
    zoomContainer: {
        "& span": {
            fontSize: "12px",
            color: "white",
        },
        "& .rpv-zoom__popover-target-arrow": {
            "--rpv-zoom__popover-target-arrow-border-color": "white",
        },
    },
    officeBackground: {
        "& .rpv-core__inner-page": {
            backgroundColor: "rgb(243, 243, 243)",
        },
        "& .rpv-core__canvas-layer": {
            "&::before": {
                content: "''",
                position: "absolute",
                bottom: "0.25rem",
                left: "0.25rem",
                right: "0.25rem",
                top: "0.25rem",
                boxShadow: "0px 0px 0px 0.25rem rgb(243,243,243)",
            },
        },
    },
    emailContent: {
        "* > hover": {
            backgroundColor: "pink",
        },
    },
    removeGlobalStyles: {
        "& ul, ol": {
            listStyleType: "revert",
            margin: "revert",
            padding: "revert",
        },
        "& dl": {
            margin: "revert",
        },
        "& dt": {
            fontWeight: "revert",
            margin: "revert",
        },
        "& dd": {
            margin: "revert",
        },
        "& figure": {
            margin: "revert",
        },
        "& img, picture": {
            margin: "revert",
            maxWidth: "revert",
        },
        "& table": {
            borderCollapse: "revert",
            margin: "revert",
            textAlign: "revert",
            width: "revert",
        },
        "& thead": {
            lineHeight: "revert",
            verticalAlign: "revert",
        },
        "& tbody": {
            verticalAlign: "revert",
        },
        "& tr": {
            borderBottom: "revert",
        },
        "& th": {
            fontWeight: "revert",
        },
        "& th, td": {
            padding: "revert",
            paddingLeft: "revert",
        },
        "&  h1,  h2,  h3,  h4,  h5,  h6": {
            fontFamily: "revert",
            fontSize: "revert",
            lineHeight: "revert",
            margin: "revert",
        },
        "& a": {
            color: "revert",
            textDecorationSkipInk: "revert",
            transition: "revert",
            "&:focus": {
                outline: "revert",
                outlineOffset: "revert",
            },
        },
        "& hr": {
            borderBottom: "revert",
            borderLeft: "revert",
            borderRight: "revert",
            borderTop: "revert",
            margin: "revert",
        },
    },
    emailThumbnailContainer: {
        overflow: "hidden",
        width: 200,
        height: 283,
        objectFit: "cover",
        borderRadius: 2,
        boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        WebkitBoxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        MozBoxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        padding: 10,
        pointerEvents: "none",
        gap: 2,
    },
    emailThumbnailContet: {
        display: "flex",
        marginTop: 2,
    },
    emailThumbnailType: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
    },
    emailThumbnailTypography: __assign(__assign({}, fontSizeImportant), { "&&&& *": fontSizeImportant, "&&&& > *": fontSizeImportant }),
    thumbnailImage: {
        borderRadius: 2,
        boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        WebkitBoxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        MozBoxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        "&--error": {
            opacity: 0.3,
        },
        // "&--full": {
        //   width: 200,
        //   height: 283,
        // },
    },
    thumbnailPositioningContainer: {
        position: "absolute",
        bottom: "calc(100% + 30px)",
        width: "200px",
        // A4 aspect ratio
        aspectRatio: "1 / 1.41",
    },
    thumbnailTitle: {
        marginBottom: "7px",
        color: "#333",
        cursor: "pointer",
        whiteSpace: "nowrap",
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: 20,
        width: 200,
    },
    thumbnailImageContainer: {
        width: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "absolute",
        // overflow: "hidden",
        textAlign: "center",
    },
    thumbnailItemContainer: {
        width: 200,
        height: 283 + 30,
        borderRadius: 5,
        marginRight: 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "space-evenly",
        cursor: "default",
        "&--moving": {
            opacity: 0.2,
        },
    },
    thumbnailContainer: {
        mouse: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "fit-content",
        flexGrow: 1,
        gap: 10,
    },
}); });
exports.default = useStyles;
var templateObject_1;
