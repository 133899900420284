"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function scrollIntoView(id) {
    var element = document.getElementById(id);
    if (element) {
        element.setAttribute("data-alert-pulseHighlight", "pulseHighlight");
        setTimeout(function () {
            element.removeAttribute("data-alert-pulseHighlight");
        }, 2000);
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
}
exports.default = scrollIntoView;
