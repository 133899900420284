"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.executeCommand = void 0;
var selectionUtils_1 = require("../../utils/selectionUtils");
function executeCommand(editor, cmd, value) {
    if (!editor)
        return;
    (0, selectionUtils_1.withPreservedSelection)(editor, function () {
        var hasSelection = !editor.selection.isCollapsed();
        if (!hasSelection) {
            editor.commands.selectAll();
        }
        switch (cmd) {
            case "fontFamily":
                editor.fontFamily.apply(value);
                break;
            case "fontSize":
                editor.fontSize.apply(value);
                break;
            case "bold":
                editor.commands.bold();
                break;
            case "italic":
                editor.commands.italic();
                break;
            case "underline":
                editor.commands.underline();
                break;
            case "align-left":
                editor.align.apply("left");
                break;
            case "align-center":
                editor.align.apply("center");
                break;
            case "align-right":
                editor.align.apply("right");
                break;
            case "align-justify":
                editor.align.apply("justify");
                break;
            case "list-ul":
                editor.lists.format("UL");
                break;
            case "list-ol":
                editor.lists.format("OL");
                break;
            case "indent":
                editor.commands.indent();
                break;
            case "outdent":
                editor.commands.outdent();
                break;
            case "insertLink":
                editor.link.insert(value || "", editor.selection.text(), {
                    target: "_blank",
                });
                break;
            case "textColor":
                editor.colors.text(value);
                break;
            case "backgroundColor":
                editor.colors.background(value);
                break;
            case "strikethrough":
                editor.commands.strikeThrough();
                break;
            default:
                break;
        }
        // If we selected all the text, reset the selection
        if (!hasSelection) {
            editor.selection.clear();
        }
    });
}
exports.executeCommand = executeCommand;
