"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var redux_1 = require("@hooks/redux");
var react_1 = require("react");
var reactFlow_1 = require("@customTypes/reactFlow");
var constants_1 = require("@api/constants");
var useEdge = function (dispatch, user, relationships, _defaultEdgeOptions) {
    var customer = (0, constants_1.getCustomer)(user);
    var _a = (0, redux_1.useAppSelector)(function (state) { return state.workspace.defaultRelationship; }), defaultEdge = _a.relationship, defaultEdgeValue = _a.value;
    var defaultRelationshipType = customer === constants_1.CUSTOMER.SIMONSEN_VOGT_WIIG
        ? reactFlow_1.EdgeVariants.SMOOTHSTEP
        : reactFlow_1.EdgeVariants.CUSTOM;
    var _b = (0, react_1.useState)(__assign(__assign({}, _defaultEdgeOptions), { type: defaultRelationshipType })), defaultEdgeOptions = _b[0], setDefaultEdgeOptions = _b[1];
    var handleDefaultEdge = (0, react_1.useCallback)(function (edge) {
        var defaultRelationship = relationships.find(function (r) { return r.id === (edge === null || edge === void 0 ? void 0 : edge.value); });
        setDefaultEdgeOptions(__assign(__assign({}, defaultEdgeOptions), { type: (defaultRelationship === null || defaultRelationship === void 0 ? void 0 : defaultRelationship.type) || defaultRelationshipType }));
        dispatch((0, workspaceActions_http_1.setDefaultRelationship)(edge));
    }, [relationships]);
    var handleDefaultEdgeValue = (0, react_1.useCallback)(function (val) {
        dispatch((0, workspaceActions_http_1.setDefaultRelationshipValue)(val));
    }, []);
    return {
        defaultEdge: defaultEdge,
        handleDefaultEdge: handleDefaultEdge,
        defaultEdgeValue: defaultEdgeValue,
        handleDefaultEdgeValue: handleDefaultEdgeValue,
        defaultEdgeOptions: defaultEdgeOptions,
        defaultRelationshipType: defaultRelationshipType,
    };
};
exports.default = useEdge;
