"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.popperOffsetHorizontal = exports.popperOffsetOffice = exports.popperOffset = exports.popperOffsetSmall = void 0;
exports.popperOffsetSmall = [
    {
        name: "offset",
        options: {
            offset: [0, 8],
        },
    },
];
exports.popperOffset = [
    {
        name: "offset",
        options: {
            offset: [0, 15],
        },
    },
];
exports.popperOffsetOffice = [
    {
        name: "offset",
        options: {
            offset: [125, -43],
        },
    },
];
exports.popperOffsetHorizontal = [
    {
        name: "offset",
        options: {
            offset: [15, 0],
        },
    },
];
exports.default = exports.popperOffset;
