"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
function useElementsSelected(dispatch, editable) {
    var storeApi = (0, reactflow_1.useStoreApi)();
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var onChange = (0, react_1.useCallback)(function (_a) {
        var nodes = _a.nodes, edges = _a.edges;
        if (!editable) {
            return;
        }
        if (nodes.length > 0) {
            var nodesSelected = nodes.map(function (node) { return node.id; });
            if (nodesSelected.length > 1) {
                setTimeout(function () {
                    storeApi.setState(function () { return ({
                        nodesSelectionActive: true,
                    }); }); // show selection box
                }, 1);
            }
            dispatch((0, workspaceActions_websocket_1.userEditNodes)(nodesSelected));
        }
        if (edges.length > 0) {
            var edgesSelected = edges.reduce(function (acc, edge) {
                var _a;
                if (!((_a = edge.data) === null || _a === void 0 ? void 0 : _a.editing)) {
                    acc.push(edge.id);
                }
                return acc;
            }, []);
            dispatch((0, workspaceActions_websocket_1.userEditEdges)(edgesSelected));
        }
    }, [editable, storeApi, dispatch]);
    (0, reactflow_1.useOnSelectionChange)({
        onChange: onChange,
    });
    var handleNodeDeselect = (0, react_1.useCallback)(function (nodeId) {
        var selectedNodes = rfInstance.getNodes().reduce(function (acc, node) {
            var _a;
            if (!((_a = node.data) === null || _a === void 0 ? void 0 : _a.editing)) {
                acc.push(node.id);
            }
            return acc;
        }, []);
        if (nodeId) {
            if (!selectedNodes.includes(nodeId)) {
                selectedNodes.length > 0 && dispatch((0, workspaceActions_websocket_1.userFinishEditingNodes)(selectedNodes));
            }
        }
        else {
            selectedNodes.length > 0 && dispatch((0, workspaceActions_websocket_1.userFinishEditingNodes)(selectedNodes));
        }
    }, [rfInstance]);
    var handleEdgeDeselect = (0, react_1.useCallback)(function (deselectReduxState) {
        if (deselectReduxState === void 0) { deselectReduxState = false; }
        var selectedEdges = rfInstance
            .getEdges()
            .filter(function (e) { var _a; return !((_a = e.data) === null || _a === void 0 ? void 0 : _a.editing); })
            .map(function (edge) { return edge.id; });
        selectedEdges.length > 0 && dispatch((0, workspaceActions_websocket_1.userFinishEditingEdges)(selectedEdges));
        if (deselectReduxState) {
            (0, workspaceActions_http_1.deselectEdges)(dispatch);
        }
    }, [rfInstance]);
    return {
        handleNodeDeselect: handleNodeDeselect,
        handleEdgeDeselect: handleEdgeDeselect,
    };
}
exports.default = useElementsSelected;
