"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INITIALTEMPLATES = exports.INITIALELEMENTS = exports.defaultStringSelectOptionCreatable = exports.defaultNumberSelectOptionCreatable = exports.defaultNumberSelectOptionWithIndex = exports.WhereInApp = void 0;
var WhereInApp;
(function (WhereInApp) {
    WhereInApp["workspace"] = "workspace";
    WhereInApp["workspaces"] = "workspaces";
    WhereInApp["whiteboard"] = "whiteboard";
    WhereInApp["whiteboards"] = "whiteboards";
    WhereInApp["deadline"] = "deadline";
    WhereInApp["deadlines"] = "deadlines";
    WhereInApp["condition"] = "condition";
    WhereInApp["conditions"] = "conditions";
    WhereInApp["alert"] = "alert";
    WhereInApp["alerts"] = "alerts";
    WhereInApp["output"] = "output";
    WhereInApp["outputs"] = "outputs";
    WhereInApp["action"] = "action";
    WhereInApp["actions"] = "actions";
    WhereInApp["attribute"] = "attribute";
    WhereInApp["attributes"] = "attributes";
    WhereInApp["node"] = "node";
    WhereInApp["nodes"] = "nodes";
    WhereInApp["relationship"] = "relationship";
    WhereInApp["relationships"] = "relationships";
    WhereInApp["group"] = "group";
    WhereInApp["groups"] = "groups";
    WhereInApp["lawsuit"] = "lawsuit";
    WhereInApp["lawsuits"] = "lawsuits";
    WhereInApp["case"] = "case";
    WhereInApp["cases"] = "cases";
    WhereInApp["timeline"] = "timeline";
    WhereInApp["timelines"] = "timelines";
    WhereInApp["person"] = "person";
    WhereInApp["people"] = "people";
    WhereInApp["timelinePerson"] = "timelinePerson";
    WhereInApp["timelinePeople"] = "timelinePeople";
    WhereInApp["document"] = "document";
    WhereInApp["documents"] = "documents";
    WhereInApp["timelineDocument"] = "timelineDocument";
    WhereInApp["timelineDocuments"] = "timelineDocuments";
    WhereInApp["board"] = "board";
    WhereInApp["boards"] = "boards";
    WhereInApp["insight"] = "insight";
    WhereInApp["insights"] = "insights";
})(WhereInApp = exports.WhereInApp || (exports.WhereInApp = {}));
exports.defaultNumberSelectOptionWithIndex = {
    value: 0,
    label: "",
    index: 0,
};
exports.defaultNumberSelectOptionCreatable = {
    value: 0,
    label: "",
};
exports.defaultStringSelectOptionCreatable = {
    value: "",
    label: "",
};
// used for group and lawsuit
/**
 * @description The initial elements for building blocks on cases (lawsuits) and groups
 */
exports.INITIALELEMENTS = {
    outputs: 0,
    alerts: 0,
    attributes: 0,
    relationships: 0,
    conditions: 0,
    nodes: 0,
    documents: 0,
    people: 0,
};
/**
 * @description Type of templates for templatelist on groups
 */
exports.INITIALTEMPLATES = {
    boards: 0,
    workspaces: 0,
    timelines: 0,
    insights: 0,
};
// ! used for group and lawsuit
