"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialIcon = exports.initialFlag = void 0;
var nodeConstants_1 = require("./nodeConstants");
var folderConstants_1 = require("@redux/constants/folderConstants");
var converters_1 = require("@helpers/colors/converters");
var reactFlow_1 = require("@customTypes/reactFlow");
var constants_1 = require("@api/constants");
var TextService_1 = require("@helpers/flow/label/TextService");
var converters_2 = require("@helpers/measuringUnits/converters");
var initialBackGroundColor = {
    r: 255,
    g: 255,
    b: 255,
    a: 1,
};
var initialBorderColor = {
    r: 0,
    g: 0,
    b: 0,
    a: 1,
};
var initialTextColor = {
    r: 0,
    g: 0,
    b: 0,
    a: 1,
};
var initialWidth = 3;
var initialHeight = 1.3;
var initialFontSize = 10;
var initialAttributeFontSize = 6;
var initialFontFamily = TextService_1.FontFace.Open_Sans;
exports.initialFlag = {
    id: null,
    size: "m",
    horizontalOffset: 0,
    verticalOffset: 0,
    flagChanged: false,
};
exports.initialIcon = {
    item: { id: null, preview: "" },
    vector: null,
    size: "m",
    horizontalOffset: 0,
    verticalOffset: 0,
};
var initialState = {
    loading: false,
    nodes: [],
    title: "",
    description: "",
    notes: "",
    selectedAttributes: new Map(),
    deletedAttributes: [],
    group: "",
    groupOrLawsuit: "",
    backgroundColor: initialBackGroundColor,
    borderColor: initialBorderColor,
    fontSize: initialFontSize,
    attributeFontSize: initialAttributeFontSize,
    fontFamily: initialFontFamily,
    availableAttributes: [],
    attributesDropDownOptions: [],
    height: initialHeight,
    width: initialWidth,
    positionY: -initialHeight / 2,
    positionX: -initialWidth / 2,
    textColor: initialTextColor,
    shape: reactFlow_1.Figur.ROUNDRECTANGLE,
    nodesDescendants: [],
    deletingFolder: 0,
    fitSizeToLabel: true,
    flag: exports.initialFlag,
    icon: exports.initialIcon,
    loadings: constants_1.initialLoadings,
    documents: [],
    documentModalOpen: false,
    borderStyle: "solid",
    isBold: false,
    isItalic: false,
    isUnderline: false,
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case nodeConstants_1.NODE_DUPLICATE_LOADING:
        case nodeConstants_1.POST_DOCUMENT_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case nodeConstants_1.NODE_DUPLICATE_FAILED:
        case nodeConstants_1.POST_DOCUMENT_FAILED:
        case nodeConstants_1.GET_NODE_ICON_FAILED:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case nodeConstants_1.GET_NODES_LOADING:
            return __assign(__assign({}, state), { loading: true });
        case nodeConstants_1.GET_NODES_SUCCESS:
            return __assign(__assign({}, state), { nodes: action.nodes, loading: false });
        case nodeConstants_1.GET_NODES_FAILED:
        case nodeConstants_1.GET_NODE_DESCENDANTS_FAILED:
            return __assign(__assign({}, state), { loading: false });
        case nodeConstants_1.POST_NODE_SUCCESS:
            return initialState;
        case nodeConstants_1.POST_NODE_FAILED:
            return state;
        case nodeConstants_1.SHOW_NODE_LITE_SUCCESS:
            return __assign(__assign({}, state), { groupOrLawsuit: action.groupOrLawsuit });
        case nodeConstants_1.SHOW_NODE_SUCCESS:
            return __assign(__assign({}, state), { title: action.node.label, description: action.node.description, notes: action.node.notes || "", availableAttributes: action.node.attributes, groupOrLawsuit: action.node.groupOrLawsuit, backgroundColor: (0, converters_1.hexToRgba)(action.node.backgroundColor), borderColor: (0, converters_1.hexToRgba)(action.node.borderColor), deletedAttributes: [], width: Math.round((0, converters_2.px2cm)(action.node.width) * 100) / 100, height: Math.round((0, converters_2.px2cm)(action.node.height) * 100) / 100, positionX: -action.node.width / 2, positionY: -action.node.height / 2, textColor: (0, converters_1.hexToRgba)(action.node.textColor), shape: action.node.figure || reactFlow_1.Figur.ROUNDRECTANGLE, fitSizeToLabel: action.node.fitSizeToLabel, documents: action.node.documents || [], fontSize: action.node.fontSize, attributeFontSize: action.node.attributeFontSize, fontFamily: action.node.fontFamily, isBold: action.node.isBold, isItalic: action.node.isItalic, isUnderline: action.node.isUnderline, flag: action.node.flag, icon: action.node.icon });
        case nodeConstants_1.SHOW_NODE_FAILED:
            return state;
        case nodeConstants_1.PUT_NODE_SUCCESS:
            return state;
        case nodeConstants_1.PUT_NODE_FAILED:
            return state;
        case nodeConstants_1.DELETE_NODE_SUCCESS: {
            return __assign(__assign({}, state), { nodesDescendants: action.nodesDescendants });
        }
        case nodeConstants_1.DELETE_NODE_FAILED:
        case nodeConstants_1.FORCE_DELETE_NODE_FAILED:
            return state;
        case nodeConstants_1.NODE_GET_ATTRIBUTE_DROPDOWN_SUCCESS: {
            return __assign(__assign({}, state), { availableAttributes: action.attributes });
        }
        case nodeConstants_1.NODE_GET_ATTRIBUTE_DROPDOWN_FAILED:
            return state;
        case nodeConstants_1.GET_SELECTED_ATTRIBUTES_SUCCESS: {
            var selectedAttributes_1 = new Map();
            action.attributes.forEach(function (attribute) {
                selectedAttributes_1.set(attribute.id, attribute);
            });
            return __assign(__assign({}, state), { selectedAttributes: selectedAttributes_1 });
        }
        case nodeConstants_1.GET_SELECTED_ATTRIBUTES_FAILED:
            return state;
        case nodeConstants_1.TITLE_CHANGE:
            return __assign(__assign({}, state), { title: action.title });
        case nodeConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.description });
        case nodeConstants_1.NOTES_CHANGE:
            return __assign(__assign({}, state), { notes: action.notes });
        case nodeConstants_1.DOCUMENTS_CHANGE:
            return __assign(__assign({}, state), { documents: action.documents });
        case nodeConstants_1.GET_NODE_ICON_SUCCESS:
            return __assign(__assign({}, state), { icon: __assign(__assign({}, state.icon), { vector: action.vector }) });
        case nodeConstants_1.ADD_ATTRIBUTE: {
            var selectedAttributes = new Map(state.selectedAttributes);
            selectedAttributes.set(action.attribute.id, action.attribute);
            return __assign(__assign({}, state), { selectedAttributes: selectedAttributes });
        }
        case nodeConstants_1.UPDATE_ATTRIBUTE: {
            var selectedAttributes = new Map(state.selectedAttributes);
            var attribute = selectedAttributes.get(action.attribute.id);
            if (attribute) {
                // @ts-ignore Philip (Typing is not working here, although it should)
                attribute[action.key] = action.value;
                selectedAttributes.set(action.attribute.id, attribute);
            }
            return __assign(__assign({}, state), { selectedAttributes: selectedAttributes });
        }
        case nodeConstants_1.REMOVE_ATTRIBUTE: {
            var selectedAttributes = new Map(state.selectedAttributes);
            selectedAttributes.delete(action.attribute.id);
            var deletedAttributes = __spreadArray([], state.deletedAttributes, true);
            // eslint-disable-next-line no-prototype-builtins
            if (action.attribute.hasOwnProperty("node_attribut_id")) {
                var castedAttribute = __assign({}, action.attribute);
                deletedAttributes.push(castedAttribute.node_attribut_id);
            }
            return __assign(__assign({}, state), { selectedAttributes: selectedAttributes, deletedAttributes: deletedAttributes });
        }
        case nodeConstants_1.CHANGE_BACKGROUND_COLOR:
            return __assign(__assign({}, state), { backgroundColor: action.color });
        case nodeConstants_1.CHANGE_BORDER_COLOR:
            return __assign(__assign({}, state), { borderColor: action.color });
        case nodeConstants_1.CHANGE_HEIGHT:
            return __assign(__assign({}, state), { height: action.height, positionY: action.positionY });
        case nodeConstants_1.CHANGE_WIDTH:
            return __assign(__assign({}, state), { width: action.width, positionX: action.positionX });
        case nodeConstants_1.CHANGE_TEXT_COLOR:
            return __assign(__assign({}, state), { textColor: action.color });
        case nodeConstants_1.CHANGE_SHAPE:
            return __assign(__assign({}, state), { shape: action.shape });
        case nodeConstants_1.CHANGE_FIT_SIZE_TO_LABEL:
            return __assign(__assign({}, state), { fitSizeToLabel: action.value });
        case nodeConstants_1.PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS: {
            var availableAttributes = __spreadArray([], state.availableAttributes, true);
            availableAttributes.map(function (attribute) {
                if (attribute.id === action.attribute.id) {
                    return action.attribute;
                }
                return attribute;
            });
            var selectedAttributes = new Map(state.selectedAttributes);
            selectedAttributes.set(action.attribute.id, action.attribute);
            return __assign(__assign({}, state), { selectedAttributes: selectedAttributes, availableAttributes: availableAttributes });
        }
        case nodeConstants_1.POST_DROPDOWN_VALUE_SUCCESS: {
            var selectedAttributes = new Map(state.selectedAttributes);
            selectedAttributes.set(action.attribute.id, action.attribute);
            return __assign(__assign({}, state), { availableAttributes: __spreadArray(__spreadArray([], state.availableAttributes, true), [action.attribute], false), selectedAttributes: selectedAttributes });
        }
        case nodeConstants_1.CLEAR_NODE_DESCENDANTS:
            return __assign(__assign({}, state), { nodesDescendants: initialState.nodesDescendants });
        case nodeConstants_1.FORCE_DELETE_NODE_SUCCESS: {
            var nodes = state.nodes.filter(function (node) { return node.id !== action.nodeId; });
            return __assign(__assign({}, state), { nodes: nodes });
        }
        case nodeConstants_1.GET_NODE_DESCENDANTS_SUCCESS: {
            return __assign(__assign({}, state), { nodesDescendants: action.nodesDescendants });
        }
        case nodeConstants_1.NODE_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { nodes: action.node ? __spreadArray(__spreadArray([], state.nodes, true), [action.node], false) : state.nodes, loadings: constants_1.initialLoadings });
        case nodeConstants_1.POST_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { documents: state.documents.map(function (documentId) {
                    if (documentId === 0) {
                        documentId = action.id;
                    }
                    return documentId;
                }), loadings: constants_1.initialLoadings });
        case nodeConstants_1.TOGGLE_DOCUMENT_MODAL:
            return __assign(__assign({}, state), { documentModalOpen: action.open });
        case nodeConstants_1.CHANGE_FONT_SIZE:
            return __assign(__assign({}, state), { fontSize: action.size });
        case nodeConstants_1.CHANGE_ATTRIBUTE_FONT_SIZE:
            return __assign(__assign({}, state), { attributeFontSize: action.size });
        case nodeConstants_1.CHANGE_FONT_FAMILY:
            return __assign(__assign({}, state), { fontFamily: action.font });
        case nodeConstants_1.CHANGE_FLAG_NODE:
            return __assign(__assign({}, state), { flag: __assign(__assign({}, state.flag), (_b = {}, _b[action.key] = action.value, _b)) });
        case nodeConstants_1.CHANGE_ICON_NODE:
            return __assign(__assign({}, state), { icon: __assign(__assign({}, state.icon), (_c = {}, _c[action.key] = action.value, _c)) });
        case (0, folderConstants_1.moveElementToDestroyedParentFailed)("nodes"):
        case (0, folderConstants_1.postFolderFailed)("nodes"):
        case (0, folderConstants_1.putFolderTitleFailed)("nodes"):
        case (0, folderConstants_1.putFolderLocationFailed)("nodes"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("nodes"):
        case (0, folderConstants_1.duplicateFolderFailed)("nodes"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderFailed)("nodes"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("nodes"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadings] = true, _d)) });
        case (0, folderConstants_1.moveElementToDestroyedParentLoading)("nodes"):
        case (0, folderConstants_1.putFolderLocationLoading)("nodes"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("nodes"):
        case (0, folderConstants_1.putFolderTitleLoading)("nodes"):
        case (0, folderConstants_1.postFolderLoading)("nodes"):
        case (0, folderConstants_1.duplicateFolderLoading)("nodes"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_e = {}, _e[action.loadings] = true, _e)) });
        case (0, folderConstants_1.postFolderSuccess)("nodes"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, nodes: __spreadArray([action.folder], state.nodes, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("nodes"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, nodes: state.nodes.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("nodes"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, nodes: state.nodes.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("nodes"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, nodes: state.nodes.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("nodes"): {
            if (action.descendants && action.descendants.length > 0) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: action.folder.id, nodesDescendants: action.descendants });
            }
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, nodes: state.nodes
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, nodes: state.nodes
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.moveElementToDestroyedParentSuccess)("nodes"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, nodes: state.nodes.map(function (c) {
                    if (c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.duplicateFolderSuccess)("nodes"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, nodes: __spreadArray(__spreadArray([], action.folder, true), state.nodes, true) });
        case nodeConstants_1.CHANGE_BORDER_STYLE:
            return __assign(__assign({}, state), { borderStyle: action.borderStyle });
        case "NODE_CHANGE_BOLD":
            return __assign(__assign({}, state), { isBold: !state.isBold });
        case "NODE_CHANGE_ITALIC":
            return __assign(__assign({}, state), { isItalic: !state.isItalic });
        case "NODE_CHANGE_UNDERLINE":
            return __assign(__assign({}, state), { isUnderline: !state.isUnderline });
        default:
            return state;
    }
}
exports.default = reducer;
