"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDrag = exports.DragProvider = void 0;
/* eslint-disable react/jsx-no-constructed-context-values */
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var redux_1 = require("@hooks/redux");
var DragContext = (0, react_1.createContext)(undefined);
var DragProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(null), draggedItem = _b[0], setDraggedItem = _b[1];
    var t = (0, react_i18next_1.useTranslation)().t;
    var reduxColumns = (0, redux_1.useAppSelector)(function (state) { return state.insight.columns; });
    var columns = __spreadArray([
        {
            id: "documentTitle",
            title: t("insights.document_title"),
            description: t("insights.document_title_description"),
            type: t("insights.types.shorttext"),
        },
        {
            id: "documentCategory",
            title: t("insights.category"),
            description: t("insights.category_description"),
            type: t("insights.types.shorttext"),
        },
        {
            id: "documentInfo",
            title: t("generic.info"),
            description: t("insights.info_description"),
            type: t("insights.types.shorttext"),
        }
    ], reduxColumns.map(function (column) { return ({
        id: column.id.toString(),
        title: column.title,
        description: column.prompt,
        type: column.type,
    }); }), true);
    var _c = (0, react_1.useState)([
        {
            id: "1",
            title: null,
            date: null,
            description: null,
            people: [],
            tags: [],
            notes: [],
        },
    ]), events = _c[0], setEvents = _c[1];
    var handleDrop = function (eventId, fieldType) {
        if (fieldType === void 0) { fieldType = "columns"; }
        if (!draggedItem)
            return;
        setEvents(function (prevEvents) {
            return prevEvents.map(function (event) {
                var _a;
                if (event.id === eventId) {
                    var newItem = {
                        id: draggedItem.id,
                        title: draggedItem.title,
                    };
                    if (fieldType === "people") {
                        return __assign(__assign({}, event), { people: __spreadArray(__spreadArray([], event.people, true), [newItem], false) });
                    }
                    if (fieldType === "tags") {
                        return __assign(__assign({}, event), { tags: __spreadArray(__spreadArray([], event.tags, true), [newItem], false) });
                    }
                    if (fieldType === "notes") {
                        return __assign(__assign({}, event), { notes: __spreadArray(__spreadArray([], event.notes, true), [newItem], false) });
                    }
                    // For single-value fields (title, date, description)
                    return __assign(__assign({}, event), (_a = {}, _a[fieldType] = newItem, _a));
                }
                return event;
            });
        });
        setDraggedItem(null);
    };
    var addNewEvent = function () {
        var newId = "event-".concat(Date.now());
        var newEvent = {
            id: newId,
            title: null,
            date: null,
            description: null,
            people: [],
            tags: [],
            notes: [],
        };
        setEvents(function (prevEvents) { return __spreadArray(__spreadArray([], prevEvents, true), [newEvent], false); });
    };
    var deleteEvent = function (eventId) {
        setEvents(function (prevEvents) { return prevEvents.filter(function (event) { return event.id !== eventId; }); });
    };
    var clearEventField = function (eventId, fieldType) {
        setEvents(events.map(function (event) {
            if (event.id === eventId) {
                var updatedEvent = __assign({}, event);
                // Handle all field types appropriately
                if (fieldType === "people" || fieldType === "tags" || fieldType === "notes") {
                    updatedEvent[fieldType] = []; // Reset arrays to empty arrays
                }
                else {
                    updatedEvent[fieldType] = null; // Set single-value fields to null
                }
                return updatedEvent;
            }
            return event;
        }));
    };
    return (react_1.default.createElement(DragContext.Provider, { value: {
            draggedItem: draggedItem,
            setDraggedItem: setDraggedItem,
            events: events,
            setEvents: setEvents,
            columns: columns,
            handleDrop: handleDrop,
            addNewEvent: addNewEvent,
            deleteEvent: deleteEvent,
            clearEventField: clearEventField,
        } }, children));
};
exports.DragProvider = DragProvider;
var useDrag = function () {
    var context = (0, react_1.useContext)(DragContext);
    if (context === undefined) {
        throw new Error("useDrag must be used within a DragProvider");
    }
    return context;
};
exports.useDrag = useDrag;
