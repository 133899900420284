"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fitScreen = void 0;
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var usePowerPointFitter_1 = require("@hooks/flow/usePowerPointFitter");
var redux_1 = require("@hooks/redux");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function fitScreen(rfInstance, pptxCanvasDimensions, showPPTXCanvas, nodeElements) {
    var officePanelContainer = document.getElementById("office-panel-container");
    var officeItemContainer = document.getElementById("office-item-container");
    var officeWidth = (officePanelContainer === null || officePanelContainer === void 0 ? void 0 : officePanelContainer.clientWidth) || 0;
    var officeHeight = (officeItemContainer === null || officeItemContainer === void 0 ? void 0 : officeItemContainer.clientHeight) || 0;
    if (officePanelContainer && !showPPTXCanvas) {
        var nodes = nodeElements || rfInstance.getNodes();
        var bounds = (0, reactflow_1.getNodesBounds)(nodes);
        bounds.x -= officeWidth;
        bounds.width += officeWidth;
        rfInstance.fitBounds(bounds, { padding: 0.3, duration: 500 });
    }
    if (!showPPTXCanvas) {
        rfInstance.fitView({ padding: 0.5, duration: 500 });
        return;
    }
    var innerWidth = window.innerWidth, innerHeight = window.innerHeight;
    // Padding of 10% around the viewport
    var padding = 0.1;
    var availableWidth = (innerWidth - officeWidth) * (1 - 2 * padding);
    var availableHeight = (innerHeight - officeHeight) * (1 - 2 * padding);
    var canvasWidth = pptxCanvasDimensions.width, canvasHeight = pptxCanvasDimensions.height;
    // Calculate the scale to fit within the viewport while maintaining aspect ratio
    var scaleX = availableWidth / canvasWidth;
    var scaleY = availableHeight / canvasHeight;
    var zoom = Math.min(scaleX, scaleY); // Ensure it fits within both dimensions
    // Centering the canvas
    var centerX = (innerWidth + officeWidth - canvasWidth * zoom) / 2;
    var centerY = (innerHeight + officeHeight - canvasHeight * zoom) / 2;
    rfInstance.setViewport({
        zoom: zoom,
        x: centerX,
        y: centerY,
    }, { duration: 500 });
}
exports.fitScreen = fitScreen;
var useFitToScreen = function (rfInstance, customFitScreen) {
    var showPPTXCanvas = (0, redux_1.useAppSelector)(function (state) { return state.workspace.showPPTXCanvas; });
    var user = (0, auth0_react_1.useAuth0)().user;
    var customer = (0, constants_1.getCustomer)(user);
    var pptxCanvasDimensions = (0, react_1.useMemo)(function () {
        var _a = (0, usePowerPointFitter_1.getCustomerSlidePropertiesInPx)(customer), width = _a.width, height = _a.height;
        return { width: width, height: height };
    }, [customer]);
    var fitToScreen = (0, react_1.useCallback)(function () {
        if (customFitScreen) {
            customFitScreen();
            return;
        }
        if (!rfInstance) {
            return;
        }
        fitScreen(rfInstance, pptxCanvasDimensions, showPPTXCanvas);
    }, [customFitScreen, rfInstance, pptxCanvasDimensions, showPPTXCanvas]);
    return [fitToScreen];
};
exports.default = useFitToScreen;
