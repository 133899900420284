"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var constants_1 = require("@components/Boards/Cells/constants");
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    container: {
        width: "100%",
        height: "100%",
    },
    button: {
        width: "100%",
        height: "100%",
    },
    box: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        position: "relative",
    },
    iconButton: {
        backgroundColor: "#ddd",
        position: "absolute",
        right: 5,
        padding: "3px",
    },
    closeIcon: {
        fontSize: 10,
    },
    addCircleIcon: {
        color: theme.palette.primary.main,
    },
    hoverTextBorder: { boxShadow: "0 0 0px 1px ".concat("".concat(theme.palette.info.main, "33"), " inset") },
    typography: {
        textAlign: "left",
    },
    textField: {
        width: "100%",
        height: "100%",
        textAlign: "left",
        margin: 0,
        "& .MuiInputBase-root": {
            borderRadius: "5px",
            height: "100%",
        },
        "& .MuiInputBase-input": {
            padding: 1,
            textAlign: "left",
        },
        "& .MuiInputBase-multiline": {
            padding: 1,
            textAlign: "left",
        },
    },
}); });
function EditableText(props) {
    var _a = useStyles(), classes = _a.classes, cx = _a.cx;
    var value = props.value, handleStopEdit = props.handleStopEdit, isEditable = props.isEditable, shouldJustifyLeft = props.shouldJustifyLeft, elispisChars = props.elispisChars, textFieldProps = props.textFieldProps, typygraphyProps = props.typygraphyProps, typographyClass = props.typographyClass, boxSx = props.boxSx, multiline = props.multiline, showTooltip = props.showTooltip, _b = props.isClearable, isClearable = _b === void 0 ? true : _b;
    var _c = (0, react_1.useState)(false), isEditing = _c[0], setIsEditing = _c[1];
    var edit = function () {
        setIsEditing(true);
    };
    var _d = (0, react_1.useState)(false), isHovering = _d[0], setIsHovering = _d[1];
    var handleMouseEnter = (0, react_1.useCallback)(function () {
        setIsHovering(true);
    }, []);
    var handleMouseLeave = (0, react_1.useCallback)(function () {
        setIsHovering(false);
    }, []);
    var _e = (0, react_1.useState)(value), text = _e[0], setText = _e[1];
    (0, react_1.useEffect)(function () {
        setText(value);
    }, [value]);
    var onValueChange = (0, react_1.useCallback)(function (e) {
        setText(e.target.value);
    }, []);
    var onBlur = (0, react_1.useCallback)(function () {
        setIsEditing(false);
        if (!isClearable && !text) {
            setText(value);
        }
        else {
            handleStopEdit(text);
        }
    }, [setIsEditing, handleStopEdit, text, value, isClearable]);
    return (react_1.default.createElement(material_1.Box, { className: classes.container, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, sx: __assign({}, boxSx) },
        !isEditing && (react_1.default.createElement(material_1.Tooltip, { title: showTooltip && elispisChars && text.length > elispisChars ? text : null, disableInteractive: true, arrow: true, placement: "top" },
            react_1.default.createElement("span", null,
                react_1.default.createElement(material_1.ButtonBase, { className: cx(classes.button, "disabled-item-normal"), onClick: edit, disabled: !isEditable },
                    react_1.default.createElement(material_1.Box, { className: cx(classes.box, isEditable && isHovering ? classes.hoverTextBorder : ""), sx: [shouldJustifyLeft ? {
                                justifyContent: "flex-start"
                            } : {
                                justifyContent: "center"
                            }] },
                        react_1.default.createElement(material_1.Typography, __assign({ variant: "body2", className: cx(classes.typography, typographyClass) }, typygraphyProps), elispisChars
                            ? text.length > elispisChars
                                ? text.substring(0, elispisChars) + "..."
                                : text
                            : text)))))),
        isEditing && (react_1.default.createElement(material_1.Box, { className: classes.container },
            react_1.default.createElement(material_1.TextField, __assign({ fullWidth: true, autoFocus: true, value: text, onChange: onValueChange, className: classes.textField, onBlur: onBlur, multiline: multiline, onKeyDown: constants_1.handleKeyDown }, textFieldProps))))));
}
exports.default = (0, react_1.memo)(EditableText);
